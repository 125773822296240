<!--loader-->
<div id="loading" *ngIf="isLoading">
    <div class="loaderbox">
        <div class="darkbackground-box"></div>      
        <div class="spinnerbox-ic">
            <mat-spinner style="width: 55px;height:55px"></mat-spinner>
        </div>     
    </div> 
</div>
      
<mat-dialog-content class="mat-typography">
    <div class="row">

        <div class="col-md-6">
            <h2 class="mb-0 pb-0" mat-dialog-title>Okta Audit trail ({{user.username}})</h2>
            <div class="row mb-2 mt-3">
                <div class="col-md-4 col-sm-12 col-xs-3 text-md-end">Okta Id:</div>
                <div class="col-md-8 col-sm-10 col-xs-10" style="font-weight: bold;">{{oktaTrail.id}}</div>
            </div>
            <div class="row mb-2">
                <div class="col-md-4 col-sm-12 col-xs-3 text-md-end">Status :</div>
                <div class="col-md-8 col-sm-10 col-xs-10" style="font-weight: bold;">{{oktaTrail.status}}</div>
            </div>
            <div class="row mb-2">
                <div class="col-md-4 col-sm-12 col-xs-3 text-md-end">Created on :</div>
                <div class="col-md-8 col-sm-10 col-xs-10" style="font-weight: bold;">{{(oktaTrail.created+'+0000') | date: 'MMM dd yyyy, hh:mm:ss a' : loggedInUser.timezoneHrsMin}}</div>
            </div>
            <div class="row mb-2">
                <div class="col-md-4 col-sm-12 col-xs-3 text-md-end">Status changed on:</div>
                <div class="col-md-8 col-sm-10 col-xs-10" style="font-weight: bold;">{{(oktaTrail.statusChanged+'+0000') | date: 'MMM dd yyyy, hh:mm:ss a' : loggedInUser.timezoneHrsMin}}</div>
            </div>
            <div class="row mb-2">
                <div class="col-md-4 col-sm-12 col-xs-3 text-md-end">Last updated on:</div>
                <div class="col-md-8 col-sm-10 col-xs-10" style="font-weight: bold;">{{(oktaTrail.lastUpdated+'+0000') | date: 'MMM dd yyyy, hh:mm:ss a' : loggedInUser.timezoneHrsMin}}</div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4 col-sm-12 col-xs-3 text-md-end">Password Changed:</div>
                <div class="col-md-8 col-sm-10 col-xs-10" style="font-weight: bold;">{{(oktaTrail.passwordChanged+'+0000') | date: 'MMM dd yyyy, hh:mm:ss a' : loggedInUser.timezoneHrsMin}}</div>
            </div>
            <hr>
            <div class="row mb-2">
                <div class="col-md-4 col-sm-12 col-xs-3 text-md-end">Aonline Portal Last Login:</div>
                <div class="col-md-8 col-sm-10 col-xs-10" style="font-weight: bold;">{{(user?.lastLogin+'+0000') | date: 'MMM dd yyyy, hh:mm:ss a' : loggedInUser.timezoneHrsMin}}</div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="d-flex align-items-bottom justify-content-center">
                
                
            </div>

            <div class="d-flex flex-wrap align-items-center justify-content-lg-start">
                <div class="d-flex col-md-9 col-sm-9 col-xs-9">
                    <h2 class="mx-0 px-0 mb-0 pb-0" mat-dialog-title>User Management Audit trail</h2>
                </div>
            
                <div class="col-md-3 col-sm-3 col-xs-3 box-n px-2">
                  <div class="d-flex flex-row-reverse bd-highlight" *ngIf="loggedInUser">
                    <button type="button" class="btn btn-dark btn-sm" [routerLink]="['users', user.id, 'audit']" (click)="onClose()">View All</button>
                  </div>
                </div>
            </div>
            
            <p *ngIf="!umTrail || umTrail.length == 0" class="text-center mt-3">No Audit Trail found</p>
            <p class="text-center mt-3"></p>
            <div *ngFor="let umLog of umTrail">
                <span class="m-0" *ngIf="umLog.action == 'CREATE'">User Created</span>
                <span class="m-0" *ngIf="umLog.action == 'UPDATE' && !umLog.attribute">User Updated</span>
                <span class="m-0" *ngIf="umLog.action == 'UPDATE' && umLog.attribute == 'IsActive' && umLog.newValue == 'False'">User Deactivated</span>
                <span class="m-0" *ngIf="umLog.action == 'UPDATE' && umLog.attribute == 'IsActive' && umLog.newValue == 'True'">User Activated</span>
                <span class="m-0" *ngIf="umLog.action == 'EMAIL'">Email Sent</span>
                <span class="m-0" *ngIf="umLog.action == 'RESET_AUTH'">Authenticators Reset</span>
                <span class="m-0" *ngIf="umLog.action == 'CLIENT_CONTACT_CREATED'">Made point of contact for client :{{umLog.newValue}}</span>
                <span class="m-0" *ngIf="umLog.action == 'CLIENT_CONTACT_REMOVED'">Removed as point of contact for client :{{umLog.newValue}}</span>
                <small> by <em>{{umLog.createdByUser.email}}</em></small>
                <p><small>on {{umLog.createdAt+'+0000' | date: 'MMM dd yyyy, hh:mm:ss a' : loggedInUser.timezoneHrsMin}}</small></p>
                <hr>
            </div>
        </div>

    </div>
    
</mat-dialog-content>

<mat-dialog-actions >
    <div class="col-md-6">
        <small><em>&nbsp;&nbsp;&nbsp;Note: All dates & times are in <strong>{{'GMT'+loggedInUser.timezoneHrsMin}}</strong></em></small>
    </div>
    <div class="col-md-6" align="end">
        <button class="btn btn-danger" (click)="onClose()" type="button">Close</button>
    </div>
</mat-dialog-actions>