import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss']
})
export class UserFilterComponent {

  isLoading = false;

  constructor(public dialogRef: MatDialogRef<UserFilterComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onApply(){
    this.dialogRef.close('Filter set');
  }

  onClear(){
    this.dialogRef.close(null);
  }

}
