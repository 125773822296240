<!--loader-->
<div *ngIf="isLoading">
    <div class="loaderbox">
        <div class="darkbackground-box"></div>
        <div class="spinnerbox-ic">
            <mat-spinner style="width: 55px;height:55px"></mat-spinner>
        </div>
    </div>
</div>

<div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-3">
        <div class="row hdbox-fm my-2">
            <div class="col-md-11 col-sm-12 col-xs-12  hdbox-fm-grid d-flex align-items-center">
                Update Admin - {{admin?.firstName}} {{admin?.lastName}}
            </div>
        </div>
    
        <form [formGroup]="editForm" (ngSubmit)="onSubmit()" class="my-3">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Email Address :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                <mat-label>Enter user's email address</mat-label>
                                <input matInput formControlName="email" name="email">
                                <div *ngIf="editForm.controls['email'].touched && editForm.controls['email'].invalid">
                                    <mat-error *ngIf="editForm.controls['email'].errors?.['required']">This field is required</mat-error>
                                    <mat-error *ngIf="editForm.controls['email'].errors?.['email']">Invalid email address</mat-error>
                                    <mat-error *ngIf="editForm.controls['email'].errors?.['exists']">Email address already exists</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>First Name :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                <mat-label>Enter user's first name</mat-label>
                                <input matInput formControlName="firstname" name="firstname">
                                <div *ngIf="editForm.controls['firstname'].touched && editForm.controls['firstname'].invalid">
                                    <mat-error *ngIf="editForm.controls['firstname'].errors?.['required']">This field is required</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Last Name :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                <mat-label>Enter user's last name</mat-label>
                                <input matInput formControlName="lastname" name="lastname">
                                <div *ngIf="editForm.controls['lastname'].touched && editForm.controls['lastname'].invalid">
                                    <mat-error *ngIf="editForm.controls['lastname'].errors?.['required']">This field is required</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
        
                    <div class="row frm mb-2">
                        <div class="col-md-3 col-sm-3 col-xs-3 text-md-end">Countries :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="fill" class="dropdown-access">
                                <mat-label>Select Countries</mat-label>
                                <mat-select formControlName="countries" name="countries" [(ngModel)]="selectedCountries" multiple panelClass="input-ad-box dropdown" style="width: 350px;height: 50px;">
                                    <mat-select-trigger>
                                        {{(selectedCountries?.length > 0) ? selectedCountries[0]?.code : ''}}
                                        <span *ngIf="(selectedCountries?.length || 0) > 1" class="example-additional-selection">
                                        (+{{(selectedCountries?.length || 0) - 1}} {{selectedCountries?.length === 2 ? 'other' : 'others'}})
                                        </span>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let country of allCountries" [value]="country">{{country.code}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
        
                </div>
        
                <div class="col-md-6 col-sm-12">
        
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12 txt-ad-box"><p class="h5 pb-1">Select Permissions</p></div>
                        <div class="col-md-12 col-sm-12 col-xs-12 txt-ad-box">
                            <div class="row mb-2">
                                <div class="col-md-12 col-sm-12 col-xs-12"><mat-checkbox formControlName="psuperadmin" name="psuperadmin">Super Admin</mat-checkbox></div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-12 col-sm-12 col-xs-12"><mat-checkbox formControlName="pspsite" name="pspsite">Sharepoint Site Requests</mat-checkbox></div>
                            </div>
                    
                            <div class="row mt-3">
                                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                                    <button type="submit" class="svn-btn" [disabled]="this.editForm.invalid">Submit</button> 
                                    <button (click)="onCancel()" type="button" class="cn-btn">Cancel</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
            
                </div>
        
            </div>
        </form>
    </div>
</div>