import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';
import {debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { OPTION_COUNTRIES, OPTION_LANGUAGES, OPTION_SALUTATIONS, OPTION_USER_TYPES } from 'src/models/Constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit, AfterViewInit {

  optionUserTypes: any[] = OPTION_USER_TYPES;
  optionCountries: any[] = OPTION_COUNTRIES;
  optionSalutations: any[] = OPTION_SALUTATIONS;
  optionLanguages: any[] = OPTION_LANGUAGES;

  loggedInUser : LoggedInUser;
  minLengthTerm = 2;
  isLoading: boolean = false;
  registerForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    client: new FormControl('', [Validators.required]),
    groups: new FormControl('', [Validators.required]),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    usertype: new FormControl('', [Validators.required]),
    sendemail: new FormControl(false, [Validators.required]),
    isClientContact: new FormControl(false, [Validators.required]),
    title: new FormControl(''), 
    language: new FormControl(''),
    address: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    country: new FormControl(''),
    zip: new FormControl(''),
    phone: new FormControl(''),
    salutation: new FormControl(''), 
  });

  file: File | null = null; // Variable to store file
  fileErr: string = "";

  // For Client selection
  filteredClients: any;
  isLoadingClients = false;
  selectedClient: any = "";

  // For Group Selection
  allGroups: any;
  selectedGroups: any = [];

  // For UserType selecion
  selectedUserType: any = "";

  // For Populating FirstName and LastName in case of internal user
  firstName: string;
  lastName: string;
  preLoadedValues: boolean=false;
  // For email
  existingUser: any;
  

  constructor(private appService: AppService, private authGuard: AuthGuard, private http: HttpClient, private _snackBar: MatSnackBar, private location: Location,private router: Router) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.registerForm.controls['client'].valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(250),
        tap(() => {
          this.filteredClients = [];
          this.isLoadingClients = true;
        }),
        switchMap(value => this.appService.searchClients(10, 0, value as string)
          .pipe(
            finalize(() => {
              this.isLoadingClients = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        if (response['data'] == undefined) {
          this.filteredClients = [];
        } else {
          this.filteredClients = response['data'];
          console.log(this.filteredClients)
        }
      });

    this.registerForm.controls['email'].valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
            this.existingUser = null;
            this.registerForm.controls['email'].setErrors(this.registerForm.controls['email'].errors);
            if(this.preLoadedValues)
            {
            this.registerForm.controls['firstname'].reset();
            this.registerForm.controls['lastname'].reset();
            this.registerForm.controls['usertype'].reset();
            this.registerForm.controls['firstname'].enable();
            this.registerForm.controls['lastname'].enable();
            this.registerForm.controls['usertype'].enable();
            }
            if(this.registerForm.controls['email'].value != "" && !this.registerForm.controls['email'].invalid){
              this.findExistingUser(this.registerForm.controls['email'].value);
            }
        })
      )
      .subscribe();
  }

  selectedClientAuth: boolean=false;
  onClientSelected() {
    this.selectedClient = this.selectedClient;
    console.log(this.selectedClient)
    this.selectedClientAuth=this.selectedClient.isAuthAvailable;
    this.appService.getClientGroups(this.selectedClient.id).subscribe((response) => {
      this.allGroups = response;
      this.autoSelectDefaultGroups();
    }); 
    
  }

  onUserTypeSelected() {
    this.selectedClient = this.selectedClient;
    this.appService.getClientGroups(this.selectedClient.id).subscribe((response) => {
      this.allGroups = response;
      this.autoSelectDefaultGroups();
    });
  }

  displayClientWith(value: any) {
    return value?.name;
  }

  autoSelectDefaultGroups(){
    this.selectedGroups = this.allGroups.filter((group: { isDefault: boolean; }) => group.isDefault);
  }

  async findExistingUser(email: string | null){
    if(email != null){
      
      await this.appService.getUserByEmail(email).subscribe((response) => {    
        this.existingUser = response;
        this.registerForm.controls['email'].setErrors({ 'exists': true});
      });
      await this.appService.getOktaDetailsByEmail(email).subscribe(
      (results) => {
       if(results.aonnetId !=null)
        {
          this.firstName = results.firstName;
          this.lastName = results.lastName;
          this.selectedUserType = 2;  //UserId for employee type
          this.registerForm.controls['firstname'].disable();
          this.registerForm.controls['lastname'].disable();
          this.registerForm.controls['usertype'].disable();
          this.preLoadedValues =true;
        }

      });
    }
  }

  onSubmit(): void {
    if (this.registerForm.invalid) {
      return;
    }
    // continue work here
    window.scrollTo(0, 0);
    const formData = new FormData();
    formData.append('FirstName', this.registerForm.controls['firstname'].value!);
    formData.append('LastName', this.registerForm.controls['lastname'].value!);
    formData.append('Email', this.registerForm.controls['email'].value!);
    formData.append('Login', this.registerForm.controls['email'].value!);
    formData.append('Client', this.selectedClient.name);
    formData.append('ClientIds', this.selectedClient.clientId);
    formData.append('isActive', `true`);

    if(this.registerForm.controls['title'].value) formData.append('Title', this.registerForm.controls['title'].value);
    if(this.registerForm.controls['isClientContact'].value) formData.append('IsClientContact', `true`);
    if(this.registerForm.controls['language'].value) formData.append('LanguageCode', this.registerForm.controls['language'].value);
    if(this.registerForm.controls['salutation'].value) formData.append('Salutation', this.registerForm.controls['salutation'].value);
    if(this.registerForm.controls['address'].value) formData.append('Street', this.registerForm.controls['address'].value);
    if(this.registerForm.controls['city'].value) formData.append('City', this.registerForm.controls['city'].value);
    if(this.registerForm.controls['state'].value) formData.append('State', this.registerForm.controls['state'].value);
    if(this.registerForm.controls['country'].value) formData.append('Country', this.registerForm.controls['country'].value);
    if(this.registerForm.controls['zip'].value) formData.append('Zip', `${this.registerForm.controls['zip'].value}`);
    if(this.registerForm.controls['usertype'].value) formData.append('RoleId', this.registerForm.controls['usertype'].value);
    if(this.registerForm.controls['phone'].value) formData.append('Phone', `${this.registerForm.controls['phone'].value}`);

    formData.append('CreatedByUserId', `${this.loggedInUser?.id}`);
    formData.append('AccessOnGroups', this.selectedGroups.map((group:any) => group.id).join(","));
    
    if(this.file)
      formData.append('Image', this.file, this.file?.name);
    
    this.createUser(formData);
  }

  createUser(formData: FormData){
    this.isLoading = true;
    let sendEmail = this.registerForm.controls['sendemail'].value!;
    this.appService.addUser(formData, sendEmail)
      .subscribe((data) => {
        this.isLoading = false;
        this.resetForm();
        this.showSnackbar("User Added Successfully");

        this.router.navigate(["/users"]);
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
      });
  }

  resetForm(){
    this.registerForm.reset();
    this.selectedClient = "";
    this.selectedGroups = [];
    this.file = null;

    this.registerForm.controls['email'].setErrors(null);
    this.registerForm.controls['client'].setErrors(null);
    this.registerForm.controls['groups'].setErrors(null);
    this.registerForm.controls['firstname'].setErrors(null);
    this.registerForm.controls['lastname'].setErrors(null);
    this.registerForm.controls['language'].setErrors(null);
  }

  onFileChange(event: any) {
    this.file = null;
    this.fileErr = "";
    if(event.target.files[0] == null){
      return;
    }
    this.file = event.target.files[0];

    let type: string = this.file?.type!;
    if(type !== 'image/png' && type !== 'image/jpeg'){
      this.fileErr = "Invalid file!"
      return;
    }
    
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target?.result as string;
      image.onload = () => {
        const {
          height,
          width
        } = image;
        if (height > 160 || width > 160) {
          this.fileErr = "Height and width must not exceed 160px.";
          return false;
        }
        return true;
      };
    };
    
    // let sizeInBytes: number = this.file?.size!;
    // if(sizeInBytes > 256000){
    //   this.fileErr = "File too large!"
    //   return;
    // }
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  onCancel() {
    this.location.back();
  }

}

