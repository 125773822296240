<!--loader-->
<div id="loading" *ngIf="isLoadingResults || (dataSource.loading$ | async)">
    <div class="loaderbox">
      <div class="darkbackground-box"></div>      
      <div class="spinnerbox-ic">
          <mat-spinner style="width: 55px;height:55px"></mat-spinner>
      </div>     
    </div> 
  </div>
  
  <!-- Parallax Section -->
  <div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-1">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <div class="d-flex col-md-9 col-sm-12 col-xs-12 pt-4">
          <form [formGroup]="filterForm" >
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Search by Task</mat-label>
                <input matInput formControlName="sptask" name="sptask" placeholder="Ex. Migrate" #spTaskInput>
            </mat-form-field>              
              <mat-form-field appearance="outline" class="form-field">
                <mat-label>Search by Admin Name/Admin Email</mat-label>
                <input matInput formControlName="admin" name="admin" placeholder="Ex. Gaurav" #adminInput>
            </mat-form-field>     
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Search by Entity</mat-label>
                <input matInput formControlName="entity" name="entity"  #entityInput>
            </mat-form-field>   
            <mat-form-field class="example-form-field">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="rangePicker" [formGroup]="dateRange" >
                <input matStartDate placeholder="Start date" formControlName="start">
                <input matEndDate placeholder="End date" formControlName="end">
              </mat-date-range-input>
              <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
              <mat-date-range-picker #rangePicker>
                <mat-date-range-picker-actions>
                  <button mat-button matDateRangePickerCancel>Cancel</button>
                  <button mat-raised-button color="primary" matDateRangePickerApply (click)="onDateSelect()">Apply</button>
                </mat-date-range-picker-actions>
              </mat-date-range-picker>
            </mat-form-field>   
            
            
            <button href="#" class="btn btn-sm" (click)="onClearFilters()">Clear filters</button>
          </form>
        </div>
    
        <div class="col-md-3 col-sm-12 col-xs-12 box-n">
          <div class="d-flex flex-row-reverse bd-highlight" *ngIf="loggedInUser">
            <button type="button" class="btn btn-dark d-flex align-items-center justify-content-center" (click)="exportForm()">
              <mat-icon>download</mat-icon>
              <span class="m-2">Export CSV</span>
            </button>
          </div>
        </div>
      </div>
    
      <div class="mat-elevation-z8 data-row-columm mb-3" >
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows matSort matSortActive="id" matSortDirection="asc" matSortDisableClear>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
          <!-- Name Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
          </ng-container>
      
          <!-- ClientId Column -->
          <ng-container matColumnDef="adminName">
            <th mat-header-cell *matHeaderCellDef> Admin Name </th>
            <td mat-cell *matCellDef="let row"> {{row.adminName}} </td>
          </ng-container>
    
          <!-- SP Site Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Admin Email </th>
            <td mat-cell *matCellDef="let row"> {{row.email}} </td>
          </ng-container>
    
          <!-- All Users Count Column -->
          <ng-container matColumnDef="task">
            <th mat-header-cell *matHeaderCellDef> Task </th>
            <td mat-cell *matCellDef="let row"> {{row.task}} </td>
          </ng-container>
    
          <!-- Aon Users Count Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let row">
                 {{row.status}}              
            </td>
          </ng-container>
    
          <!-- Country Column-->
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef> Created At </th>
            <td mat-cell *matCellDef="let row">
                {{row.createdAt}} 
            </td>
          </ng-container> 

           <!-- Inactive Users Column-->
           <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef> Updated At </th>
            <td mat-cell *matCellDef="let row">
                {{row.updatedAt}} 
            </td>
          </ng-container> 

           <!-- ACX Only Column-->
           <ng-container matColumnDef="entityId">
            <th mat-header-cell *matHeaderCellDef> Entity Id </th>
            <td mat-cell *matCellDef="let row">
                {{row.entityId}} 
            </td>
          </ng-container> 
          
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filters</td>
          </tr>
        </table>
      
        <mat-paginator [length]="dataSource.maxCount$ | async" [pageSize]="10" [pageSizeOptions]="[10, 50, 100, 500]" aria-label="Select page of dataSource"></mat-paginator>
      </div>
    
    
    </div>
  </div>
  