import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss']
})
export class FileSelectorComponent {

  multiple: boolean = true;
  allowedTypes: string[] = [];
  sampleFileUrl: string;
  files: any[] = [];

  error: string | null = null;

  constructor(private dialogRef: MatDialogRef<FileSelectorComponent>, @Inject(MAT_DIALOG_DATA) public data: {multiple: boolean, allowedTypes: string[], sampleFileUrl: string}) {
    this.multiple = data.multiple ?? false;
    this.allowedTypes = data.allowedTypes ?? [];
    this.sampleFileUrl = data.sampleFileUrl ?? '';

    console.log(this.sampleFileUrl);
  }

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event: any) {
    if(event.target.files == null || event.target.files[0] == null){
      return;
    }else{
      this.prepareFilesList(event.target.files);
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    this.error = null;
    if(!this.multiple){
      this.files = [];
    }

    if(this.validate(files) === false){
      return;
    }

    if(this.multiple){
      for (const item of files) {
        item.progress = 0;
        this.files.push(item);
      }
    }else{
      this.files = [files[0]];
    }
  }

  validate(files: Array<any>){
    for (const item of files) {
      if(this.allowedTypes.length > 0){
        let type: string = item.type!;
        if(!this.allowedTypes.includes(type)){
          if(this.multiple){
            this.error = 'Invalid file/s Type';
          }else{
            this.error = 'Invalid file Type';
          }
          return false;
        }
      } 
    }
    return true;
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  downloadSample(){
    if(this.sampleFileUrl){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', this.sampleFileUrl);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
  

  onClose(){
    this.dialogRef.close(null);
  }

  onSubmit(){
    this.dialogRef.close(this.files);
  }
}
