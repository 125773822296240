import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AppService } from '../app.service';
import { AuthGuard } from '../auth/authguard.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-admin',
  templateUrl: './edit-admin.component.html',
  styleUrls: ['./edit-admin.component.scss']
})
export class EditAdminComponent  implements OnInit {

  loggedInUser : LoggedInUser;

  id: number | null;
  admin: any;
  isLoading: boolean = false;

  editForm = new FormGroup({
    email: new FormControl({value: "", disabled: true}, [Validators.required, Validators.email]),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    countries: new FormControl('', [Validators.required]),
    psuperadmin: new FormControl(false, [Validators.required]),
    pspsite: new FormControl(false, [Validators.required]),
  });

  allCountries: any[];
  selectedCountries: any = [];

  constructor(private appService: AppService, private http: HttpClient, private authGuard: AuthGuard, private _snackBar: MatSnackBar, private location: Location, private route: ActivatedRoute) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.id = parseInt(this.route.snapshot.paramMap.get('id')!); 
    this.getAdminById(this.id);
    this.loadCountries();
  }

  ngAfterViewInit() {
    
  }

  loadCountries(){
    this.appService.getCountries(this.loggedInUser).subscribe((response: any) => {
      this.allCountries = response;
      this.updateAllFields();
    });
  }

  getAdminById(id: number | null){
    if(id != null){
      this.appService.getAdminById(id).subscribe((response) => {
        this.admin = response;
        this.updateAllFields()
      },(e) => {
        console.log('findExistingUser', e);
        this.showSnackbar(e.message);
        this.isLoading = false;
      });
    }
  }

  updateAllFields(){
    if(this.admin && this.allCountries && this.allCountries.length > 0){
      this.editForm.controls['email'].setValue(this.admin.email);
      this.editForm.controls['firstname'].setValue(this.admin.firstName);
      this.editForm.controls['lastname'].setValue(this.admin.lastName);
      this.editForm.controls['psuperadmin'].setValue(this.admin.roleId == 0);
      this.editForm.controls['pspsite'].setValue(this.admin.pCreateSpSite);
      let selectedCountryIds = this.admin.mapAdminCountries.map((ac: { countryId: number; }) => ac.countryId);
      this.selectedCountries = this.allCountries.filter(c => selectedCountryIds.includes(c.id));
      this.isLoading = false;
    }
  }

  onSubmit(): void {
    if (this.editForm.invalid) {
      return;
    }
    window.scrollTo(0, 0);

    let adminUser = {
      Id: this.id,
      FirstName: this.editForm.controls['firstname'].value!,
      LastName: this.editForm.controls['lastname'].value!,
      Email: this.editForm.controls['email'].value!,
      IsSuperAdmin: this.editForm.controls['psuperadmin'].value!,
      PCreateSpSite: this.editForm.controls['pspsite'].value!,
      countryIds: this.selectedCountries.map((selectedCountry: { id: any; }) => {
        return selectedCountry.id;
      })
    }
    
    this.updateAdmin(adminUser);
  }

  
  updateAdmin(adminUser: any) {
    console.log(adminUser);
    this.isLoading = true; 
    this.appService.updateAdmin(adminUser)
      .subscribe((data) => {
        this.isLoading = false;
        this.showSnackbar("Admin updated successfully");
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
      }); 
  }

  resetForm(){
    this.editForm.reset();
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  onCancel() {
    this.location.back();
  }

}
