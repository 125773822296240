<!-- banner start -->
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 rmv-pd">
            <div class="containerbox-banner"></div>

        </div>
    </div>
</div>
<!-- banner close-->

<!--loader-->
<div id="loading">
    <div class="loaderbox">

        <div class="darkbackground-box"></div>
        
        <div class="spinnerbox-ic">
            <mat-spinner style="width: 55px;height:55px"></mat-spinner>
        </div>
        
        
        </div>
   
  </div>
  
  
<!--form area -->

<div class="container form-area-box">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 hdbox-fm">
            Create Subgroup for Clients            
        </div>
        <span class="error-msg-txt">{{message}}</span>
        <span class="error-msg-txt">{{message1}}</span>
    </div>

    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

    <div class="row">
        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Select Client :</div>
        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
            <mat-form-field class="example-full-width">
                <mat-label>Search Clients</mat-label>
                <input matInput placeholder="Search Clients" [(ngModel)]="selectedClient" [matAutocomplete]="auto"
                    formControlName="client" name="client" placeholder="Min 2 characters to load">
                <div *ngIf="registerForm.controls['client'].touched && registerForm.controls['client'].invalid">
                    <mat-error *ngIf="registerForm.controls['client'].errors?.['required']">This field is required</mat-error>
                </div>
                <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onClientSelected()"
                  [displayWith]="displayClientWith">
                  <mat-option *ngIf="isLoadingClients" class="is-loading">Loading...</mat-option>
                  <ng-container *ngIf="!isLoadingClients">
                    <mat-option *ngFor="let client of filteredClients" [value]="client">
                      <span>{{client.name}} ({{client.clientId}})</span>
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    <!-- <div class="row frm">
        <div class="col-md-2 col-sm-3 col-xs-3 txt-ad-box">Client:</div>
        <div class="col-md-10 col-sm-10 col-xs-10 input-ad-box">
            <select class="input-ad-box dropdown select-btn" style="width: 350px" id="client" [(ngModel)]="client"
            name="client" >
            <option selected="true" disabled value="-1">Select Client</option>
            <option *ngFor="let client of clientList" [ngValue]="client">
                {{client.name}}</option>
        </select>
        </div>
    </div> -->

    <!-- <div class="row frm">
        <div class="col-md-2 col-sm-3 col-xs-3 txt-ad-box">Subgroup :</div>
        <div class="col-md-10 col-sm-10 col-xs-10 input-ad-box">
            <select class="input-ad-box dropdown select-btn" style="width: 350px" id="tool" [(ngModel)]="selectedTool"
            name="tool" (change)="onChange()">
            <option selected="true" disabled value="-1">Select Subgroup</option>
            <option *ngFor="let tool of toolsList" [value] = 'tool.title'>
                {{tool.title}}</option>
        </select>
        </div>
    </div> -->

    <div class="row">
        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Subgroup :</div>
        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
            <mat-form-field appearance="outline" class="form-field m-0 p-0">
                <mat-label>Select Subgroup</mat-label>
                <mat-select formControlName="tool" name="tool" (selectionChange)="onChange()">
                    <mat-option *ngFor="let tool of toolsList" [value]="tool">
                        {{tool.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"></div>
        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
            <button type="submit" class="svn-btn" [disabled]="this.registerForm.invalid">Submit</button>
            <button (click)="onCancel()" type="button" class="cn-btn">Cancel</button>
        </div>
    </div>
    <!-- <div class="row frm">
        <div class="col-md-2 col-sm-3 col-xs-3 txt-ad-box"></div>
        <div class="col-md-10 col-sm-10 col-xs-10 input-ad-box">
          <a   type="submit" class="svn-btn">Save</a> <a href="#" class="cn-btn">Cancel</a></div>
    </div> -->
</form>
</div>