<!--loader-->
<div id="loading" *ngIf="isLoadingResults || (dataSource.loading$ | async)">
    <div class="loaderbox">
      <div class="darkbackground-box"></div>      
      <div class="spinnerbox-ic">
          <mat-spinner style="width: 55px;height:55px"></mat-spinner>
      </div>     
    </div> 
  </div>
  
  <!-- Parallax Section -->
  <div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-1">
      <div class="row my-2">
        
        <!-- <div class="col-md-8 col-sm-8 col-xs-8 sorting-contentbox d-flex align-items-center justify-content-end">
          <button type="button" class="btn btn-dark m-2 d-flex align-items-center justify-content-center" [routerLink]="['/admins/new']">
            <mat-icon>add_circle_outline</mat-icon>
            <span class="m-2">Add new admin</span>
          </button>
        </div> -->
      </div>
  
      <div>
        <form [formGroup]="filterForm" style="float: left;">          
          <mat-form-field class="example-full-width form-field" appearance='outline'>
            <mat-label>Filter by Country</mat-label>
            <input matInput placeholder="Select Country" [(ngModel)]="selectedCountry" [matAutocomplete]="auto"
                formControlName="country" name="country" placeholder="Country code">
            <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onCountrySelected()"
              [displayWith]="displayCountryWith">
              <mat-option *ngIf="isLoadingCountries" class="is-loading">Loading...</mat-option>
              <ng-container *ngIf="!isLoadingCountries">
                <mat-option *ngFor="let country of filteredCountries" [value]="country">
                  <span>{{country.code}}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
          <button href="#" class="btn btn-sm" (click)="onClearFilters()">Clear filters</button>
        </form>
        <button type="button" class="btn btn-dark m-2 d-flex align-items-center justify-content-center" style="float: right;"(click)="exportForm()">
          <mat-icon>download</mat-icon>
          <span class="m-2">Export CSV</span>
        </button>
      </div>
    
      <div class="mat-elevation-z8 data-row-columm mb-3" >
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows matSort matSortActive="id" matSortDirection="asc" matSortDisableClear>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
          </ng-container>
      
          <!-- ClientId Column -->
          <ng-container matColumnDef="clientId">
            <th mat-header-cell *matHeaderCellDef> Client Id </th>
            <td mat-cell *matCellDef="let row"> {{row.clientId}} </td>
          </ng-container>
    
          <!-- SP Site Column -->
          <ng-container matColumnDef="spSite">
            <th mat-header-cell *matHeaderCellDef> SP Site Status </th>
            <td mat-cell *matCellDef="let row"> {{row.spSite ? 'Yes' : 'No'}} </td>
          </ng-container>
    
          <!-- All Users Count Column -->
          <ng-container matColumnDef="allUsersCount">
            <th mat-header-cell *matHeaderCellDef> All Users Count </th>
            <td mat-cell *matCellDef="let row"> {{row.allUsersCount}} </td>
          </ng-container>
    
          <!-- Aon Users Count Column -->
          <ng-container matColumnDef="aonUsersCount">
            <th mat-header-cell *matHeaderCellDef> Aon Users Count </th>
            <td mat-cell *matCellDef="let row">
                 {{row.aonUsersCount}}              
            </td>
          </ng-container>
    
          <!-- Country Column-->
          <ng-container matColumnDef="countryCode">
            <th mat-header-cell *matHeaderCellDef> Country </th>
            <td mat-cell *matCellDef="let row">
                {{row.countryCode}} 
            </td>
          </ng-container> 

           <!-- Inactive Users Column-->
           <ng-container matColumnDef="inactiveUsers">
            <th mat-header-cell *matHeaderCellDef> Inactive Users </th>
            <td mat-cell *matCellDef="let row">
                {{row.inactiveUsers}} 
            </td>
          </ng-container> 

           <!-- ACX Only Column-->
           <ng-container matColumnDef="acxOnly">
            <th mat-header-cell *matHeaderCellDef> ACX Only </th>
            <td mat-cell *matCellDef="let row">
                {{row.acxOnly}} 
            </td>
          </ng-container> 
          
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filters</td>
          </tr>
        </table>
      
        <mat-paginator [length]="dataSource.maxCount$ | async" [pageSize]="10" [pageSizeOptions]="[10, 50, 100, 500]" aria-label="Select page of dataSource"></mat-paginator>
      </div>
    
    
    </div>
  </div>
  