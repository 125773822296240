import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, catchError, finalize, Observable, of } from "rxjs";
import { LoggedInUser } from "src/models/LoggedInUser";
import { AppService } from "../app.service";

export class UserDataSource implements DataSource<any> {

    private usersSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private maxCountSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public maxCount$ = this.maxCountSubject.asObservable();

    constructor(private appService: AppService) {}

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.usersSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.usersSubject.complete();
        this.loadingSubject.complete();
    }

    loadUsers(pageIndex = 0, pageSize = 10, filter = '', clientId = 0, subgroupId = 0, type = "", country = "", isActive : boolean | null = null, emailSent : boolean | null = null, sortBy = 'name', sortDirection = 'asc') {

        this.loadingSubject.next(true);

        let offset = pageIndex * pageSize;
        this.appService.getUsers(pageSize, offset, filter, clientId, subgroupId, type, country, isActive, emailSent, sortBy, sortDirection).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(result => {
            this.usersSubject.next(result.data);
            this.maxCountSubject.next(result.maxCount);
        });
    }  
    
    getDataLength(): Number{
        return this.usersSubject.value.length
    }

    getData(): any[]{
        return this.usersSubject.value;
    }
}