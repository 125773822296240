<!--loader-->
<div *ngIf="isLoading">
    <div class="loaderbox">
        <div class="darkbackground-box"></div>
        <div class="spinnerbox-ic">
            <mat-spinner style="width: 55px;height:55px"></mat-spinner>
        </div>
    </div>
</div>

<div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-3">
        <div class="row hdbox-fm my-2">
            <div class="col-md-12 col-sm-12 col-xs-12 hdbox-fm-grid d-flex align-items-center">
                Create Client
            </div>
        </div>

        <form [formGroup]="createForm" (ngSubmit)="onSubmit()" class="my-3">

            <div class="row">

                <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="row">
                        <div class="col-md-5 col-sm-12 col-xs-12 txt-ad-box text-md-end"><span class="text-danger">* </span>Select Country :</div>
                        <div class="col-md-7 col-sm-12 col-xs-12 input-ad-box">
                            <mat-form-field class="example-full-width">
                                <mat-label>Search Country</mat-label>
                                <input matInput placeholder="Search countries" [(ngModel)]="selectedCountry" [matAutocomplete]="auto"
                                    formControlName="country" name="country" placeholder="Enter country code">
                                <div *ngIf="createForm.controls['country'].touched && createForm.controls['country'].invalid">
                                    <mat-error *ngIf="createForm.controls['country'].errors?.['required']">This field is required</mat-error>
                                </div>
                                <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onCountrySelected()"
                                  [displayWith]="displayCountryWith">
                                  <mat-option *ngIf="isLoadingCountries" class="is-loading">Loading...</mat-option>
                                  <ng-container *ngIf="!isLoadingCountries">
                                    <mat-option *ngFor="let country of filteredCountries" [value]="country">
                                      <span>{{country.name}} ({{country.code}})</span>
                                    </mat-option>
                                  </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-5 col-sm-12 col-xs-12 txt-ad-box text-md-end"><span class="text-danger">* </span>Client Name :</div>
                        <div class="col-md-7 col-sm-12 col-xs-12 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0">
                                <mat-label>Enter client name</mat-label>
                                <input matInput formControlName="name" name="name">
                                <div *ngIf="createForm.controls['name'].touched && createForm.controls['name'].invalid">
                                    <mat-error *ngIf="createForm.controls['name'].errors?.['required']">This field is required</mat-error>
                                    <mat-error *ngIf="createForm.controls['name'].errors?.['exists']">Client name already exists</mat-error>
                                </div>
                            </mat-form-field>
                            <br>
                            <button type="button" class="btn btn-dark btn-sm mb-3" *ngIf="clientExists && canEditExistingClient" [routerLink]="['/clients', existingClientId, 'edit']">Edit existing client</button>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-5 col-sm-12 col-xs-12 txt-ad-box text-md-end"><span class="text-danger">* </span>Type :</div>
                        <div class="col-md-7 col-sm-12 col-xs-12 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0">
                                <mat-label>Client type</mat-label>
                                <mat-select formControlName="type" name="type">
                                    <mat-option *ngFor="let type of optionClientTypes" [value]="type.value">
                                        {{type.text}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="createForm.controls['type'].touched && createForm.controls['type'].invalid">
                                    <mat-error *ngIf="createForm.controls['type'].errors?.['required']">This field is required</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-5 col-sm-12 col-xs-12 txt-ad-box text-md-end"><span class="text-danger">* </span>Industry :</div>
                        <div class="col-md-7 col-sm-12 col-xs-12 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0">
                                <mat-label>Client's industry</mat-label>
                                <mat-select formControlName="industry" name="industry" [(ngModel)]="selectedIndustry">
                                    <mat-option *ngFor="let industry of allIndustries" [value]="industry">
                                        {{industry.title}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="createForm.controls['industry'].touched && createForm.controls['industry'].invalid">
                                    <mat-error *ngIf="createForm.controls['industry'].errors?.['required']">This field is required</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-5 col-sm-12 col-xs-12 txt-ad-box text-md-end">Provide Access on :<div class="smt-txt">(Multiple choice field and its values are ACX, GAMS, Shopie, Aon Bond Link )</div></div>
                        <div class="col-md-7 col-sm-12 col-xs-12 input-ad-box">
                            <mat-form-field appearance="outline" class="dropdown-access">
                                <mat-label>Select Apps</mat-label>
                                <mat-select formControlName="tools" name="tools" [(ngModel)]="selectedTools" multiple panelClass="input-ad-box dropdown">
                                    <mat-select-trigger>
                                        {{(selectedTools.length > 0) ? selectedTools[0].title : ''}}
                                        <span *ngIf="(selectedTools.length || 0) > 1" class="example-additional-selection">
                                            (+{{(selectedTools.length || 0) - 1}} {{selectedTools.length === 2 ? 'other' : 'others'}})
                                        </span>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let tool of allTools" [value]="tool" [disabled]="tool.disabled">{{tool.title}}</mat-option>
                                </mat-select>
                                <mat-hint *ngIf="noTools">No tools available for selected country</mat-hint>
                                <mat-hint  *ngIf="isCollabToolSelected" class="text-primary">Selecting 'Sharepoint Collaboration' tool will create a new Sharepoint collaboration site for this client.</mat-hint>
                            </mat-form-field>
                            <div *ngIf="noTools || isCollabToolSelected" class="mb-4"></div>
                        </div>
                    </div>
        
                    <div class="row">
                        <div class="col-md-5 col-sm-12 col-xs-12 txt-ad-box text-md-end">Upload Logo :<div class="smt-txt">Max 165x44 px, JPEG/PNG</div></div>
                        <div class="col-md-7 col-sm-12 col-xs-12 input-ad-box">
                            <div>
                                <input class="form-control upload-file-btn" type="file" (change)="onFileChange($event)">
                                <mat-error *ngIf="fileErr">{{fileErr}}</mat-error>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4" *ngIf="!isRealEstateToolSelected && !isRequestQuoteToolSelected && !isClaimsToolSelected">
                        <div class="d-flex justify-content-center">
                            <button type="submit" class="svn-btn" [disabled]="this.createForm.invalid || fileErr">Submit</button>
                            <button (click)="onCancel()" type="button" class="cn-btn">Cancel</button>
                        </div>
                    </div>
    
                </div>

                <div class="col-md-6 col-sm-12 col-xs-12">

                    <div *ngIf="isRealEstateToolSelected || isRequestQuoteToolSelected">
                        <p class="h6 ">Real Estate / Request Quote Fields</p>
                        <div class="row mt-2" *ngIf="isRealEstateToolSelected || isRequestQuoteToolSelected">
                            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Site Url :</div>
                            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                                <mat-form-field appearance="outline" class="form-field m-0 p-0">
                                    <mat-label>Enter Site Url</mat-label>
                                    <input matInput formControlName="spUrl" name="spUrl">
                                    <div *ngIf="createForm.controls['spUrl'].touched && createForm.controls['spUrl'].invalid">
                                        <mat-error *ngIf="createForm.controls['spUrl'].errors?.['required']">This field is required</mat-error>
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    
                    <div *ngIf="isClaimsToolSelected">
                        <p class="h6" >Global Claims Intake Fields</p>
                        <div class="row mt-2">
                            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>ODS Client Id/s (CSV) :</div>
                            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                                <mat-form-field appearance="outline" class="form-field m-0 p-0 mb-2">
                                    <mat-label>Enter ODS Client Id: eg 1363340 or 1363340,1363341,1363342...</mat-label>
                                    <input matInput formControlName="odsClientIds" name="odsClientIds">
                                    <mat-hint >*eg 1363340 or 1363340,1363341,1363342...</mat-hint>
                                    <div *ngIf="createForm.controls['odsClientIds'].touched && createForm.controls['odsClientIds'].invalid">
                                        <mat-error *ngIf="createForm.controls['odsClientIds'].errors?.['required']">This field is required</mat-error>
                                    </div>
                                </mat-form-field>
                                <br>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Primary SPOC Email :</div>
                            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                                <mat-form-field appearance="outline" class="form-field m-0 p-0">
                                    <mat-label>Enter Primary SPOC's Email</mat-label>
                                    <input matInput formControlName="primaryclaimspocemail" name="primaryclaimspocemail">
                                    <mat-hint *ngIf="createForm.controls['primaryclaimspocemail'].touched && !createForm.controls['primaryclaimspocemail'].invalid">{{selectedPrimaryClaimSpocFName}} {{selectedPrimaryClaimSpocLName}}</mat-hint>
                                    <div *ngIf="createForm.controls['primaryclaimspocemail'].touched && createForm.controls['primaryclaimspocemail'].invalid">
                                        <mat-error *ngIf="createForm.controls['primaryclaimspocemail'].errors?.['required']">This field is required</mat-error>
                                        <mat-error *ngIf="createForm.controls['primaryclaimspocemail'].errors?.['email']">Invalid email address!</mat-error>
                                        <mat-error *ngIf="createForm.controls['primaryclaimspocemail'].errors?.['employeenotfound']">Employee not found!</mat-error>
                                    </div>
                                </mat-form-field>
                                <br>
                            </div>
                        </div>
                    
                        <div class="row mt-3">
                            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Secondary SPOC Email :</div>
                            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                                <mat-form-field appearance="outline" class="form-field m-0 p-0">
                                    <mat-label>Enter Secondary SPOC's Email</mat-label>
                                    <input matInput formControlName="secondaryclaimspocemail" name="secondaryclaimspocemail">
                                    <mat-hint *ngIf="createForm.controls['secondaryclaimspocemail'].touched && !createForm.controls['secondaryclaimspocemail'].invalid">{{selectedSecondaryClaimSpocFName}} {{selectedSecondaryClaimSpocLName}}</mat-hint>
                                    <div *ngIf="createForm.controls['secondaryclaimspocemail'].touched && createForm.controls['secondaryclaimspocemail'].invalid">
                                        <mat-error *ngIf="createForm.controls['secondaryclaimspocemail'].errors?.['required']">This field is required</mat-error>
                                        <mat-error *ngIf="createForm.controls['secondaryclaimspocemail'].errors?.['email']">Invalid email address!</mat-error>
                                        <mat-error *ngIf="createForm.controls['secondaryclaimspocemail'].errors?.['employeenotfound']">Employee not found!</mat-error>
                                    </div>
                                </mat-form-field>
                                <br>
                            </div>
                        </div>

                    </div>

                    <div class="row mt-3" *ngIf="isRealEstateToolSelected || isRequestQuoteToolSelected || isClaimsToolSelected">
                        <div class="d-flex justify-content-center">
                            <button type="submit" class="svn-btn" [disabled]="this.createForm.invalid || fileErr">Submit</button>
                            <button (click)="onCancel()" type="button" class="cn-btn">Cancel</button>
                        </div>
                    </div>

                </div>
            </div>

            
            
        </form>
    
    </div>
</div>