<!--loader-->
<div *ngIf="isLoading">
  <div class="loaderbox">
      <div class="darkbackground-box"></div>
      <div class="spinnerbox-ic">
          <mat-spinner style="width: 55px;height:55px"></mat-spinner>
      </div>
    </div>
</div>

<div class="jumbotron paral paralsec py-5">
  <div class="container card px-4 py-3">
    <div class="row hdbox-fm my-2">
      <div class="col-md-12 col-sm-12 col-xs-12 hdbox-fm-grid d-flex align-items-center">
        Edit User - <span >{{user?.firstName}} {{user?.lastName}}  ({{ user?.username }})</span>
      </div>
    </div>
  
    <div class="row my-3">
      <div class="col-md-5 col-sm-12">
        <!-- Left Section -->
        <form [formGroup]="editUserForm" (ngSubmit)="onEditUserSubmit()">
  
          <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Email Address :</div>
              <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                  <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                      <mat-label>User's email address</mat-label>
                      <input matInput formControlName="email" name="email">
                  </mat-form-field>
              </div>
          </div>
      
          <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>First Name :</div>
              <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                  <mat-form-field appearance="outline" class="form-field" [style.width.%]="90">
                      <mat-label>Enter user's first name</mat-label>
                      <input matInput formControlName="firstname" name="firstname">
                      <div *ngIf="editUserForm.controls['firstname'].touched && editUserForm.controls['firstname'].invalid">
                          <mat-error *ngIf="editUserForm.controls['firstname'].errors?.['required']">This field is required</mat-error>
                      </div>
                  </mat-form-field>
              </div>
          </div>
      
          <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Last Name :</div>
              <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                  <mat-form-field appearance="outline" class="form-field" [style.width.%]="90">
                      <mat-label>Enter user's last name</mat-label>
                      <input matInput formControlName="lastname" name="lastname">
                      <div *ngIf="editUserForm.controls['lastname'].touched && editUserForm.controls['lastname'].invalid">
                          <mat-error *ngIf="editUserForm.controls['lastname'].errors?.['required']">This field is required</mat-error>
                      </div>
                  </mat-form-field>
              </div>
          </div>
        
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Select User Type :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field" [style.width.%]="90">
                    <mat-label>Select the type of user</mat-label>
                    <mat-select formControlName="usertype" name="usertype">
                      <ng-container *ngFor="let userType of optionUserTypes" >
                        <mat-option  [value]="userType.id" [ngStyle]="{ display : userType.status==0 ? 'none' : 'block' }">
                            {{userType.text}}
                        </mat-option>
                      </ng-container>                     
                    </mat-select>
                    <div *ngIf="editUserForm.controls['usertype'].touched && editUserForm.controls['usertype'].invalid">
                      <mat-error *ngIf="editUserForm.controls['usertype'].errors?.['required']">This field is required</mat-error>
                    </div>
                </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Title :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field" [style.width.%]="90">
                    <mat-label>Enter user's Title</mat-label>
                    <input matInput formControlName="title" name="title">
                </mat-form-field>
            </div>
           </div>
  
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Language :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field" [style.width.%]="90">
                    <mat-label>Select user's preferred language</mat-label>
                    <mat-select formControlName="language" name="language">
                        <mat-option *ngFor="let language of optionLanguages" [value]="language.value">
                            {{language.text}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
          </div>
  
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Salutation :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field" [style.width.%]="90">
                    <mat-label>Select a salutation</mat-label>
                    <mat-select formControlName="salutation" name="salutation">
                        <mat-option value="">None</mat-option>
                        <mat-option *ngFor="let salutation of optionSalutations" [value]="salutation.value">
                            {{salutation.text}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
          </div>
          
          <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Address :</div>
              <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                  <mat-form-field appearance="outline" class="form-field" [style.width.%]="90">
                      <mat-label>Enter user's address</mat-label>
                      <input matInput formControlName="address" name="address">
                  </mat-form-field>
              </div>
          </div>
      
          <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">City :</div>
              <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                  <mat-form-field appearance="outline" class="form-field" [style.width.%]="90">
                      <mat-label>Enter user's city</mat-label>
                      <input matInput formControlName="city" name="city">
                  </mat-form-field>
              </div>
          </div>
  
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">State :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field" [style.width.%]="90">
                    <mat-label>Enter user's state</mat-label>
                    <input matInput formControlName="state" name="state">
                </mat-form-field>
            </div>
          </div>
      
          <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Country :</div>
              <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                  <mat-form-field appearance="outline" class="form-field" [style.width.%]="90">
                      <mat-label>Enter user's country</mat-label>
                      <mat-select formControlName="country" name="country">
                        <mat-option *ngFor="let country of optionCountries" [value]="country.value">
                            {{country.text}}
                        </mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>
          </div>
          
          <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Zip :</div>
              <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                  <mat-form-field appearance="outline" class="form-field" [style.width.%]="90">
                      <mat-label>Enter user's zip</mat-label>
                      <input matInput formControlName="zip" name="zip">
                  </mat-form-field>
              </div>
          </div>
      
          <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Telephone :</div>
              <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                  <mat-form-field appearance="outline" class="form-field" [style.width.%]="90">
                      <mat-label>Select user's telephone</mat-label>
                      <input matInput formControlName="phone" name="phone">
                  </mat-form-field>
              </div>
          </div>
      
          <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Upload Photo :<div class="smt-txt">Max 160x160 px, JPEG/PNG</div></div>
              <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                  <div>
                      <input class="form-control upload-file-btn" type="file" (change)="onPhotoChange($event)" [style.width.%]="90" #inputFile>
                      <mat-error *ngIf="fileErr">{{fileErr}}</mat-error>
                  </div>
              </div>
          </div>
      
          <div class="row mt-3">
              <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"></div>
              <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                  <button type="submit" class="svn-btn" [disabled]="this.editUserForm.invalid || fileErr">Save</button>
                  <button (click)="onCancel()" type="button" class="cn-btn">Cancel</button>
              </div>
          </div>
        </form>
      </div>
  
  
  
      <div class="col-md-7 col-sm-12">
        <!-- Right Section -->
        <p class="h5 pb-2">Add/Edit User's Clients</p>
        <form [formGroup]="userClientForm" #formDirective="ngForm" (ngSubmit)="onClientsSubmit(userClientForm, formDirective)" class="form-inline">

          <div class="row">
            <div class="col-md-4 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field class="example-full-width" [style.width.%]="100">
                    <mat-label>Search Clients</mat-label>
                    <input matInput placeholder="Search Clients" [(ngModel)]="selectedClient" [matAutocomplete]="auto"
                        formControlName="client" name="client" placeholder="Min 2 characters to load">
                    <div *ngIf="userClientForm.controls['client'].touched && userClientForm.controls['client'].invalid">
                        <mat-error *ngIf="userClientForm.controls['client'].errors?.['required']">This field is required</mat-error>
                    </div>
                    <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onClientSelected()"
                      [displayWith]="displayClientWith">
                      <mat-option *ngIf="isLoadingClients" class="is-loading">Loading...</mat-option>
                      <ng-container *ngIf="!isLoadingClients">
                        <mat-option *ngFor="let client of filteredClients" [value]="client">
                          <span>{{client.name}} ({{client.clientId}})</span>
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="col-md-4 col-sm-10 col-xs-10 input-ad-box">
              <mat-form-field appearance="outline" class="dropdown-access" [style.width.%]="100">
                  <mat-label>Select Groups/Subgroups</mat-label>
                  <mat-select formControlName="groups" name="groups" [(ngModel)]="selectedGroups" multiple panelClass="input-ad-box dropdown">
                      <mat-select-trigger>
                          {{(selectedGroups.length > 0) ? selectedGroups[0].name : ''}}
                          <span *ngIf="(selectedGroups.length || 0) > 1" class="example-additional-selection">
                              (+{{(selectedGroups.length || 0) - 1}} {{selectedGroups.length === 2 ? 'other' : 'others'}})
                          </span>
                      </mat-select-trigger>
                      <mat-option *ngFor="let group of allGroups" [value]="group" >{{group.name}}</mat-option>
                  </mat-select>
                  <div *ngIf="userClientForm.controls['groups'].touched && userClientForm.controls['groups'].invalid">
                      <mat-error *ngIf="userClientForm.controls['groups'].errors?.['required']">This field is required</mat-error>
                  </div>
              </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-10 col-xs-10 input-ad-box" >
              <mat-checkbox  formControlName="isClientContact" name="isClientContact" [(ngModel)]="isClientContact" >Is Point of Contact</mat-checkbox>
          </div> 
            <div class="col-md-1 col-sm-10 col-xs-10 input-ad-box">
              <button type="submit" class="btn btn-dark align-middle"  [disabled]="this.userClientForm.invalid">Save</button>
            </div>
           
          <hr class="mt-2">

          </div>
        </form>


        <p class="h5 pb-2">Clients and Groups</p>
        
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Clients</th>
              <th scope="col">Groups</th>
              <th scope="col">Point of Contact</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let client of usersClients; index as i">
              <th scope="row">{{i+1}}</th>
              <td><p>{{client.clientName}} ({{client.clientId}})</p>
                <!-- <div *ngIf="client.isClientContactActive" style="font-weight: 700; ">
                  <mat-icon style="float: left;">contacts</mat-icon>
                  <p style="float: left;">
                     Point Of Contact</p>
                </div> -->
                </td>
              <td>
                <p *ngFor="let group of usersClientToGroupsMap[client.clientId]">{{group.groupName}}</p>
              </td>
              <td>
                <p *ngIf="client.isClientContactActive">Yes</p>
                <p *ngIf="!client.isClientContactActive">No</p>
              </td>
              <td class="align-top text-center">
                <button *ngIf="client.canDelete" type="button" class="btn-close mt-1" aria-label="Close" (click)="deleteClientFromUser(client.id)"></button>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
  </div>
</div>