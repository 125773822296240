<!--loader-->
<div *ngIf="isLoadingResults || (dataSource.loading$ | async)">
  <div class="loaderbox">
    <div class="darkbackground-box"></div>      
    <div class="spinnerbox-ic">
        <mat-spinner style="width: 55px;height:55px"></mat-spinner>
    </div>      
  </div>
</div>

<div class="jumbotron paral paralsec py-5">
  <div class="container card px-4 py-1">
    <div class="row my-2">
      <div class="col-md-4 col-sm-4 col-xs-4 hdbox-fm-grid d-flex align-items-center">
        Clients List
      </div>
      <div class="col-md-8 col-sm-8 col-xs-8 sorting-contentbox d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-dark m-2 d-flex align-items-center justify-content-center" [routerLink]="['/clients/new']">
          <mat-icon>add_circle_outline</mat-icon>
          <span class="m-2">Add Client</span>
        </button>
        <button type="button" class="btn btn-dark m-2 d-flex align-items-center justify-content-center" (click)="exportForm()">
          <mat-icon>download</mat-icon>
          <span class="m-2">Export CSV</span>
        </button>
        <mat-form-field appearance="outline" class="form-field mx-2" subscriptSizing="dynamic">
          <mat-label>Search by Name or Id</mat-label>
          <input matInput name="name" placeholder="Ex. Aonline" #input>
          <button *ngIf="input.value.length > 0" mat-icon-button aria-label="Clear" matSuffix (click)="onClearSearch($event)">
            <mat-icon>close</mat-icon>
          </button>
          <mat-icon *ngIf="input.value.length == 0" matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    
    <div class="mt-2">
      <form [formGroup]="filterForm" >
        <mat-form-field appearance="outline" class="form-field m-0 p-0">
          <mat-label>Filter by Region</mat-label>
          <mat-select formControlName="region" name="region" [(ngModel)]="selectedRegion" (selectionChange)="onRegionChanged()">
            <mat-option *ngIf="isLoadingCountries" class="is-loading">Loading...</mat-option>
            <mat-option *ngFor="let region of allRegions" [value]="region">
                {{region | uppercase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width form-field" appearance='outline'>
          <mat-label>Filter by Country</mat-label>
          <input matInput placeholder="Select Country" [(ngModel)]="selectedCountry" [matAutocomplete]="auto"
              formControlName="country" name="country" placeholder="Country code">
          <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onFilterChanged()"
            [displayWith]="displayCountryWith">
            <mat-option *ngIf="isLoadingCountries" class="is-loading">Loading...</mat-option>
            <ng-container *ngIf="!isLoadingCountries">
              <mat-option *ngFor="let country of filteredCountries" [value]="country">
                <span>{{country.code | uppercase}}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-field m-0 p-0">
          <mat-label>Client type</mat-label>
          <mat-select formControlName="type" name="type" (selectionChange)="onFilterChanged()">
              <mat-option *ngFor="let type of optionClientTypes" [value]="type.value">
                  {{type.text}}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-field m-0 p-0">
          <mat-label>Client's industry</mat-label>
          <mat-select formControlName="industry" name="industry" [(ngModel)]="selectedIndustry" (selectionChange)="onFilterChanged()">
              <mat-option *ngFor="let industry of allIndustries" [value]="industry">
                  {{industry.title}}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-field m-0 p-0">
          <mat-label>Is Deleted</mat-label>
          <mat-select formControlName="isDeleted" name="isDeleted" [(ngModel)]="selectedIsDeleted" (selectionChange)="onFilterChanged()">
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
        <button href="#" class="btn btn-sm" (click)="onClearFilters()">Clear filters</button>
      </form>
    
    </div>
    
    <div class="mat-elevation-z8 data-row-columm mb-3" >
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows matSort matSortActive="receivedParams.sortBy" matSortDirection="asc" matSortDisableClear>
    
        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th class="mat-column-id" mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td class="mat-column-id" mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let row"> {{row.name}}</td>
        </ng-container>
    
        <!-- Client ID Column -->
        <ng-container matColumnDef="clientId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Client ID </th>
          <td mat-cell *matCellDef="let row"> {{row.clientId}} </td>
        </ng-container>
    
        <!-- Email Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
          <td mat-cell *matCellDef="let row"> {{ getClientTypeName(row.type) }} </td>
        </ng-container>

        <!-- Region Column -->
        <ng-container matColumnDef="region">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Region </th>
          <td mat-cell *matCellDef="let row"> {{row.regionName | uppercase}} </td>
        </ng-container>
    
        <!-- Country Column -->
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
          <td mat-cell *matCellDef="let row"> {{row.countryCode | uppercase}} </td>
        </ng-container>

        <ng-container matColumnDef="isDeleted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 
            <mat-icon>delete</mat-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.isActive" class="text-success">●</span>
            <span *ngIf="!row.isActive" class="text-danger">●</span>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="actions">
          <th class="mat-column-actions" mat-header-cell *matHeaderCellDef> Actions </th>
          <td class="mat-column-actions" mat-cell *matCellDef="let row">
            <div class="btn-group btn-group-sm" role="group" aria-label="Actions">
              <button *ngIf="row.isActive == true" class="btn btn-secondary btn-dark d-flex align-items-center" [id]="row.id" [routerLink]="[row.id, 'edit']" matTooltip="Edit"><mat-icon>edit</mat-icon></button>
              <div *ngIf="row.isActive == true" class="alert alert-dark m-0 px-0"></div>
              <button *ngIf="row.isActive == true" class="btn btn-secondary btn-danger d-flex align-items-center" (click)="deleteClient(row)" matTooltip="Delete"><mat-icon>delete</mat-icon></button>
            </div>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button aria-label="expand row" (click)="toggleExpandClicked($event, row)">
              <mat-icon *ngIf="expandedElement !== row">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="expandedElement === row">keyboard_arrow_up</mat-icon>
            </button>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row" [attr.colspan]="displayWithExpand.length">
            <div class="row-detail-inner-container"
                [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
              <div class="d-flex justify-content-center align-items-center mat-spinner-container">
                <img *ngIf="row.imageUrl" src="{{row.imageUrl}}" alt="..." class="rounded-circle row-detail-image m-2" (load)="$any($event.target).classList.add('image-loaded')">
                <mat-spinner *ngIf="row.imageUrl" class="mat-spinner-small spinner-dark"></mat-spinner>
              </div>
              <div *ngIf="!row.imageUrl" class="rounded-circle row-detail-image m-2 d-flex align-items-center justify-content-center">{{row.initials}}</div>
                          
              <div class="row w-100 m-2">
                <div class="col-md-6">
                  <label *ngIf="row.industryView" class="">Industry: &nbsp;</label>{{row.industryView}}<br *ngIf="row.industry">
                </div>
                <div class="col-md-6">
                  <label *ngIf="row.spSiteUrl">Sharepoint Collaboration: &nbsp;</label><a *ngIf="row.spSiteUrl" href="{{row.spSiteUrl}}" target="_blank">Open Sharepoint Site</a>
                  <div class="col-md-12">
                    <mat-spinner *ngIf="row.isLoadingDetails" style="width: 16px;height:16px;" class="spinner-dark"></mat-spinner>
                  </div>
                </div>
                <div class="col-md-12">
                  <label *ngIf="row.defaultClientToolsStr || row.subgroupsToolsStr"><b>Access On Apps/Tools -</b></label><br *ngIf="row.defaultClientToolsStr || row.subgroupsToolsStr">
                  <label *ngIf="row.defaultClientToolsStr">Through default group: &nbsp;</label>{{row.defaultClientToolsStr}}<br *ngIf="row.defaultClientToolsStr">
                  <label *ngIf="row.subgroupsToolsStr">Through Subgroups: &nbsp;</label>{{row.subgroupsToolsStr}}<br *ngIf="row.subgroupsToolsStr">
                </div>
              </div>
            </div>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayWithExpand"></tr>
        <tr mat-row *matRowDef="let row; columns: displayWithExpand;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === row"
        (click)="expandedElement = expandedElement === row ? null : row"></tr>
        
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filters"</td>
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    
      <mat-paginator [length]="dataSource.maxCount$ | async" [pageSize]="10" [pageSizeOptions]="[10, 50, 100, 500]" aria-label="Select page of dataSource"></mat-paginator>
    </div>
  </div>
</div>
