import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { OPTION_CLIENT_TYPES } from 'src/models/Constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, distinctUntilChanged, debounceTime, tap, switchMap, finalize } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss'],
})
export class CreateClientComponent implements OnInit {

  optionClientTypes: any[] = OPTION_CLIENT_TYPES;

  loggedInUser : LoggedInUser;
  minLengthTerm = 2;
  isLoading: boolean = false;

  createForm = new FormGroup({
    country: new FormControl('', [Validators.required, Validators.email]),
    name: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    industry: new FormControl('', [Validators.required]),
    tools: new FormControl(''),
    spUrl: new FormControl('')
  });

  claimsContactForm = new FormGroup({
    aspfname: new FormControl({value: '', disabled: true}, [Validators.required],),
    asplname: new FormControl({value: '', disabled: true}, [Validators.required]),
    aspemail: new FormControl('', [Validators.required, Validators.email]),
    assfname: new FormControl({value: '', disabled: true}, [Validators.required]),
    asslname: new FormControl({value: '', disabled: true}, [Validators.required]),
    assemail: new FormControl('', [Validators.required, Validators.email]),
    odsci: new FormControl('', [Validators.required]),
  });
  
  claimsToolExists:boolean =false;

  file: File | null = null; // Variable to store file
  fileErr: string = "";

  // For Country selection
  filteredCountries: any;
  isLoadingCountries = false;
  selectedCountry: any = null;

  // For existing client navigation
  clientExists: boolean = false;
  canEditExistingClient: boolean = false;
  existingClientId: number = 0;

  //For Industries selection
  allIndustries: any = [];
  selectedIndustry: any = null;

  // For Tools Selection
  allTools: any;
  selectedTools: any = [];
  noTools = false;
  isCollabToolSelected = false;
  isClaimsToolSelected = false;
  isRealEstateToolSelected = false;
  isRequestQuoteToolSelected = false;

  //Permissions
  pCreateSpSite: boolean = false;

  //For Claim Contact
  selectedAspFName:string;
  selectedAspLName:string;
  selectedAspEmail:string;
  selectedAssFName:string;
  selectedAssLName:string;
  selectedAssEmail:string;
  primaryPreLoadedValues:boolean;
  secondaryPreLoadedValues:boolean;

  aoncontact:any ;
  insurercontact :any;
  constructor(private appService: AppService,private http: HttpClient, private authGuard: AuthGuard, private _snackBar: MatSnackBar, private location: Location,private router: Router) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.isLoading = false;

    this.appService.getAdminById(this.loggedInUser.id!).subscribe((response:any) => {
      this.pCreateSpSite = response.pCreateSpSite;
    });
    
    this.appService.searchCountries(10, 0, '', 'asc').subscribe((response:any) => {
      this.filteredCountries = response['data'];
    });

    this.appService.getIndustries(this.loggedInUser).subscribe((response) => {
      this.allIndustries = response;
    });
  }

  ngAfterViewInit() {
    this.isLoading = false;
    this.createForm.controls['country'].valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(250),
        tap(() => {
          this.filteredCountries = [];
          this.isLoadingCountries = true;
        }),
        switchMap(value => this.appService.searchCountries(10, 0, value as string, 'asc')
          .pipe(
            finalize(() => {
              this.isLoadingCountries = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        if (response['data'] == undefined) {
          this.filteredCountries = [];
        } else {
          this.filteredCountries = response['data'];
        }
      });

    this.createForm.controls['name'].valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        tap(() => {
            this.resetClientExists(null);
            this.findExistingClient(this.createForm.controls['name'].value);
        })
      )
      .subscribe();

    this.createForm.controls['tools'].valueChanges
    .subscribe((response: any) => {
      this.isCollabToolSelected = (response.filter((tool: { toolCode: string; }) => tool.toolCode == "app-collaboration")).length > 0;
      this.isRealEstateToolSelected = (response.filter((tool: { toolCode: string; }) => tool.toolCode == "app-re-collab")).length > 0;
      this.isRequestQuoteToolSelected = (response.filter((tool: { toolCode: string; }) => tool.toolCode == "app-re-request-quote")).length > 0;
      this.isClaimsToolSelected = (response.filter((tool: { toolCode: string; }) => tool.toolCode == "app-claims")).length > 0;

     
        if(this.isCollabToolSelected == true){
          this.allTools.forEach((tool: { disabled: boolean; toolCode: string; }) => {
            if(tool.toolCode == "app-re-collab" || tool.toolCode == "app-re-request-quote"  ){   
              tool.disabled = true;  
              
            }else{
              tool.disabled = false;              
            }
          });
        }
        else if(this.isRequestQuoteToolSelected || this.isRealEstateToolSelected){
          this.createForm.controls["spUrl"].setValidators(Validators.required);
          this.createForm.controls["spUrl"].updateValueAndValidity();
          this.allTools.forEach((tool: { disabled: boolean; toolCode: string; }) => {
          if(tool.toolCode != "app-collaboration" ){
            tool.disabled = false;
           }else{
            tool.disabled = true;
          }
          });
        }
        else {
          this.createForm.controls["spUrl"].clearValidators();
          this.createForm.controls["spUrl"].updateValueAndValidity();
          this.allTools.forEach((tool: { disabled: boolean; toolCode: string; }) => {
            if(tool.toolCode == "app-collaboration" || tool.toolCode == "app-re-collab" || tool.toolCode == "app-re-request-quote" ){
              tool.disabled = false;
            }
          });
        }
    });
    this.claimsContactForm.controls['aspemail'].valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() =>  {        
            this.claimsContactForm.controls['aspemail'].setErrors(this.claimsContactForm.controls['aspemail'].errors);
            if(this.primaryPreLoadedValues)
            {
              this.selectedAspFName= "";
              this.selectedAspLName = "";
            }
            if(this.claimsContactForm.controls['aspemail'].value != "" && !this.claimsContactForm.controls['aspemail'].invalid){
              this.findPrimaryUserDetails(this.claimsContactForm.controls['aspemail'].value);
            }
        
       })).subscribe();

       this.claimsContactForm.controls['assemail'].valueChanges
       .pipe(
         debounceTime(300),
         distinctUntilChanged(),
         tap(() =>  {
         this.claimsContactForm.controls['assemail'].setErrors(this.claimsContactForm.controls['assemail'].errors);
            if(this.secondaryPreLoadedValues)
            {
              this.selectedAssFName= "";
              this.selectedAssLName = "";
            }
            if(this.claimsContactForm.controls['assemail'].value != "" && !this.claimsContactForm.controls['assemail'].invalid){
              this.findSecondaryUserDetails(this.claimsContactForm.controls['assemail'].value);
            }
          })).subscribe();
  }

  findPrimaryUserDetails(email:string | null)
  {
    if(email != null){
    this.appService.getOktaDetailsByEmail(email).subscribe(
      (results) => {
        this.isLoading = true; 
       if(results.aonnetId !=null)
        {            
          this.selectedAspFName= results.firstName;
          this.selectedAspLName = results.lastName;
          this.primaryPreLoadedValues = true;
          this.isLoading = false; 
        }
        else
          {
            this.claimsContactForm.controls['aspemail'].setErrors({ invalid: 'User Not Found in Okta' });
          }
      });
    }
      this.isLoading = false; 
  }
  findSecondaryUserDetails(email:string | null)
  {
    if(email != null){
      this.appService.getOktaDetailsByEmail(email).subscribe(
        (results) => {
         if(results.aonnetId !=null)
          {            
            this.selectedAssFName= results.firstName;
            this.selectedAssLName = results.lastName;
            this.secondaryPreLoadedValues = true;
          }
          else
            {
              this.claimsContactForm.controls['assemail'].setErrors({ invalid: 'User Not Found in Okta' });
            }
        });
    }
  }
  onFileChange(event: any) {
    this.file = null;
    this.fileErr = "";
    if(event.target.files[0] == null){
      return;
    }
    this.file = event.target.files[0];
    
    let type: string = this.file?.type!;
    if(type !== 'image/png' && type !== 'image/jpeg'){
      this.fileErr = "Invalid file!"
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target?.result as string;
      image.onload = () => {
        const {
          height,
          width
        } = image;
        if (height > 44) {
          this.fileErr = "Height must not exceed 44px.";
          return false;
        }else if(width > 165){
          this.fileErr = "Width must not exceed 165px.";
          return false;
        }
        return true;
      };
    };
  }

  public onCountrySelected() {
    this.noTools = false;
    this.selectedCountry = this.selectedCountry;
    this.createForm.controls['country'].setErrors(null);
    this.appService.searchTools(1000, 0, '', this.selectedCountry.id).subscribe((response:any) => {
      if(response['data'].length == 0){
        this.noTools = true;
      }
      this.allTools = response['data'];
      if(!this.pCreateSpSite){
        this.allTools.forEach((tool: { disabled: boolean; toolCode: string; }) => {
          if(tool.toolCode != "app-collaboration"){
            tool.disabled = false;
          }else{
            tool.disabled = true;
          }
        });
      }
    });
  }

  displayCountryWith(value: any) {
    if(value){
      return `${value!.code}`;
    }
    return '';
  }

  findExistingClient(name: string | null){
    if(name != null){
      
      this.appService.checkIfClientExists(encodeURIComponent(name)).subscribe((response) => {    
        this.resetClientExists(response);
      },(e) => {
        console.log('findExistingClient', e.error);
      });
    }
  }

  resetClientExists(response: any){
    if(response != null){
      this.clientExists = response.exists;
      this.canEditExistingClient = response.canEdit;
      this.existingClientId = response.id;
      if(this.clientExists == true){
        this.createForm.controls['name'].setErrors({'exists': true});
      }else{
        this.createForm.controls['name'].setErrors(null);
      }
    }else{
      this.clientExists = false;
      this.canEditExistingClient = false;
      this.existingClientId = 0;
      this.createForm.controls['name'].setErrors(null);
    }
    
  }

  onSubmit(): void {
    if ((this.isClaimsToolSelected) &&(this.claimsContactForm.controls['aspfname'].value ==null ||
      this.claimsContactForm.controls['asplname'].value ==null  ||
      this.claimsContactForm.controls['asslname'].value ==null  ||
      this.claimsContactForm.controls['assfname'].value ==null  ) 
      ){

      (<HTMLInputElement>document.getElementById("myModal")).style.display = "block"; 
        this.showSnackbar("Please enter a valid Aon SPOC email");
      return;
    }
    if (this.createForm.invalid) {
      return;
    }
    if(this.isClaimsToolSelected && this.claimsContactForm.invalid)
      {        
        (<HTMLInputElement>document.getElementById("myModal")).style.display = "block";
        this.showSnackbar("Please Fill the Aon SPOC form");
        return;          
      }
    window.scrollTo(0, 0);
    const formData = new FormData();
    formData.append('CountryId', this.selectedCountry.id);
    formData.append('Name', this.createForm.controls['name'].value!);
    formData.append('SpSiteUrl', this.createForm.controls['spUrl'].value!);
    formData.append('Type', this.createForm.controls['type'].value!);
    formData.append('IndustryId', this.selectedIndustry.id);
    formData.append('AccessOn', this.selectedTools.map((tool:any) => tool.id).join(","));
    
    if(this.file)
      formData.append('Image', this.file, this.file?.name);
    if(this.claimsContactForm.controls['odsci'].value!)
      {
    formData.append('OdsClientIdsCsv', this.claimsContactForm.controls['odsci'].value!);    
    formData.append('ASPFName', this.claimsContactForm.controls['aspfname'].value!);
    formData.append('ASPLName', this.claimsContactForm.controls['asplname'].value!);
    formData.append('ASPEmail', this.claimsContactForm.controls['aspemail'].value!);
    formData.append('ASSFName', this.claimsContactForm.controls['assfname'].value!);
    formData.append('ASSLName', this.claimsContactForm.controls['asslname'].value!);
    formData.append('ASSEmail', this.claimsContactForm.controls['assemail'].value!);  
      }
    this.createClient(formData);
    
  }

  
  onClaimContactFormSubmit(): void {
    if ((this.isClaimsToolSelected) &&(this.claimsContactForm.controls['aspfname'].value ==null ||
      this.claimsContactForm.controls['asplname'].value ==null  ||
      this.claimsContactForm.controls['asslname'].value ==null  ||
      this.claimsContactForm.controls['assfname'].value ==null  ) 
      ){

      (<HTMLInputElement>document.getElementById("myModal")).style.display = "block"; 
        this.showSnackbar("Please enter a valid Aon SPOC email");
      return;
    }
    if (this.claimsContactForm.invalid) {
      return;
    }
    
    this.claimsToolExists=true;
    (<HTMLInputElement>document.getElementById("myModal")).style.display = "none";
  }
  createClient(formData: FormData) {
    this.isLoading = true; 
    this.appService.addClient(formData)
      .subscribe((data) => {
        this.isLoading = false;
        this.resetForm();
        this.showSnackbar("Client Added Successfully");
        this.router.navigate(["/clients"]);
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
      }); 
  }

  resetForm(){   
    this.createForm.reset();
    this.selectedCountry = null;
    this.selectedTools = [];
    this.file = null;
    this.noTools = false;
    this.isCollabToolSelected = false;
    this.isRealEstateToolSelected = false;
    this.isRequestQuoteToolSelected = false;
    this.isClaimsToolSelected = false;
    //(<HTMLInputElement>document.getElementById("myModal")).style.display = "none";
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  onCancel() {
    this.location.back();
  }
  openForm() {
    (<HTMLInputElement>document.getElementById("myModal")).style.display = "block";
  }
  
  closeForm() {
    (<HTMLInputElement>document.getElementById("myModal")).style.display = "none";
  }
}
