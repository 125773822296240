import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { OPTION_CLIENT_TYPES } from 'src/models/Constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, distinctUntilChanged, debounceTime, tap, switchMap, finalize, catchError } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss'],
})
export class CreateClientComponent implements OnInit {

  optionClientTypes: any[] = OPTION_CLIENT_TYPES;

  loggedInUser : LoggedInUser;
  minLengthTerm = 2;
  isLoading: boolean = false;

  createForm = new FormGroup({
    country: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    industry: new FormControl('', [Validators.required]),
    tools: new FormControl(''),
    spUrl: new FormControl(''),
    odsClientIds: new FormControl(''),
    primaryclaimspocemail: new FormControl(''),
    secondaryclaimspocemail: new FormControl('')
  });
  
  claimsToolExists:boolean =false;

  file: File | null = null; // Variable to store file
  fileErr: string = "";

  // For Country selection
  filteredCountries: any;
  isLoadingCountries = false;
  selectedCountry: any = null;

  // For existing client navigation
  clientExists: boolean = false;
  canEditExistingClient: boolean = false;
  existingClientId: number = 0;

  //For Industries selection
  allIndustries: any = [];
  selectedIndustry: any = null;

  // For Tools Selection
  allTools: any;
  selectedTools: any = [];
  noTools = false;
  isCollabToolSelected = false;
  isClaimsToolSelected = false;
  isRealEstateToolSelected = false;
  isRequestQuoteToolSelected = false;

  //Permissions
  pCreateSpSite: boolean = false;

  //For Claim Contact
  selectedPrimaryClaimSpocFName:string;
  selectedPrimaryClaimSpocLName:string;
  selectedSecondaryClaimSpocFName:string;
  selectedSecondaryClaimSpocLName:string;

  constructor(private appService: AppService,private http: HttpClient, private authGuard: AuthGuard, private _snackBar: MatSnackBar, private location: Location,private router: Router) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.isLoading = false;

    this.appService.getAdminById(this.loggedInUser.id!).subscribe((response:any) => {
      this.pCreateSpSite = response.pCreateSpSite;
    });
    
    this.appService.searchCountries(10, 0, '', 'asc').subscribe((response:any) => {
      this.filteredCountries = response['data'];
    });

    this.appService.getIndustries(this.loggedInUser).subscribe((response) => {
      this.allIndustries = response;
    });
  }

  ngAfterViewInit() {
    this.isLoading = false;
    this.createForm.controls['country'].valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(250),
        tap(() => {
          this.filteredCountries = [];
          this.isLoadingCountries = true;
        }),
        switchMap(value => this.appService.searchCountries(10, 0, value as string, 'asc')
          .pipe(
            finalize(() => {
              this.isLoadingCountries = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        if (response['data'] == undefined) {
          this.filteredCountries = [];
        } else {
          this.filteredCountries = response['data'];
        }
      });

    this.createForm.controls['name'].valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        tap(() => {
            this.resetClientExists(null);
            this.findExistingClient(this.createForm.controls['name'].value);
        })
      )
      .subscribe();

    this.createForm.controls['tools'].valueChanges
      .subscribe((response: any) => {

        this.isCollabToolSelected = (response.filter((tool: { toolCode: string; }) => tool.toolCode == "app-collaboration")).length > 0;
        this.isRealEstateToolSelected = (response.filter((tool: { toolCode: string; }) => tool.toolCode == "app-re-collab")).length > 0;
        this.isRequestQuoteToolSelected = (response.filter((tool: { toolCode: string; }) => tool.toolCode == "app-re-request-quote")).length > 0;
        this.isClaimsToolSelected = (response.filter((tool: { toolCode: string; }) => tool.toolCode == "app-claims")).length > 0;

        this.allTools.forEach((tool: { disabled: boolean; toolCode: string; }) => {
          tool.disabled = false;
        });

        let toDisable = [];
        if(this.isCollabToolSelected == true){
          toDisable = this.allTools.filter((tool: { disabled: boolean; toolCode: string; }) => tool.toolCode == "app-re-collab" || tool.toolCode == "app-re-request-quote");
        } else if(this.isRequestQuoteToolSelected || this.isRealEstateToolSelected){
          toDisable = this.allTools.filter((tool: { disabled: boolean; toolCode: string; }) => tool.toolCode == "app-collaboration");
          this.createForm.controls["spUrl"].setValidators(Validators.required);
          this.createForm.controls["spUrl"].updateValueAndValidity();
        } else {
          this.createForm.controls["spUrl"].clearValidators();
          this.createForm.controls["spUrl"].updateValueAndValidity();
        }

        toDisable.forEach((tool: { disabled: boolean; toolCode: string; }) => {
          tool.disabled = true;
        });

        if(this.isClaimsToolSelected){
          this.createForm.controls["odsClientIds"].setValidators(Validators.required);
          this.createForm.controls["odsClientIds"].updateValueAndValidity();
          this.createForm.controls["primaryclaimspocemail"].setValidators([Validators.required, Validators.email]);
          this.createForm.controls["primaryclaimspocemail"].updateValueAndValidity();
          this.createForm.controls["secondaryclaimspocemail"].setValidators([Validators.required, Validators.email]);
          this.createForm.controls["secondaryclaimspocemail"].updateValueAndValidity();
        }else{
          this.createForm.controls["odsClientIds"].clearValidators();
          this.createForm.controls["odsClientIds"].updateValueAndValidity();
          this.createForm.controls["primaryclaimspocemail"].clearValidators();
          this.createForm.controls["primaryclaimspocemail"].updateValueAndValidity();
          this.createForm.controls["secondaryclaimspocemail"].clearValidators();
          this.createForm.controls["secondaryclaimspocemail"].updateValueAndValidity();
        }
    });

    this.createForm.controls['primaryclaimspocemail'].valueChanges
      .pipe(
        filter(res => {
          this.createForm.controls['primaryclaimspocemail'].setErrors(this.createForm.controls['primaryclaimspocemail'].errors);
          return res !== null && res.length >= this.minLengthTerm && !this.createForm.controls['primaryclaimspocemail'].invalid
        }),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => {
          this.isLoading = true;
          this.selectedPrimaryClaimSpocFName= "";
          this.selectedPrimaryClaimSpocLName = "";
        }),
        switchMap(value => this.appService.getOktaDetailsByEmail(value!)
          .pipe(
            catchError(error => {
              this.isLoading = false;
              this.createForm.controls['primaryclaimspocemail'].setErrors({ 'employeenotfound': true });
              return "";
            }),
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        this.isLoading = false;
        if(response.aonnetId !=null){            
          this.selectedPrimaryClaimSpocFName = response.firstName;
          this.selectedPrimaryClaimSpocLName = response.lastName;
        } else {
          this.createForm.controls['primaryclaimspocemail'].setErrors({ 'employeenotfound': true });
        }
      });

    this.createForm.controls['secondaryclaimspocemail'].valueChanges
      .pipe(
        filter(res => {
          this.createForm.controls['secondaryclaimspocemail'].setErrors(this.createForm.controls['secondaryclaimspocemail'].errors);
          return res !== null && res.length >= this.minLengthTerm && !this.createForm.controls['secondaryclaimspocemail'].invalid
        }),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => {
          this.isLoading = true;
          this.selectedSecondaryClaimSpocFName= "";
          this.selectedSecondaryClaimSpocLName = "";
        }),
        switchMap(value => this.appService.getOktaDetailsByEmail(value!)
          .pipe(
            catchError(error => {
              this.isLoading = false;
              this.createForm.controls['secondaryclaimspocemail'].setErrors({ 'employeenotfound': true });
              return "";
            }),
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        this.isLoading = false;
        if(response.aonnetId !=null){            
          this.selectedSecondaryClaimSpocFName = response.firstName;
          this.selectedSecondaryClaimSpocLName = response.lastName;
        } else {
          this.createForm.controls['secondaryclaimspocemail'].setErrors({ 'employeenotfound': true });
        }
      });
  }

  onFileChange(event: any) {
    this.file = null;
    this.fileErr = "";
    if(event.target.files[0] == null){
      return;
    }
    this.file = event.target.files[0];
    
    let type: string = this.file?.type!;
    if(type !== 'image/png' && type !== 'image/jpeg'){
      this.fileErr = "Invalid file!"
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target?.result as string;
      image.onload = () => {
        const {
          height,
          width
        } = image;
        if (height > 44) {
          this.fileErr = "Height must not exceed 44px.";
          return false;
        }else if(width > 165){
          this.fileErr = "Width must not exceed 165px.";
          return false;
        }
        return true;
      };
    };
  }

  public onCountrySelected() {
    this.noTools = false;
    this.selectedCountry = this.selectedCountry;
    this.createForm.controls['country'].setErrors(null);
    this.appService.searchTools(1000, 0, '', this.selectedCountry.id).subscribe((response:any) => {
      if(response['data'].length == 0){
        this.noTools = true;
      }
      this.allTools = response['data'];
      if(!this.pCreateSpSite){
        this.allTools.forEach((tool: { disabled: boolean; toolCode: string; }) => {
          if(tool.toolCode != "app-collaboration"){
            tool.disabled = false;
          }else{
            tool.disabled = true;
          }
        });
      }
    });
  }

  displayCountryWith(value: any) {
    if(value){
      return `${value!.code}`;
    }
    return '';
  }

  findExistingClient(name: string | null){
    if(name != null){
      
      this.appService.checkIfClientExists(encodeURIComponent(name)).subscribe((response) => {    
        this.resetClientExists(response);
      },(e) => {
        console.log('findExistingClient', e.error);
      });
    }
  }

  resetClientExists(response: any){
    if(response != null){
      this.clientExists = response.exists;
      this.canEditExistingClient = response.canEdit;
      this.existingClientId = response.id;
      if(this.clientExists == true){
        this.createForm.controls['name'].setErrors({'exists': true});
      }else{
        this.createForm.controls['name'].setErrors(null);
      }
    }else{
      this.clientExists = false;
      this.canEditExistingClient = false;
      this.existingClientId = 0;
      this.createForm.controls['name'].setErrors(null);
    }
    
  }

  onSubmit(): void {
    if (this.createForm.invalid) {
      return;
    }

    if(this.isClaimsToolSelected && this.createForm.invalid)
      {        
        (<HTMLInputElement>document.getElementById("myModal")).style.display = "block";
        this.showSnackbar("Please Fill the Aon SPOC form");
        return;          
      }
    window.scrollTo(0, 0);
    const formData = new FormData();
    formData.append('CountryId', this.selectedCountry.id);
    formData.append('Name', this.createForm.controls['name'].value!);
    formData.append('SpSiteUrl', this.createForm.controls['spUrl'].value!);
    formData.append('Type', this.createForm.controls['type'].value!);
    formData.append('IndustryId', this.selectedIndustry.id);
    formData.append('AccessOn', this.selectedTools.map((tool:any) => tool.id).join(","));
    formData.append('OdsClientIdsCsv', this.createForm.controls['odsClientIds'].value!); 
    formData.append('ASPFName', this.selectedPrimaryClaimSpocFName);
    formData.append('ASPLName', this.selectedPrimaryClaimSpocLName);
    formData.append('ASPEmail', this.createForm.controls['primaryclaimspocemail'].value!);
    formData.append('ASSFName', this.selectedSecondaryClaimSpocFName);
    formData.append('ASSLName', this.selectedSecondaryClaimSpocLName);
    formData.append('ASSEmail', this.createForm.controls['secondaryclaimspocemail'].value!); 
    
    if(this.file)
      formData.append('Image', this.file, this.file?.name);

    this.createClient(formData);
  }

  
  // onClaimContactFormSubmit(): void {
  //   if ((this.isClaimsToolSelected) &&(this.createForm.controls['aspfname'].value ==null ||
  //     this.createForm.controls['asplname'].value ==null  ||
  //     this.createForm.controls['asslname'].value ==null  ||
  //     this.createForm.controls['assfname'].value ==null  ) 
  //     ){

  //     (<HTMLInputElement>document.getElementById("myModal")).style.display = "block"; 
  //       this.showSnackbar("Please enter a valid Aon SPOC email");
  //     return;
  //   }
  //   if (this.createForm.invalid) {
  //     return;
  //   }
    
  //   this.claimsToolExists=true;
  //   (<HTMLInputElement>document.getElementById("myModal")).style.display = "none";
  // }

  createClient(formData: FormData) {
    this.isLoading = true; 
    this.appService.addClient(formData)
      .subscribe((data) => {
        this.isLoading = false;
        this.resetForm();
        this.showSnackbar("Client Added Successfully");
        this.router.navigate(["/clients"]);
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
      }); 
  }

  resetForm(){   
    this.createForm.reset();
    this.selectedCountry = null;
    this.selectedTools = [];
    this.file = null;
    this.noTools = false;
    this.isCollabToolSelected = false;
    this.isRealEstateToolSelected = false;
    this.isRequestQuoteToolSelected = false;
    this.isClaimsToolSelected = false;
    //(<HTMLInputElement>document.getElementById("myModal")).style.display = "none";
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  onCancel() {
    this.location.back();
  }

  openForm() {
    (<HTMLInputElement>document.getElementById("myModal")).style.display = "block";
  }
  
  closeForm() {
    (<HTMLInputElement>document.getElementById("myModal")).style.display = "none";
  }
}
