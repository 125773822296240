import { Component,OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { FormControl } from '@angular/forms';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
@Component({
  selector: 'app-assign-tools',
  templateUrl: './assign-tools.component.html',
  styleUrls: ['./assign-tools.component.scss']
})
export class AssignToolsComponent implements OnInit{

  loggedInUser : LoggedInUser;

  constructor(private appService: AppService, private authGuard: AuthGuard, private _snackBar: MatSnackBar) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  apps = new FormControl('');  
  tools = new FormControl('');
  toolsList:any=[];
  appList: any = [];
  selectedTools: any = [];
  countries:any=[];
  countryList:any=[];
  country:number;
  
  message: string | null = null;

  ngOnInit(): void {
    this.hideloader();  
    this.appService.getCountries(this.loggedInUser).subscribe((response) => {
      this.countryList = response;
    });
    this.appService.getApps(this.loggedInUser).subscribe((response) => {
      this.appList = response;
    });
  }

  onCountryChange(event: any) {
    this.appService.getCountryTools(this.loggedInUser, event.target.value).subscribe((response) => {
      var selectedToolCodes = response.map((tool: { code: any; }) => tool.code);
      this.selectedTools = this.appList.filter((tool: { code: any; }) => selectedToolCodes.includes(tool.code));
    });
  }

  onSubmit()
  {
    this.showloader(); 
    var body=[];
    var b1;
    for(var i=0;i<this.selectedTools.length;i++)
    {
      b1= {
        "countryId":this.country,
        "toolId":this.selectedTools[i].id
        };
      body.push(b1);
    }
    console.log(this.selectedTools,body);

    this.addTools(this.country,body);
   
  }

  addTools(id:number,body:any)
  {
  this.appService.mapTools(id, body).subscribe(
    (data) => {
      //this.message = 'Tools assigned successfully';
      this._snackBar.open("Tools assigned successfully!", undefined, {
        duration: 2000,
        verticalPosition: 'top',
        horizontalPosition: 'end'
      });
      console.log(data);
      this.hideloader();  
     },
    (error) => {
      //this.message ='An Error occured while Creating Subgroup, please try again after sometime';  
      this._snackBar.open("An Error occured while Creating Subgroup, please try again after sometime.", undefined, {
        duration: 2000,
        verticalPosition: 'top',
        horizontalPosition: 'end'
      });
      this.hideloader();         
    }
  ); 
  this.country=0;
  this.selectedTools=[];

  }

  
  hideloader() {
    (document.getElementById('loading') as HTMLInputElement).style.display = 'none' ;
  }
  showloader() {
    (document.getElementById('loading') as HTMLInputElement).style.display = 'block' ;
  }
}
