import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap} from 'rxjs/operators';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-site',
  templateUrl: './site-create.component.html',
  styleUrls: ['./site-create.component.scss'],
})
export class CreateSiteComponent implements OnInit, AfterViewInit {

  loggedInUser : LoggedInUser;
  minLengthTerm = 2;
  isLoading: boolean = false;
  registerForm = new FormGroup({
    client: new FormControl('', [Validators.required]),
    name: new FormControl(''),
    address: new FormControl('', [Validators.required]),
  });

  // For Client selection
  filteredClients: any;
  isLoadingClients = false;
  selectedClient: any = "";

  // For site
  existingSite: any;
  

  constructor(private appService: AppService, private authGuard: AuthGuard, private http: HttpClient, private _snackBar: MatSnackBar, private location: Location,private router: Router) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.registerForm.controls['client'].valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(250),
        tap(() => {
          this.filteredClients = [];
          this.isLoadingClients = true;
        }),
        switchMap(value => this.appService.searchClients(10, 0, value as string)
          .pipe(
            finalize(() => {
              this.isLoadingClients = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        if (response['data'] == undefined) {
          this.filteredClients = [];
        } else {
          this.filteredClients = response['data'];
          console.log(this.filteredClients)
        }
      });

    this.registerForm.controls['name'].valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
            this.existingSite = null;
            this.registerForm.controls['name'].setErrors(this.registerForm.controls['name'].errors);
            if(this.registerForm.controls['name'].value != "" && !this.registerForm.controls['name'].invalid){
              this.findExistingSite(this.registerForm.controls['name'].value);
            }
        })
      )
      .subscribe();
  }

  displayClientWith(value: any) {
    return value?.name;
  }

  async findExistingSite(name: string | null){
    if(name != null){
      await this.appService.getSiteByName(this.selectedClient.id, name).subscribe((response) => {    
        this.existingSite = response;
        this.registerForm.controls['name'].setErrors({ 'exists': true});
      });
    }
  }

  onSubmit(): void {
    if (this.registerForm.invalid) {
      return;
    }
    // continue work here
    window.scrollTo(0, 0);
    const formData = new FormData();
    formData.append('Name', this.registerForm.controls['name'].value!);
    formData.append('Address', this.registerForm.controls['address'].value!);
    formData.append('CreatedByUserId', `${this.loggedInUser?.id}`);
    formData.append('ModifiedByUserId', `${this.loggedInUser?.id}`);
    formData.append('ClientId', this.selectedClient.id);
    formData.append('IsActive', `true`);
    
    this.createSite(formData);
  }

  createSite(formData: FormData){
    this.isLoading = true;
    this.appService.addSite(formData)
      .subscribe((data) => {
        this.isLoading = false;
        this.resetForm();
        this.showSnackbar("Site Added Successfully");

        this.router.navigate(["/sites"]);
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
      });
  }

  resetForm(){
    this.registerForm.reset();
    this.selectedClient = "";

    this.registerForm.controls['client'].setErrors(null);
    this.registerForm.controls['name'].setErrors(null);
    this.registerForm.controls['address'].setErrors(null);
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  onCancel() {
    this.location.back();
  }

}

