import {AfterViewInit, Component, ViewChild,OnInit, inject, ElementRef} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {forkJoin, fromEvent, merge, Observable, of as observableOf} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import { ClientDataSource } from './client-data-source';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { OPTION_CLIENT_TYPES } from 'src/models/Constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-report',
  templateUrl: './client-report.component.html',
  styleUrls: ['./client-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClientReportComponent implements OnInit, AfterViewInit {

  optionClientTypes: any[] = OPTION_CLIENT_TYPES;

  loggedInUser : LoggedInUser;
  minLengthTerm = 1;

  //Route Params
  gotParam: boolean = false;
  readonly routeParams = ['sortBy', 'sort', 'pageIndex', 'pageSize', 'searchText', 'region', 'country', 'type', 'industry', 'isDeleted'];
  receivedParams : any = {
    sortBy: 'name',
    sort : 'asc',
    pageIndex: 0,
    pageSize: 50,
    searchText: '',
    region: '',
    country: '',
    type: '',
    industry: '',
    isDeleted: ''
  }


  dataSource: ClientDataSource;
  displayedColumns: string[] = ['id', 'name', 'clientId', 'type', 'region', 'country', 'isDeleted', 'actions'];
  displayWithExpand = [...this.displayedColumns, 'expand'];
  message: string | null = null;
  _data: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  filterForm = new FormGroup({
    region: new FormControl(''),
    country: new FormControl(''),
    type: new FormControl(''),
    industry: new FormControl(''),
    isDeleted: new FormControl('')
  });

  // For Region selection
  allRegions: any = [];
  selectedRegion: any = "";

  // For Country selection
  allCountries: any = [];
  regionCountries: any = [];
  filteredCountries: any;
  isLoadingCountries = false;
  selectedCountry: any = "";

  //For Industries selection
  allIndustries: any = [];
  selectedIndustry: any = null;

  //For IsDeleted selection
  selectedIsDeleted: any = null;

  constructor(private router: Router, private route: ActivatedRoute, private location: Location, private appService: AppService, private authGuard: AuthGuard, private _snackBar: MatSnackBar) {   
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.dataSource = new ClientDataSource(this.appService);
  }

  body:any;
  expandedElement:any | null;

  ngAfterViewInit() {
    setTimeout(() => {
      this.isLoadingResults = true;
      forkJoin([
        this.appService.getIndustries(this.loggedInUser),
        this.appService.searchCountries(999, 0, '', 'asc', true)
      ]).subscribe(
        (results) => {
          this.isLoadingResults = false;
          this.allIndustries = results[0];
          this.allCountries = results[1].data;
          this.regionCountries = results[1].data;
          this.allRegions = this.getRegionsFromCountries(this.allCountries);
          //Check and refresh page for received params
          this.gotParam = false;
          this.route.queryParams
            .subscribe(params => {
              this.routeParams.forEach(param => {
                if(params[param]){
                  this.gotParam = true;
                  this.receivedParams[param] = params[param];
                }
              });

              this.sort.sort(({ id: this.receivedParams.sortBy, start: this.receivedParams.sort}) as MatSortable);
              if(this.gotParam){
                this.setPageFiltersFromParams();
              }else{
                this.dataSource.loadClients(true);
              }

              this.subscribeEvents();
            }
          );
        },
        (error) => {
          this.isLoadingResults = false;
          this.showSnackbar(error.message);
        }
      );
    });
  }

  subscribeEvents(){
    // server-side search
    fromEvent(this.input.nativeElement,'keyup')
    .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
            this.paginator.pageIndex = 0;
            this.loadClientsPage();
        })
    )
    .subscribe();
    
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        
    merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadClientsPage())
        )
        .subscribe();

    //Listen to country selection changes
    this.filterForm.controls['country'].valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(250),
        tap(value => {
          this.filteredCountries = this.regionCountries.filter((country: { countryCode: string; }) => country.countryCode.includes(value?.toLowerCase() ?? ''));
        })
      ).subscribe();
  }

  getRegionsFromCountries(countries: any[]): String[]{
    let regions: String[] = [];
    countries.forEach(country => {
      if(regions.indexOf(country.region.code) === -1) {
        regions.push(country.region.code);
      }
    });
    return regions.sort();
  }

  getClientTypeName(typeCode: any){
    var arr = this.optionClientTypes.filter(obj => {
      return obj.value === typeCode;
    });
    if(arr.length > 0){
      return arr[0].text;
    }
    return "";
  }

  setPageFiltersFromParams(){
    setTimeout(() => {
      this.sort.active = this.receivedParams['sortBy'];
      this.sort.direction = this.receivedParams['sort'];
      this.paginator.pageIndex = this.receivedParams['pageIndex'];
      this.paginator.pageSize = this.receivedParams['pageSize'];
      this.input.nativeElement.value = this.receivedParams['searchText'];
      if(this.receivedParams['region']){
        if(!this.allRegions.includes(this.receivedParams['region'].toUpperCase())){
          this.selectedRegion == '';
          this.regionCountries = this.allCountries;
        }else{
          this.selectedRegion = this.receivedParams['region'].toUpperCase();
          this.regionCountries = this.allCountries.filter((country: { region: string }) => country.region === this.receivedParams['region']);
        }
      }
      if(this.receivedParams['country']){
        this.filteredCountries = this.regionCountries;
        this.selectedCountry = this.regionCountries.filter((country: { countryCode: string }) => country.countryCode === this.receivedParams['country'])[0];
      }
      if(this.receivedParams['type']){
        this.filterForm.controls['type'].setValue(this.receivedParams['type']);
      }
      if(this.receivedParams['industry']){
        this.selectedIndustry = this.allIndustries.filter((industry: { code: string; }) => industry.code === this.receivedParams['industry'])[0];
      }
      if(this.receivedParams['isDeleted'] != null){
        this.selectedIsDeleted = this.receivedParams['isDeleted'] == 'true' ? true : this.receivedParams['isDeleted'] == 'false' ? false : null;
      }
      this.loadClientsPage();
    });
    
  }

  loadClientsPage(): void {
    this.dataSource.loadClients(
    true,
    this.paginator.pageIndex,
    this.paginator.pageSize,
    this.input.nativeElement.value,
    this.selectedRegion ? this.selectedRegion.toLowerCase() : "",
    this.selectedCountry ? this.selectedCountry.countryCode : "",
    this.selectedIndustry ? this.selectedIndustry.code : "",
    this.filterForm.controls['type'].value ? this.filterForm.controls['type'].value : "",
    this.selectedIsDeleted,
    this.sort.active,
    this.sort.direction);

    this.updateUrlState();
  }

  updateUrlState(): void {
    let pathParts: [string] = [`/clients?pageIndex=${this.paginator.pageIndex}&pageSize=${this.paginator.pageSize}&sortBy=${this.sort.active}&sort=${this.sort.direction}`];
    if(this.input.nativeElement.value){
      pathParts.push(`searchText=${this.input.nativeElement.value}`)
    }
    if(this.selectedRegion){
      pathParts.push(`region=${this.selectedRegion.toLowerCase()}`)
    }
    if(this.selectedCountry){
      pathParts.push(`country=${this.selectedCountry.countryCode}`)
    }
    if(this.selectedIndustry){
      pathParts.push(`industry=${this.selectedIndustry.code}`)
    }
    if(this.filterForm.controls['type'].value){
      pathParts.push(`type=${this.filterForm.controls['type'].value}`)
    }
    if(this.selectedIsDeleted != null){
      pathParts.push(`isDeleted=${this.selectedIsDeleted}`)
    }
    this.location.replaceState(pathParts.join('&'));
  }
  
  deleteClient(row: any){
    this.isLoadingResults = true;
    if(confirm(`Confirm deletion of client - ${row.name} (${row.clientId}) ?`)) {
      this.appService.deleteClient(row.id).subscribe((response) => {
        this.isLoadingResults = false;
        row.isActive = !row.isActive;
        this.showSnackbar(response.message);
      },(e) => {
        this.isLoadingResults = false;
        this.showSnackbar(e.message);
      });
    }else{
      this.isLoadingResults = false;
    }
  }

  toggleExpandClicked(event: any, row: any){
    this.expandedElement = this.expandedElement === row ? null : row
    event.stopPropagation();

    if(!row.imageUrl && row.imagePath){
      row.imageUrl = `${environment.appUrl}${row.imagePath}`;
    }
    
    if(row.initials == null && row.name != null){
      row.initials = row.name?.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
    }

    if(row.industry){
      row.industryView = `${this.allIndustries.filter((industry: { code: any; }) => industry.code.localeCompare(row.industry, undefined, { sensitivity: 'accent' }) === 0)[0].title} (${row.industry})`
    }

    if(row.clientDetails == null){
      row.isLoadingDetails = true;
    }
    this.appService.getClientById(row.id).subscribe((response) => {
      row.defaultClientToolsStr = response.tools
        .filter((tool: { isDefault: boolean; }) => tool.isDefault === true)
        .map((tool: { title: any; }) => {
          return tool.title;
        }).join(', ');
      row.subgroupsToolsStr = response.tools
        .filter((tool: { isDefault: boolean; }) => tool.isDefault === false)
        .map((tool: { title: any; }) => {
          return tool.title;
        }).join(', ');
      if(row.spSiteUrl){
        row.spSiteUrl = `${environment.aonlineUrl}${environment.getAccessTargetUrl}${response.spSiteUrl}`;
      }
      row.isLoadingDetails = false;
    },(e) => {
      row.isLoadingDetails = false;
    });    
  }

  onRegionChanged() {
    this.selectedCountry = '';
    this.regionCountries = this.allCountries.filter((country: { region: string }) => country.region === this.selectedRegion);
    this.loadClientsPage();
  }

  onFilterChanged() {
    this.loadClientsPage();
  }

  displayCountryWith(value: any) {
    return value?.countryCode;
  }

  onClearFilters(){
    this.filterForm.reset();
    this.regionCountries = this.allCountries;
    this.paginator.pageIndex = 0;
    this.loadClientsPage();
  }

  onClearSearch($event: any){
    this.input.nativeElement.value = '';
    this.loadClientsPage();
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }  

  exportForm() {
    this.isLoadingResults = true; 
    this.appService.getClientsCsv(this.loggedInUser,
      this.input.nativeElement.value,
      this.selectedRegion ? this.selectedRegion : "",
      this.selectedCountry ? this.selectedCountry.countryCode : "",
      this.selectedIndustry ? this.selectedIndustry.code : "",
      this.filterForm.controls['type'].value ? this.filterForm.controls['type'].value : "",
      this.selectedIsDeleted,
      this.sort.active,
      'desc'
    ).subscribe(
      (data: string) => {
        this.showSnackbar('CSV exported Successfully');
        const a = document.createElement("a");
        a.href = "data:text/csv," + data;
        let filename = "Clients";
        a.setAttribute("download", filename + ".csv");
        document.body.appendChild(a);        a.click();
        document.body.removeChild(a);
        this.isLoadingResults = false;
      },
      (e: { message: string; }) => {
        this.showSnackbar("Error exporting report - " + e.message);
        this.isLoadingResults = false;         
      }
    );         
  }
  
}


