import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, catchError, finalize, Observable, of } from "rxjs";
import { LoggedInUser } from "src/models/LoggedInUser";
import { AppService } from "../app.service";

export class AdminDataSource implements DataSource<any> {
  private adminsSubject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private maxCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public maxCount$ = this.maxCountSubject.asObservable();

  constructor(private appService: AppService) {}

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.adminsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.adminsSubject.complete();
    this.loadingSubject.complete();
  }

  loadUsers(
    pageIndex = 0,
    pageSize = 10,
    filter = '',
    countryId = 0,
    sortDirection = 'asc'
  ) {
    this.loadingSubject.next(true);
    let offset = pageIndex * pageSize;
    this.appService
      .getAdmins(pageSize, offset, filter, countryId, sortDirection)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((result) => {
        console.log('admins', result);
        this.adminsSubject.next(result.data);
        this.maxCountSubject.next(result.maxCount);
      });
  }

  loadAdminUsersReport(
    pageIndex = 0,
    pageSize = 10,
    filter = '',
    countryCode = '',
    sortDirection = 'asc'
  ) {
    this.loadingSubject.next(true);
    let offset = pageIndex * pageSize;
    this.appService
      .getAdminsReport(pageSize, offset, filter, countryCode, sortDirection)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((result) => {
        this.adminsSubject.next(result.data);
        this.maxCountSubject.next(result.maxCount);
      });
  }
}