<div class="container-fluid">
  <div class="row justify-content-center min-vh-100">
    <div class="d-flex flex-column vh-100 px-0">
      <div class="row justify-content-center bg-purple">
        <header name="test" class="p-3 border-bottom">
          <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <div class="d-flex col-md-6 col-sm-6 col-xs-6">
              <a href="{{aonlineHomepageUrl}}" class="flex-column px-2">
                <span class="aonlogo">
                  <img src="/assets/images/aonLine-logo.png">
                </span>
              </a>
              <a href="/" class="d-flex align-items-center text-dark text-decoration-none logoname px-2 mb-1">
                User Management App
              </a>
            </div>
        
            <div class="col-md-6 col-sm-6 col-xs-6 box-n px-2">
              <div class="d-flex flex-row-reverse bd-highlight" *ngIf="loggedInUser">
                <div class="bd-highlight text-light bg-dark rounded-circle row-detail-image d-flex align-items-center justify-content-center" matTooltip="{{'Timezone UTC' + loggedInUser.timezoneHrsMin}}">{{initials}}</div>
                <div class="d-flex flex-column-reverse bd-highlight">
                  <div class="bd-highlight">{{loggedInUser.email}}</div>
                  <div class="bd-highlight">{{fullName}}</div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div class="justify-content-center flex-grow-1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<!--loader-->
<div *ngIf="authMessage">
  <div class="loaderbox">
      <div class="spinnerbox-ic">
          {{authMessage}}
      </div>      
  </div>
</div>

<!-- <router-outlet *ngIf="loggedInUser"></router-outlet> -->

<!-- <footer class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 copyright-txt">
      ©2023 Aon plc. All rights reserved.
    </div>
  </div>
</footer> -->
