import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { fromEvent, merge, Observable } from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import { ClientDataSource } from '../client-report/client-data-source';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { EditAdminComponent } from '../edit-admin/edit-admin.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports-client-user-count',
  templateUrl: './reports-client-user-count.component.html',
  styleUrls: ['./reports-client-user-count.component.scss']
})
export class ReportsClientUserCountComponent implements OnInit {


  loggedInUser : LoggedInUser;
  minLengthTerm = 1;

  dataSource: ClientDataSource;
  displayedColumns: string[] = ['name','clientId', 'allUsersCount','aonUsersCount', 'countryCode', 'spSite', 'inactiveUsers','acxOnly'];
  _data: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

   filterForm = new FormGroup({
    country: new FormControl('')
  });

  isLoadingResults = false;

  // For Country selection
  filteredCountries: any;
  isLoadingCountries = false;
  selectedCountry: any = "";
  expandedElement:any | null;
  allCountries: any[];

  constructor(private router: Router, private appService: AppService, private authGuard: AuthGuard, private _snackBar: MatSnackBar, public dialog: MatDialog) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }
  
  ngOnInit(): void {   
    
    this.dataSource = new ClientDataSource(this.appService);    
    this.dataSource.loadClientUsersCount(0);
    this.loadCountries();
  }

  ngAfterViewInit() {
   
    
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        
    merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadClientUsersPage())
        )
        .subscribe();

    
    this.filterForm.controls['country'].valueChanges
    .pipe(
      filter(res => {
        return res !== null && res.length >= this.minLengthTerm
      }),
      distinctUntilChanged(),
      debounceTime(250),
      tap(value => {
        this.filteredCountries = this.allCountries.filter((country: { code: string; }) => country.code.includes(value?.toLowerCase() ?? ''));
      })
    ).subscribe();

  }

  loadClientUsersPage(): void {
     this.dataSource.loadClientUsersCount(
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.selectedCountry ? this.selectedCountry.code : '',
      this.sort.direction);
      
  }

  loadCountries(){
    this.appService.getCountries(this.loggedInUser).subscribe((response: any) => {
      this.allCountries = response;
      console.log(this.allCountries);
    });
  }

  onCountrySelected() {
    this.selectedCountry = this.selectedCountry;
    this.loadClientUsersPage();
  }

  displayCountryWith(value: any) {
    return value?.code;
  }

  onClearFilters(){
    this.filterForm.reset();
    this.paginator.pageIndex = 0;
    this.loadClientUsersPage();
  }
   
  getCountriesCsv(row:any)
  {
    return row.adminCountries.map((ac:any) => ac.country.code ).join(', '); 
  }
  exportForm() {
    this.isLoadingResults = true; 
    this.appService.ClientUsersSearchCSV(
      this.selectedCountry ? this.selectedCountry.code : '',
      this.sort.direction
    ).subscribe(
      (data: string) => { 
        this.showSnackbar('CSV exported Successfully');
        const a = document.createElement("a");
        a.href = "data:text/csv," + data;
        let filename = "Users Count";
        a.setAttribute("download", filename + ".csv");
        document.body.appendChild(a);        a.click();
        document.body.removeChild(a);
        this.isLoadingResults = false;
      },
      (e: { message: string; }) => {
        this.showSnackbar("Error exporting report - " + e.message);
        this.isLoadingResults = false;         
      }
    );         
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

}

