import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthGuard } from './authguard.service';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
  constructor(public auth: AuthGuard) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        "Authorization": `${this.auth.getLoggedInUser().secret}`,
        "uid": `${this.auth.getLoggedInUser().id}`
      }
    });
    return next.handle(request).pipe(catchError(error => {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        var errorMessageObj = error.error;
        if(typeof(errorMessageObj) === 'string'){
          errorMessageObj = JSON.parse(errorMessageObj);
        }
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was:`, errorMessageObj);
        if (error.status === 401) {
          console.log("Clearing session - Error 401!")
          this.auth.clearSession();
        }
        try{
          if(errorMessageObj){
            return throwError(errorMessageObj);
          }
        }catch{
          //Do nothing
        }
        
      }
      // return an observable with a user-facing error message
      return throwError(
        { message : 'Something bad happened; please try again later.'});
    }));
  }
}