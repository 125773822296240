<!--loader-->
<div id="loading" *ngIf="isLoadingResults || (dataSource.loading$ | async)">
  <div class="loaderbox">
    <div class="darkbackground-box"></div>      
    <div class="spinnerbox-ic">
        <mat-spinner style="width: 55px;height:55px"></mat-spinner>
    </div>     
  </div> 
</div>

<!-- Parallax Section -->
<div class="jumbotron paral paralsec py-5">
  <div class="container card px-4 py-1">
    <div class="row my-2">
      <div class="col-md-4 col-sm-4 col-xs-4 hdbox-fm-grid d-flex align-items-center">
        Admins List
      </div>
      <div class="col-md-8 col-sm-8 col-xs-8 sorting-contentbox d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-dark m-2 d-flex align-items-center justify-content-center" [routerLink]="['/admins/new']">
          <mat-icon>add_circle_outline</mat-icon>
          <span class="m-2">Add new admin</span>
        </button>
      </div>
    </div>

    <div>
      <form [formGroup]="filterForm" >
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>Search by name</mat-label>
          <input matInput formControlName="name" name="name" placeholder="Ex. Gaurav" #input>
        </mat-form-field>
        <mat-form-field class="example-full-width form-field" appearance='outline'>
          <mat-label>Filter by Country</mat-label>
          <input matInput placeholder="Select Country" [(ngModel)]="selectedCountry" [matAutocomplete]="auto"
              formControlName="country" name="country" placeholder="Country code">
          <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onCountrySelected()"
            [displayWith]="displayCountryWith">
            <mat-option *ngIf="isLoadingCountries" class="is-loading">Loading...</mat-option>
            <ng-container *ngIf="!isLoadingCountries">
              <mat-option *ngFor="let country of filteredCountries" [value]="country">
                <span>{{country.code}}</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
        <button href="#" class="btn btn-sm" (click)="onClearFilters()">Clear filters</button>
      </form>
  
    </div>
  
    <div class="mat-elevation-z8 data-row-columm mb-3" >
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows matSort matSortActive="id" matSortDirection="asc" matSortDisableClear>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let row"> {{row.firstName}} {{row.lastName}} </td>
        </ng-container>
    
        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let row"> {{row.email}} </td>
        </ng-container>
  
        <!-- SuperAdmin Column -->
        <ng-container matColumnDef="super">
          <th mat-header-cell *matHeaderCellDef> SuperAdmin </th>
          <td mat-cell *matCellDef="let row"> {{row.roleId == 0 ? 'Yes' : 'No'}} </td>
        </ng-container>
  
        <!-- Sharepoint Site Column -->
        <ng-container matColumnDef="spsite">
          <th mat-header-cell *matHeaderCellDef> SP Access </th>
          <td mat-cell *matCellDef="let row"> {{row.pCreateSpSite ? 'Yes' : 'No'}} </td>
        </ng-container>
  
        <!-- IsActive Column -->
        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef> Enabled </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.isActive" class="text-success">●</span>
            <span *ngIf="!row.isActive" class="text-danger">●</span>
          </td>
        </ng-container>
  
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th class="mat-column-actions" mat-header-cell *matHeaderCellDef> Actions </th>
          <td class="mat-column-actions" mat-cell *matCellDef="let row">
            <button type="button" class="btn btn-dark btn-sm m-2" [routerLink]="[row.id, 'edit']">Edit</button>
            <button type="button" class="btn btn-danger btn-sm" *ngIf="row.isActive == true" (click)="toggleStatus(row)">Disable</button>
            <button type="button" class="btn btn-success btn-sm" *ngIf="row.isActive == false" (click)="toggleStatus(row)">Enable</button>
          </td>
        </ng-container>
        
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filters</td>
        </tr>
      </table>
    
      <mat-paginator [length]="dataSource.maxCount$ | async" [pageSize]="10" [pageSizeOptions]="[10, 50, 100, 500]" aria-label="Select page of dataSource"></mat-paginator>
    </div>
  
  
  </div>
</div>
