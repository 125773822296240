import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { filter, map, Observable } from 'rxjs';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public name$!: string;
  
  constructor(private route: ActivatedRoute, private appService: AppService, private authGuard: AuthGuard, private http: HttpClient) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  loggedInUser : LoggedInUser;
  isSuperAdmin:boolean;
  oupId: number;

  usersFilter: string = '';
  clientsFilter: string = '';

  ngOnInit(): void {
    if(this.loggedInUser && this.loggedInUser.id != 0){
      this.appService.getAdminById(this.loggedInUser.id!).subscribe((response:any) => { 
        this.isSuperAdmin = response.roleId == 0;
      }, e => {
        console.log('getAdminById error - ', e);
      }); 

      this.appService.getUserByEmail(this.loggedInUser.email!).subscribe((response:any) => { 
        this.oupId = response.id;
      }, e => {
        console.log('getUserByEmail error - ', e);
      }); 
    }

    this.route.queryParams
      .subscribe(params => {
        if(params){
          this.makeFilters(params);
        }
      }
    );
  }

  makeFilters(params: any){
    if(params['ClientId']){
      let clientId = params['ClientId'];
      this.usersFilter = `?client=${clientId}`;
      this.clientsFilter = `?searchText=${clientId}`
    }
  }
}
