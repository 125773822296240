
export const OPTION_COUNTRIES = [
    {text: 'Afghanistan', value: 'af'}, 
    {text: 'Åland Islands', value: 'ax'}, 
    {text: 'Albania', value: 'al'}, 
    {text: 'Algeria', value: 'dz'}, 
    {text: 'American Samoa', value: 'as'}, 
    {text: 'AndorrA', value: 'ad'}, 
    {text: 'Angola', value: 'ao'}, 
    {text: 'Anguilla', value: 'ai'}, 
    {text: 'Antarctica', value: 'aq'}, 
    {text: 'Antigua and Barbuda', value: 'ag'}, 
    {text: 'Argentina', value: 'ar'}, 
    {text: 'Armenia', value: 'am'}, 
    {text: 'Aruba', value: 'aw'}, 
    {text: 'Australia', value: 'au'}, 
    {text: 'Austria', value: 'at'}, 
    {text: 'Azerbaijan', value: 'az'}, 
    {text: 'Bahamas', value: 'bs'}, 
    {text: 'Bahrain', value: 'bh'}, 
    {text: 'Bangladesh', value: 'bd'}, 
    {text: 'Barbados', value: 'bb'}, 
    {text: 'Belarus', value: 'by'}, 
    {text: 'Belgium', value: 'be'}, 
    {text: 'Belize', value: 'bz'}, 
    {text: 'Benin', value: 'bj'}, 
    {text: 'Bermuda', value: 'bm'}, 
    {text: 'Bhutan', value: 'bt'}, 
    {text: 'Bolivia', value: 'bo'}, 
    {text: 'Bosnia and Herzegovina', value: 'ba'}, 
    {text: 'Botswana', value: 'bw'}, 
    {text: 'Bouvet Island', value: 'bv'}, 
    {text: 'Brazil', value: 'br'}, 
    {text: 'British Indian Ocean Territory', value: 'io'}, 
    {text: 'Brunei Darussalam', value: 'bn'}, 
    {text: 'Bulgaria', value: 'bg'}, 
    {text: 'Burkina Faso', value: 'bf'}, 
    {text: 'Burundi', value: 'bi'}, 
    {text: 'Cambodia', value: 'kh'}, 
    {text: 'Cameroon', value: 'cm'}, 
    {text: 'Canada', value: 'ca'}, 
    {text: 'Cape Verde', value: 'cv'}, 
    {text: 'Cayman Islands', value: 'ky'}, 
    {text: 'Central African Republic', value: 'cf'}, 
    {text: 'Chad', value: 'td'}, 
    {text: 'Chile', value: 'cl'}, 
    {text: 'China', value: 'cn'}, 
    {text: 'Christmas Island', value: 'cx'}, 
    {text: 'Cocos (Keeling) Islands', value: 'cc'}, 
    {text: 'Colombia', value: 'co'}, 
    {text: 'Comoros', value: 'km'}, 
    {text: 'Congo', value: 'cg'}, 
    {text: 'Congo, The Democratic Republic of the', value: 'cd'}, 
    {text: 'Cook Islands', value: 'ck'}, 
    {text: 'Costa Rica', value: 'cr'}, 
    {text: 'Cote D\'Ivoire', value: 'ci'}, 
    {text: 'Croatia', value: 'hr'}, 
    {text: 'Cuba', value: 'cu'}, 
    {text: 'Cyprus', value: 'cy'}, 
    {text: 'Czech Republic', value: 'cz'}, 
    {text: 'Denmark', value: 'dk'}, 
    {text: 'Djibouti', value: 'dj'}, 
    {text: 'Dominica', value: 'dm'}, 
    {text: 'Dominican Republic', value: 'do'}, 
    {text: 'Ecuador', value: 'ec'}, 
    {text: 'Egypt', value: 'eg'}, 
    {text: 'El Salvador', value: 'sv'}, 
    {text: 'Equatorial Guinea', value: 'gq'}, 
    {text: 'Eritrea', value: 'er'}, 
    {text: 'Estonia', value: 'ee'}, 
    {text: 'Ethiopia', value: 'et'}, 
    {text: 'Falkland Islands (Malvinas)', value: 'fk'}, 
    {text: 'Faroe Islands', value: 'fo'}, 
    {text: 'Fiji', value: 'fj'}, 
    {text: 'Finland', value: 'fi'}, 
    {text: 'France', value: 'fr'}, 
    {text: 'French Guiana', value: 'gf'}, 
    {text: 'French Polynesia', value: 'pf'}, 
    {text: 'French Southern Territories', value: 'tf'}, 
    {text: 'Gabon', value: 'ga'}, 
    {text: 'Gambia', value: 'gm'}, 
    {text: 'Georgia', value: 'ge'}, 
    {text: 'Germany', value: 'de'}, 
    {text: 'Ghana', value: 'gh'}, 
    {text: 'Gibraltar', value: 'gi'}, 
    {text: 'Greece', value: 'gr'}, 
    {text: 'Greenland', value: 'gl'}, 
    {text: 'Grenada', value: 'gd'}, 
    {text: 'Guadeloupe', value: 'gp'}, 
    {text: 'Guam', value: 'gu'}, 
    {text: 'Guatemala', value: 'gt'}, 
    {text: 'Guernsey', value: 'gg'}, 
    {text: 'Guinea', value: 'gn'}, 
    {text: 'Guinea-Bissau', value: 'gw'}, 
    {text: 'Guyana', value: 'gy'}, 
    {text: 'Haiti', value: 'ht'}, 
    {text: 'Heard Island and Mcdonald Islands', value: 'hm'}, 
    {text: 'Holy See (Vatican City State)', value: 'va'}, 
    {text: 'Honduras', value: 'hn'}, 
    {text: 'Hong Kong', value: 'hk'}, 
    {text: 'Hungary', value: 'hu'}, 
    {text: 'Iceland', value: 'is'}, 
    {text: 'India', value: 'in'}, 
    {text: 'Indonesia', value: 'id'}, 
    {text: 'Iran, Islamic Republic Of', value: 'ir'}, 
    {text: 'Iraq', value: 'iq'}, 
    {text: 'Ireland', value: 'ie'}, 
    {text: 'Isle of Man', value: 'im'}, 
    {text: 'Israel', value: 'il'}, 
    {text: 'Italy', value: 'it'}, 
    {text: 'Jamaica', value: 'jm'}, 
    {text: 'Japan', value: 'jp'}, 
    {text: 'Jersey', value: 'je'}, 
    {text: 'Jordan', value: 'jo'}, 
    {text: 'Kazakhstan', value: 'kz'}, 
    {text: 'Kenya', value: 'ke'}, 
    {text: 'Kiribati', value: 'ki'}, 
    {text: 'Korea, Democratic People\'S Republic of', value: 'kp'}, 
    {text: 'Korea, Republic of', value: 'kr'}, 
    {text: 'Kuwait', value: 'kw'}, 
    {text: 'Kyrgyzstan', value: 'kg'}, 
    {text: 'Lao People\'S Democratic Republic', value: 'la'}, 
    {text: 'Latvia', value: 'lv'}, 
    {text: 'Lebanon', value: 'lb'}, 
    {text: 'Lesotho', value: 'ls'}, 
    {text: 'Liberia', value: 'lr'}, 
    {text: 'Libyan Arab Jamahiriya', value: 'ly'}, 
    {text: 'Liechtenstein', value: 'li'}, 
    {text: 'Lithuania', value: 'lt'}, 
    {text: 'Luxembourg', value: 'lu'}, 
    {text: 'Macao', value: 'mo'}, 
    {text: 'Macedonia, The Former Yugoslav Republic of', value: 'mk'}, 
    {text: 'Madagascar', value: 'mg'}, 
    {text: 'Malawi', value: 'mw'}, 
    {text: 'Malaysia', value: 'my'}, 
    {text: 'Maldives', value: 'mv'}, 
    {text: 'Mali', value: 'ml'}, 
    {text: 'Malta', value: 'mt'}, 
    {text: 'Marshall Islands', value: 'mh'}, 
    {text: 'Martinique', value: 'mq'}, 
    {text: 'Mauritania', value: 'mr'}, 
    {text: 'Mauritius', value: 'mu'}, 
    {text: 'Mayotte', value: 'yt'}, 
    {text: 'Mexico', value: 'mx'}, 
    {text: 'Micronesia, Federated States of', value: 'fm'}, 
    {text: 'Moldova, Republic of', value: 'md'}, 
    {text: 'Monaco', value: 'mc'}, 
    {text: 'Mongolia', value: 'mn'}, 
    {text: 'Montserrat', value: 'ms'}, 
    {text: 'Morocco', value: 'ma'}, 
    {text: 'Mozambique', value: 'mz'}, 
    {text: 'Myanmar', value: 'mm'}, 
    {text: 'Namibia', value: 'na'}, 
    {text: 'Nauru', value: 'nr'}, 
    {text: 'Nepal', value: 'np'}, 
    {text: 'Netherlands', value: 'nl'}, 
    {text: 'Netherlands Antilles', value: 'an'}, 
    {text: 'New Caledonia', value: 'nc'}, 
    {text: 'New Zealand', value: 'nz'}, 
    {text: 'Nicaragua', value: 'ni'}, 
    {text: 'Niger', value: 'ne'}, 
    {text: 'Nigeria', value: 'ng'}, 
    {text: 'Niue', value: 'nu'}, 
    {text: 'Norfolk Island', value: 'nf'}, 
    {text: 'Northern Mariana Islands', value: 'mp'}, 
    {text: 'Norway', value: 'no'}, 
    {text: 'Oman', value: 'om'}, 
    {text: 'Pakistan', value: 'pk'}, 
    {text: 'Palau', value: 'pw'}, 
    {text: 'Palestinian Territory, Occupied', value: 'ps'}, 
    {text: 'Panama', value: 'pa'}, 
    {text: 'Papua New Guinea', value: 'pg'}, 
    {text: 'Paraguay', value: 'py'}, 
    {text: 'Peru', value: 'pe'}, 
    {text: 'Philippines', value: 'ph'}, 
    {text: 'Pitcairn', value: 'pn'}, 
    {text: 'Poland', value: 'pl'}, 
    {text: 'Portugal', value: 'pt'}, 
    {text: 'Puerto Rico', value: 'pr'}, 
    {text: 'Qatar', value: 'qa'}, 
    {text: 'Reunion', value: 're'}, 
    {text: 'Romania', value: 'ro'}, 
    {text: 'Russian Federation', value: 'ru'}, 
    {text: 'RWANda', value: 'rw'}, 
    {text: 'Saint Helena', value: 'sh'}, 
    {text: 'Saint Kitts and Nevis', value: 'kn'}, 
    {text: 'Saint Lucia', value: 'lc'}, 
    {text: 'Saint Pierre and Miquelon', value: 'pm'}, 
    {text: 'Saint Vincent and the Grenadines', value: 'vc'}, 
    {text: 'Samoa', value: 'ws'}, 
    {text: 'San Marino', value: 'sm'}, 
    {text: 'Sao Tome and Principe', value: 'st'}, 
    {text: 'Saudi Arabia', value: 'sa'}, 
    {text: 'Senegal', value: 'sn'}, 
    {text: 'Serbia and Montenegro', value: 'cs'}, 
    {text: 'Seychelles', value: 'sc'}, 
    {text: 'Sierra Leone', value: 'sl'}, 
    {text: 'Singapore', value: 'sg'}, 
    {text: 'Slovakia', value: 'sk'}, 
    {text: 'Slovenia', value: 'si'}, 
    {text: 'Solomon Islands', value: 'sb'}, 
    {text: 'Somalia', value: 'so'}, 
    {text: 'South Africa', value: 'za'}, 
    {text: 'South Georgia and the South Sandwich Islands', value: 'gs'}, 
    {text: 'Spain', value: 'es'}, 
    {text: 'Sri Lanka', value: 'lk'}, 
    {text: 'Sudan', value: 'sd'}, 
    {text: 'Suritext', value: 'sr'}, 
    {text: 'Svalbard and Jan Mayen', value: 'sj'}, 
    {text: 'Swaziland', value: 'sz'}, 
    {text: 'Sweden', value: 'se'}, 
    {text: 'Switzerland', value: 'ch'}, 
    {text: 'Syrian Arab Republic', value: 'sy'}, 
    {text: 'Taiwan, Province of China', value: 'tw'}, 
    {text: 'Tajikistan', value: 'tj'}, 
    {text: 'Tanzania, United Republic of', value: 'tz'}, 
    {text: 'Thailand', value: 'th'}, 
    {text: 'Timor-Leste', value: 'tl'}, 
    {text: 'Togo', value: 'tg'}, 
    {text: 'Tokelau', value: 'tk'}, 
    {text: 'Tonga', value: 'to'}, 
    {text: 'Trinidad and Tobago', value: 'tt'}, 
    {text: 'Tunisia', value: 'tn'}, 
    {text: 'Turkey', value: 'tr'}, 
    {text: 'Turkmenistan', value: 'tm'}, 
    {text: 'Turks and Caicos Islands', value: 'tc'}, 
    {text: 'Tuvalu', value: 'tv'}, 
    {text: 'Uganda', value: 'ug'}, 
    {text: 'Ukraine', value: 'ua'}, 
    {text: 'United Arab Emirates', value: 'ae'}, 
    {text: 'United Kingdom', value: 'uk'}, 
    {text: 'United States', value: 'us'}, 
    {text: 'United States Minor Outlying Islands', value: 'um'}, 
    {text: 'Uruguay', value: 'uy'}, 
    {text: 'Uzbekistan', value: 'uz'}, 
    {text: 'Vanuatu', value: 'vu'}, 
    {text: 'Venezuela', value: 've'}, 
    {text: 'Viet Nam', value: 'vn'}, 
    {text: 'Virgin Islands, British', value: 'vg'}, 
    {text: 'Virgin Islands, U.S.', value: 'vi'}, 
    {text: 'Wallis and Futuna', value: 'wf'}, 
    {text: 'Western Sahara', value: 'eh'}, 
    {text: 'Yemen', value: 'ye'}, 
    {text: 'Zambia', value: 'zm'}, 
    {text: 'Zimbabwe', value: 'zw'}
];

 export const OPTION_USER_TYPES = [    
//     { value:'a', text: 'Administrator' },
//     { value:'o', text: 'Competitor' },
//     { value:'x', text: 'Contractor' },
//     { value:'c', text: 'Customer' },
//     { value:'e', text: 'Employee' },
//     { value:'i', text: 'Insurer' },
    { id:0, value:'sa', text: 'Super Admin',status:'0' },
    { id:7, value:'x', text: 'Competitor',status:'1' },
    { id:5, value:'o', text: 'Contractor' ,status:'1' },
    { id:3, value:'c', text: 'Customer',status:'1'  },
    { id:2, value:'e', text: 'Employee',status:'0'  },
    { id:1, value:'ea', text: 'Employee - Administrator',status:'0'  },        
    { id:8, value:'m', text: 'Evidence of Coverage' ,status:'0' },
    { id:9, value:'g', text: 'Generic - Customer',status:'0'  },
    { id:10, value:'gi', text: 'Generic - Insurer',status:'0'  },
    { id:4, value:'i', text: 'Insurer',status:'1'  },
    { id:6, value:'p', text: 'Prospect' ,status:'1' }

]


export const OPTION_SALUTATIONS = [
    { value:'Mr', text: 'Mr' },
    { value:'Mrs', text: 'Mrs' },
    { value:'Ms', text: 'Ms' },
    { value:'Miss', text: 'Miss' },
]

export const OPTION_LANGUAGES = [
    {text: 'Abkhazian', value: 'ab'}, 
    {text: 'Afar', value: 'aa'}, 
    {text: 'Afrikaans', value: 'af'}, 
    {text: 'Akan', value: 'ak'}, 
    {text: 'Albanian', value: 'sq'}, 
    {text: 'Amharic', value: 'am'}, 
    {text: 'Arabic', value: 'ar'}, 
    {text: 'Aragonese', value: 'an'}, 
    {text: 'Armenian', value: 'hy'}, 
    {text: 'Assamese', value: 'as'}, 
    {text: 'Avaric', value: 'av'}, 
    {text: 'Avestan', value: 'ae'}, 
    {text: 'Aymara', value: 'ay'}, 
    {text: 'Azerbaijani', value: 'az'}, 
    {text: 'Bambara', value: 'bm'}, 
    {text: 'Bashkir', value: 'ba'}, 
    {text: 'Basque', value: 'eu'}, 
    {text: 'Belarusian', value: 'be'}, 
    {text: 'Bengali', value: 'bn'}, 
    {text: 'Bihari languages', value: 'bh'}, 
    {text: 'Bislama', value: 'bi'}, 
    {text: 'Bokmål, Norwegian; Norwegian Bokmål', value: 'nb'}, 
    {text: 'Bosnian', value: 'bs'}, 
    {text: 'Breton', value: 'br'}, 
    {text: 'Bulgarian', value: 'bg'}, 
    {text: 'Burmese', value: 'my'}, 
    {text: 'Catalan; Valencian', value: 'ca'}, 
    {text: 'Central Khmer', value: 'km'}, 
    {text: 'Chamorro', value: 'ch'}, 
    {text: 'Chechen', value: 'ce'}, 
    {text: 'Chichewa; Chewa; Nyanja', value: 'ny'}, 
    {text: 'Chinese', value: 'zh'}, 
    {text: 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic', value: 'cu'}, 
    {text: 'Chuvash', value: 'cv'}, 
    {text: 'Cornish', value: 'kw'}, 
    {text: 'Corsican', value: 'co'}, 
    {text: 'Cree', value: 'cr'}, 
    {text: 'Croatian', value: 'hr'}, 
    {text: 'Czech', value: 'cs'}, 
    {text: 'Danish', value: 'da'}, 
    {text: 'Divehi; Dhivehi; Maldivian', value: 'dv'}, 
    {text: 'Dutch; Flemish', value: 'nl'}, 
    {text: 'Dzongkha', value: 'dz'}, 
    {text: 'English', value: 'en'}, 
    {text: 'Esperanto', value: 'eo'}, 
    {text: 'Estonian', value: 'et'}, 
    {text: 'Ewe', value: 'ee'}, 
    {text: 'Faroese', value: 'fo'}, 
    {text: 'Fijian', value: 'fj'}, 
    {text: 'Finnish', value: 'fi'}, 
    {text: 'French', value: 'fr'}, 
    {text: 'Fulah', value: 'ff'}, 
    {text: 'Gaelic; Scottish Gaelic', value: 'gd'}, 
    {text: 'Galician', value: 'gl'}, 
    {text: 'Ganda', value: 'lg'}, 
    {text: 'Georgian', value: 'ka'}, 
    {text: 'German', value: 'de'}, 
    {text: 'Greek, Modern (1453-)', value: 'el'}, 
    {text: 'Guarani', value: 'gn'}, 
    {text: 'Gujarati', value: 'gu'}, 
    {text: 'Haitian; Haitian Creole', value: 'ht'}, 
    {text: 'Hausa', value: 'ha'}, 
    {text: 'Hebrew', value: 'he'}, 
    {text: 'Herero', value: 'hz'}, 
    {text: 'Hindi', value: 'hi'}, 
    {text: 'Hiri Motu', value: 'ho'}, 
    {text: 'Hungarian', value: 'hu'}, 
    {text: 'Icelandic', value: 'is'}, 
    {text: 'Ido', value: 'io'}, 
    {text: 'Igbo', value: 'ig'}, 
    {text: 'Indonesian', value: 'id'}, 
    {text: 'Interlingua (International Auxiliary Language Association)', value: 'ia'}, 
    {text: 'Interlingue; Occidental', value: 'ie'}, 
    {text: 'Inuktitut', value: 'iu'}, 
    {text: 'Inupiaq', value: 'ik'}, 
    {text: 'Irish', value: 'ga'}, 
    {text: 'Italian', value: 'it'}, 
    {text: 'Japanese', value: 'ja'}, 
    {text: 'Javanese', value: 'jv'}, 
    {text: 'Kalaallisut; Greenlandic', value: 'kl'}, 
    {text: 'Kannada', value: 'kn'}, 
    {text: 'Kanuri', value: 'kr'}, 
    {text: 'Kashmiri', value: 'ks'}, 
    {text: 'Kazakh', value: 'kk'}, 
    {text: 'Kikuyu; Gikuyu', value: 'ki'}, 
    {text: 'Kinyarwanda', value: 'rw'}, 
    {text: 'Kirghiz; Kyrgyz', value: 'ky'}, 
    {text: 'Komi', value: 'kv'}, 
    {text: 'Kongo', value: 'kg'}, 
    {text: 'Korean', value: 'ko'}, 
    {text: 'Kuanyama; Kwanyama', value: 'kj'}, 
    {text: 'Kurdish', value: 'ku'}, 
    {text: 'Lao', value: 'lo'}, 
    {text: 'Latin', value: 'la'}, 
    {text: 'Latvian', value: 'lv'}, 
    {text: 'Limburgan; Limburger; Limburgish', value: 'li'}, 
    {text: 'Lingala', value: 'ln'}, 
    {text: 'Lithuanian', value: 'lt'}, 
    {text: 'Luba-Katanga', value: 'lu'}, 
    {text: 'Luxembourgish; Letzeburgesch', value: 'lb'}, 
    {text: 'Macedonian', value: 'mk'}, 
    {text: 'Malagasy', value: 'mg'}, 
    {text: 'Malay', value: 'ms'}, 
    {text: 'Malayalam', value: 'ml'}, 
    {text: 'Maltese', value: 'mt'}, 
    {text: 'Manx', value: 'gv'}, 
    {text: 'Maori', value: 'mi'}, 
    {text: 'Marathi', value: 'mr'}, 
    {text: 'Marshallese', value: 'mh'}, 
    {text: 'Mongolian', value: 'mn'}, 
    {text: 'Nauru', value: 'na'}, 
    {text: 'Navajo; Navaho', value: 'nv'}, 
    {text: 'Ndebele, North; North Ndebele', value: 'nd'}, 
    {text: 'Ndebele, South; South Ndebele', value: 'nr'}, 
    {text: 'Ndonga', value: 'ng'}, 
    {text: 'Nepali', value: 'ne'}, 
    {text: 'Northern Sami', value: 'se'}, 
    {text: 'Norwegian', value: 'no'}, 
    {text: 'Norwegian Nynorsk; Nynorsk, Norwegian', value: 'nn'}, 
    {text: 'Occitan (post 1500)', value: 'oc'}, 
    {text: 'Ojibwa', value: 'oj'}, 
    {text: 'Oriya', value: 'or'}, 
    {text: 'Oromo', value: 'om'}, 
    {text: 'Ossetian; Ossetic', value: 'os'}, 
    {text: 'Pali', value: 'pi'}, 
    {text: 'Panjabi; Punjabi', value: 'pa'}, 
    {text: 'Persian', value: 'fa'}, 
    {text: 'Polish', value: 'pl'}, 
    {text: 'Portuguese', value: 'pt'}, 
    {text: 'Pushto; Pashto', value: 'ps'}, 
    {text: 'Quechua', value: 'qu'}, 
    {text: 'Romanian; Moldavian; Moldovan', value: 'ro'}, 
    {text: 'Romansh', value: 'rm'}, 
    {text: 'Rundi', value: 'rn'}, 
    {text: 'Russian', value: 'ru'}, 
    {text: 'Samoan', value: 'sm'}, 
    {text: 'Sango', value: 'sg'}, 
    {text: 'Sanskrit', value: 'sa'}, 
    {text: 'Sardinian', value: 'sc'}, 
    {text: 'Serbian', value: 'sr'}, 
    {text: 'Shona', value: 'sn'}, 
    {text: 'Sichuan Yi; Nuosu', value: 'ii'}, 
    {text: 'Sindhi', value: 'sd'}, 
    {text: 'Sinhala; Sinhalese', value: 'si'}, 
    {text: 'Slovak', value: 'sk'}, 
    {text: 'Slovenian', value: 'sl'}, 
    {text: 'Somali', value: 'so'}, 
    {text: 'Sotho, Southern', value: 'st'}, 
    {text: 'Spanish; Castilian', value: 'es'}, 
    {text: 'Sundanese', value: 'su'}, 
    {text: 'Swahili', value: 'sw'}, 
    {text: 'Swati', value: 'ss'}, 
    {text: 'Swedish', value: 'sv'}, 
    {text: 'Tagalog', value: 'tl'}, 
    {text: 'Tahitian', value: 'ty'}, 
    {text: 'Tajik', value: 'tg'}, 
    {text: 'Tamil', value: 'ta'}, 
    {text: 'Tatar', value: 'tt'}, 
    {text: 'Telugu', value: 'te'}, 
    {text: 'Thai', value: 'th'}, 
    {text: 'Tibetan', value: 'bo'}, 
    {text: 'Tigrinya', value: 'ti'}, 
    {text: 'Tonga (Tonga Islands)', value: 'to'}, 
    {text: 'Tsonga', value: 'ts'}, 
    {text: 'Tswana', value: 'tn'}, 
    {text: 'Turkish', value: 'tr'}, 
    {text: 'Turkmen', value: 'tk'}, 
    {text: 'Twi', value: 'tw'}, 
    {text: 'Uighur; Uyghur', value: 'ug'}, 
    {text: 'Ukrainian', value: 'uk'}, 
    {text: 'Urdu', value: 'ur'}, 
    {text: 'Uzbek', value: 'uz'}, 
    {text: 'Venda', value: 've'}, 
    {text: 'Vietnamese', value: 'vi'}, 
    {text: 'Volapük', value: 'vo'}, 
    {text: 'Walloon', value: 'wa'}, 
    {text: 'Welsh', value: 'cy'}, 
    {text: 'Western Frisian', value: 'fy'}, 
    {text: 'Wolof', value: 'wo'}, 
    {text: 'Xhosa', value: 'xh'}, 
    {text: 'Yiddish', value: 'yi'}, 
    {text: 'Yoruba', value: 'yo'}, 
    {text: 'Zhuang; Chuang', value: 'za'}, 
    {text: 'Zulu', value: 'zu'}
]

export const OPTION_CLIENT_TYPES = [
    { value:'c', text: 'Client' },
    { value:'p', text: 'Prospect' },
    { value:'t', text: 'Demo/Test/Training' },
    { value:'i', text: 'Internal' },
]