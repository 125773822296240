import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, catchError, finalize, Observable, of } from "rxjs";
import { LoggedInUser } from "src/models/LoggedInUser";
import { AppService } from "../app.service";

export class ClientDataSource implements DataSource<any> {

    private clientsSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private maxCountSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public maxCount$ = this.maxCountSubject.asObservable();

    constructor(private appService: AppService) {}

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.clientsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.clientsSubject.complete();
        this.loadingSubject.complete();
    }

    loadClients(showAll = false, pageIndex = 0, pageSize = 10, filter = '', region = "", countryCode = "", industry = "", clientType = "", isDeleted : boolean | null = null, sortBy = 'name', sortDirection = 'asc') {

        this.loadingSubject.next(true);

        let offset = pageIndex * pageSize;
        this.appService.searchClients(pageSize, offset, filter, region, countryCode, industry, clientType, isDeleted, sortBy, sortDirection, showAll).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(result => {
            this.clientsSubject.next(result.data);
            this.maxCountSubject.next(result.maxCount);
        });
    }   
    
    loadClientUsersCount(pageIndex = 0, pageSize = 10, countryCode = "", sortDirection = 'asc') {

        this.loadingSubject.next(true);

        let offset = pageIndex * pageSize;
        this.appService.ClientUsersSearch(pageSize, offset, countryCode,  sortDirection).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(result => {
            this.clientsSubject.next(result.data);
            this.maxCountSubject.next(result.maxCount);
        });
    }  
}