import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CreateUserComponent } from './user-create/create-user.component';
import { CreateClientComponent } from './create-client/create-client.component';
import { AdminUserComponent } from './admin-user/admin-user.component';
import { UserReportComponent } from './user-list/user-list.component';
import { SiteListComponent } from './site-list/site-list.component';
import { EditSiteComponent } from './site-edit/site-edit.component';
import { ClientReportComponent } from './client-report/client-report.component';
import { EditUserComponent } from './user-edit/edit-user.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { HealthcheckComponent } from './healthcheck/healthcheck.component';
import { AssignToolsComponent } from './assign-tools/assign-tools.component';
import { ClientSubgroupComponent } from './client-subgroup/client-subgroup.component';
import { LazyusercreationComponent } from './lazyusercreation/lazyusercreation.component';
import { CSVuploadComponent } from './csvupload/csvupload.component';
import { CreateAdminComponent } from './create-admin/create-admin.component';
import { EditAdminComponent } from './edit-admin/edit-admin.component';
import { LogsComponent } from './logs/logs.component';
import { AuthGuard } from './auth/authguard.service';
import { LogoutComponent } from './logout/logout.component';
import { ViewUserComponent } from './user-view/view-user.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsSharepointTasksComponent } from './reports-sharepoint-tasks/reports-sharepoint-tasks.component';
import { ReportsCountryAdminsComponent } from './reports-country-admins/reports-country-admins.component';
import { UserAuditComponent } from './user-audit/user-audit.component';
import { ReportsClientUserCountComponent } from './reports-client-user-count/reports-client-user-count.component';
import { CreateSiteComponent } from './site-create/site-create.component';

const routes: Routes = [
  { path: 'healthcheck', component: HealthcheckComponent},
  { path: 'logout', component: LogoutComponent},
  { path: 'wps/portal/csanew', component: LazyusercreationComponent},
  // Protected
  { path: '', component: HomeComponent, canActivate:[AuthGuard]},
  { path: 'logs', component: LogsComponent, canActivate:[AuthGuard]},
  { path: 'client-subgroup', component: ClientSubgroupComponent, canActivate:[AuthGuard]},
  { path: 'tools', component: AssignToolsComponent, canActivate:[AuthGuard]},
  { path: 'edit-user', component: EditUserComponent, canActivate:[AuthGuard]},
  { path: 'clients', component: ClientReportComponent, canActivate:[AuthGuard]},
  { path: 'clients/new', component: CreateClientComponent, canActivate:[AuthGuard]},
  { path: 'clients/:id/edit', component: EditClientComponent, canActivate:[AuthGuard]},
  { path: 'sites', component: SiteListComponent, canActivate:[AuthGuard]},
  { path: 'sites/new', component: CreateSiteComponent, canActivate:[AuthGuard]},
  { path: 'sites/:id/edit', component: EditSiteComponent, canActivate:[AuthGuard]},
  { path: 'users', component: UserReportComponent, canActivate:[AuthGuard]},
  { path: 'users/new', component: CreateUserComponent, canActivate:[AuthGuard]},
  { path: 'users/:id', component: ViewUserComponent, canActivate:[AuthGuard]},
  { path: 'users/:id/edit', component: EditUserComponent, canActivate:[AuthGuard]},
  { path: 'users/:id/audit', component: UserAuditComponent, canActivate:[AuthGuard]},
  { path: 'admins', component: AdminUserComponent, canActivate:[AuthGuard]},
  { path: 'admins/new', component: CreateAdminComponent, canActivate:[AuthGuard]},
  { path: 'admins/:id/edit', component: EditAdminComponent, canActivate:[AuthGuard]},
  { path: 'csvupload', component: CSVuploadComponent, canActivate:[AuthGuard]},
  { path: 'reports', 
    component: ReportsComponent,
    children: [
      {
        path: "sharepoint-tasks",
        component: ReportsSharepointTasksComponent
      },
      {
        path: "country-admins",
        component: ReportsCountryAdminsComponent
      },
      {
        path: "client-user-count",
        component: ReportsClientUserCountComponent
      }
    ],
    canActivate:[AuthGuard]
  },
  { path: '**', component: HomeComponent, canActivate:[AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
