<!--loader-->
<div *ngIf="isLoading">
    <div class="loaderbox">
        <div class="darkbackground-box"></div>
        <div class="spinnerbox-ic">
            <mat-spinner style="width: 55px;height:55px"></mat-spinner>
        </div>
    </div>
</div>

<div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-3">
        <div class="row hdbox-fm my-2">
            <div class="col-md-12 col-sm-12 col-xs-12 hdbox-fm">
                Self User Creation Request
            </div>
            <span class="success-msg-txt">{{successMessage}}</span>
            <span class="error-msg-txt">{{errorMessage}}</span>
        </div>
    
        <div *ngIf="errorMessage == ''" class="my-3">
    
            <div class="row frm">
                <div class="col-md-2 col-sm-3 col-xs-3 txt-ad-box">UserName :</div>
                <div class="col-md-10 col-sm-10 col-xs-10 input-ad-box">
                    <input type="input" [(ngModel)]="uname" class="form-control input0-box" id='lastName' [disabled]="true">
                </div>
            </div>
    
            <div class="row frm">
                <div class="col-md-2 col-sm-3 col-xs-3 txt-ad-box">First Name :</div>
                <div class="col-md-10 col-sm-10 col-xs-10 input-ad-box">
                    <input type="input" [(ngModel)]="fname" class="form-control input0-box" id='lastName' [disabled]="true">
                </div>
            </div>
    
            <div class="row frm">
                <div class="col-md-2 col-sm-3 col-xs-3 txt-ad-box">Last Name :</div>
                <div class="col-md-10 col-sm-10 col-xs-10 input-ad-box">
                    <input type="input" [(ngModel)]="lname" class="form-control input0-box" id='lastName' [disabled]="true">
                </div>
            </div>
    
            <div class="row frm">
                <div class="col-md-2 col-sm-3 col-xs-3 txt-ad-box">Email :</div>
                <div class="col-md-10 col-sm-10 col-xs-10 input-ad-box">
                    <input type="input" [(ngModel)]="email" class="form-control input0-box" id='lastName' [disabled]="true">
                </div>
            </div>
    
            <div class="row frm">
                <div class="col-md-2 col-sm-3 col-xs-3 txt-ad-box">Client :</div>
                <div class="col-md-10 col-sm-10 col-xs-10 input-ad-box">
                    <input type="input" [(ngModel)]="client" class="form-control input0-box" id='lastName' [disabled]="true">
                </div>
            </div>
    
            <div class="row frm">
                <div class="col-md-2 col-sm-3 col-xs-3 txt-ad-box"></div>
                <div class="col-md-10 col-sm-10 col-xs-10 input-ad-box">
                    <a (click)="submitForm()" class="svn-btn">Submit</a>
                </div>
            </div>
        </div>
    </div>
</div>