import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { fromEvent, merge, Observable } from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import { AdminDataSource } from './admin-data-source';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { EditAdminComponent } from '../edit-admin/edit-admin.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.scss']
})

export class AdminUserComponent implements OnInit {

  loggedInUser : LoggedInUser;
  minLengthTerm = 1;

  dataSource: AdminDataSource;
  displayedColumns: string[] = ['name', 'email', 'super', 'spsite', 'isActive', 'actions'];
  _data: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  filterForm = new FormGroup({
    name: new FormControl(''),
    country: new FormControl('')
  });

  isLoadingResults = false;

  // For Country selection
  filteredCountries: any;
  isLoadingCountries = false;
  selectedCountry: any = "";

  allCountries: any[];

  constructor(private router: Router, private appService: AppService, private authGuard: AuthGuard, private _snackBar: MatSnackBar, public dialog: MatDialog) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }
  
  ngOnInit(): void {
    this.dataSource = new AdminDataSource(this.appService);
    this.dataSource.loadUsers(0);
    this.loadCountries();
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent(this.input.nativeElement,'keyup')
    .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
            this.paginator.pageIndex = 0;
            this.loadUsersPage();
        })
    )
    .subscribe();
    
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        
    merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadUsersPage())
        )
        .subscribe();

    //Listen to country selection changes
    this.filterForm.controls['country'].valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(250),
        tap(() => {
          this.filteredCountries = [];
          this.isLoadingCountries = true;
        }),
        switchMap(value => this.appService.searchCountries(10, 0, value as string, 'asc')
          .pipe(
            finalize(() => {
              this.isLoadingCountries = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        if (response['data'] == undefined) {
          this.filteredCountries = [];
        } else {
          this.filteredCountries = response['data'];
        }
      });
  }

  loadUsersPage(): void {
     this.dataSource.loadUsers(
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.input.nativeElement.value,
      this.selectedCountry ? this.selectedCountry.id : 0,
      this.sort.direction);
  }

  loadCountries(){
    this.appService.getCountries(this.loggedInUser).subscribe((response: any) => {
      this.allCountries = response;
    });
  }

  onCountrySelected() {
    this.selectedCountry = this.selectedCountry;
    this.loadUsersPage();
  }

  displayCountryWith(value: any) {
    return value?.code;
  }

  onClearFilters(){
    this.filterForm.reset();
    this.paginator.pageIndex = 0;
    this.loadUsersPage();
  }

  toggleStatus(row:any){
    let requestedIsActive = null;
    if(row.isActive == true){
      requestedIsActive = false;
    }else if(row.isActive == false){
      requestedIsActive = true;
    }

    if(requestedIsActive != null){
      this.isLoadingResults = true;
      this.appService.setAdminIsActive(row.email, requestedIsActive)
      .subscribe((data) => {
        this.isLoadingResults = false;
        this.showSnackbar(`Admin ${Boolean(JSON.parse(data)) == true ? "Enabled" : "Disabled"}`);
        row.isActive = Boolean(JSON.parse(data));
      }, 
      (e) => {
        console.log(e);
        this.isLoadingResults = false;
        this.showSnackbar(e.message);
      });
    }else{
      this.showSnackbar("Invalid Status Requested!");
    }
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }
  
}
