import { CanActivate,CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AppService } from '../app.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate,CanActivateChild {

  public loggedInUser: LoggedInUser;
  public authMessage: String;
  @Output() loggedInUserUpdated: EventEmitter<LoggedInUser> = new EventEmitter();
  @Output() authMessageUpdated: EventEmitter<String> = new EventEmitter();
  retries: number = 0;

  private readonly superAdminRoutes = ['admins', 'tools', 'logs'];

  constructor(private appService: AppService, private router: Router) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean>  {
      this.authMessage = 'Loading...';
      this.authMessageUpdated.emit(this.authMessage);
      await this.onRouteAccessed(next);
      this.authMessage = '';
      this.authMessageUpdated.emit(this.authMessage);
      if(this.loggedInUser && this.loggedInUser.id! > 0){
        return this.isAllowedRoute(this.getResolvedUrl(next));
      }
      this.router.navigate(["/logout"]);
      return false;
  }

  async canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      this.authMessage = 'Loading...';
      this.authMessageUpdated.emit(this.authMessage);
      await this.onRouteAccessed(next);
      this.authMessage = '';
      this.authMessageUpdated.emit(this.authMessage);
      if(this.loggedInUser && this.loggedInUser.id! > 0){
        return this.isAllowedRoute(this.getResolvedUrl(next));
      }
      this.router.navigate(["/logout"]);
      return false;
  }

  async onRouteAccessed(next: ActivatedRouteSnapshot){
    var params = next.queryParams;
    console.log('queryParams', params);
    console.log('environment', environment);

    if(environment.developemnt == true){
      this.makeDefaultSession();
    }else{
      if(params['SAMLart'] == null){
        this.setSessionFromStorage();
        if(this.loggedInUser && this.loggedInUser.id != 0){
          this.appService.getAdminById(this.loggedInUser.id!).subscribe((adminUser: any) => {
            this.setLoggedInUser(adminUser);
          }, e => {
            //Session cleared from Auth Service
          })
        }else{
          console.log("Clearing session - No SAML Art & no saved session details found!")
          this.showInvalidSessionAlert();
        }
      }else{
        await this.validateSessionSaml(params['SAMLart'], params['TARGET'] ? params['TARGET'] : environment.appUrl );
      }
    }
  }

  async validateSessionSaml(samlArt: string, samlTarget: string){
    console.log('ValidateSamlArt', samlArt);
    try {
      const request$ = this.appService.validateSAMLart(encodeURIComponent(samlTarget), encodeURIComponent(samlArt));
      var adminUser = await firstValueFrom(request$);
      if(adminUser.secret != null){
        this.setLoggedInUser(adminUser);
        window.location.href = samlTarget;
      } else {
        console.log("Clearing session - No Admin Secret found!")
        this.clearSession();
      }
    }
    catch (e) {
      console.log("SAML Art Error", e);
      if(environment.developemnt == false){
        if(this.retries > 3){
          console.log("Clearing session - Unable to validate SAML Art!");
          this.clearSession();
        }else{
          this.retries++;
          this.sleep(3000);
          this.validateSessionSaml(samlArt, samlTarget);
        }
      }
    }
  }

  setLoggedInUser(adminUser: any){
    let tzOffset = new Date().getTimezoneOffset();
    var superAdmin:boolean = adminUser.roleId == 0;
    this.loggedInUser = {
      id: adminUser.id,
      firstName: adminUser.firstName,
      lastName: adminUser.lastName,
      email: adminUser.email,
      isSuperAdmin: superAdmin,
      secret: adminUser.secret,
      timezoneOffset: tzOffset,
      timezoneHrsMin: this.getTimezoneHrsMin(tzOffset)
    };
    this.saveSessionToStorage();
    this.loggedInUserUpdated.emit(this.loggedInUser);
  }

  saveSessionToStorage(){
    localStorage.setItem("aonlineLoggedInUser", JSON.stringify(this.loggedInUser));
  }

  setSessionFromStorage(){
      this.loggedInUser = JSON.parse(localStorage.getItem("aonlineLoggedInUser") as string);
  }

  clearSession(){
    if(this.loggedInUser && this.loggedInUser?.id! > 0){
      this.loggedInUser = this.getEmptyUser();
      localStorage.clear();
      this.loggedInUserUpdated.emit(this.loggedInUser);
      this.showInvalidSessionAlert();
    }
  }

  showInvalidSessionAlert(){
    alert("Your session is invalid, please login again. ");
    window.location.href = environment.aonlineUrl;
  }

  getEmptyUser(){
    return  {
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      isSuperAdmin: false,
      secret: '',
      timezoneOffset: 0,
      timezoneHrsMin: '+0000'
    };
  }

  getLoggedInUser(){
    if(this.loggedInUser && this.loggedInUser?.id! > 0){
      return this.loggedInUser;
    }else{
      return this.getEmptyUser();
    }
  }

  makeDefaultSession(){
    let tzOffset = new Date().getTimezoneOffset();
    this.loggedInUser = {
      id: 16,
      firstName: 'Abdullah',
      lastName: 'Adev',
      email: 'abdullah.a@aondev.com',
      isSuperAdmin: true,
      secret: 'default',
      timezoneOffset: tzOffset,
      timezoneHrsMin: this.getTimezoneHrsMin(tzOffset)
    }
    this.saveSessionToStorage();
    this.loggedInUserUpdated.emit(this.loggedInUser);
  }

  sleep(ms: number | undefined) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  isAllowedRoute(route: string) : boolean{
    let routeStr = route.replace(/^\/|\/$/g, '');
    var routeBaseArr = routeStr.split('/');
    if(routeBaseArr.length > 0){
      var routeBase = routeBaseArr[0];
      if(this.superAdminRoutes.includes(routeBase) && !this.loggedInUser.isSuperAdmin){
        return false;
      }else{
        return true;
      }
    }
    return true;
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    let url = route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
    const queryParam = route.queryParamMap;
    if (queryParam.keys.length > 0) {
      url += '?' + queryParam.keys.map(key => queryParam.getAll(key).map(value => key + '=' + value).join('&')).join('&');
    }
    return url;
  }

  getTimezoneHrsMin(tzOffset: number): string{
    let sign = tzOffset > -1 ? '-' : '+';
    let hh = this.getTwoDigitNumber(~~(Math.abs(tzOffset)/60));
    let mm = this.getTwoDigitNumber(Math.abs(tzOffset)%60);
    return `${sign}${hh}${mm}`
  }

  getTwoDigitNumber(i: Number){
    return i.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
  }
}