<!--loader-->
<div *ngIf="isLoading">
  <div class="loaderbox">
      <div class="darkbackground-box"></div>
      <div class="spinnerbox-ic">
          <mat-spinner style="width: 55px;height:55px"></mat-spinner>
      </div>
    </div>
</div>

<div class="jumbotron paral paralsec py-5">
  <div class="container card px-4 py-3">
    <div class="row hdbox-fm">
      <div class="col-md-8 col-sm-8 col-xs-8 hdbox-fm-grid d-flex align-items-center">
        User Details - <span >{{user?.firstName}} {{user?.lastName}}  ({{ user?.username }})</span>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4 sorting-contentbox d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-dark d-flex align-items-center justify-content-center" [routerLink]="'edit'">
          <mat-icon>edit</mat-icon>
          <span class="m-2">Edit User</span>
        </button>
        <button type="button" class="btn btn-dark d-flex align-items-center justify-content-center mx-2" [routerLink]="'audit'">
          <mat-icon>summarize</mat-icon>
          <span class="m-2">Audit Report</span>
        </button>
      </div>
    </div>
        
    <p class="h5 pb-2">Clients, Groups and Tools</p>
    <table class="table table-striped">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Clients</th>
            <th scope="col">Groups</th>
            <th scope="col">Tools</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of usersClientGroupToolsRows; index as i">
            <th *ngIf="row.clientId" [attr.rowspan]="row.rowSpan">{{row.index ? row.index : ""}}</th>
            <td *ngIf="row.clientId" [attr.rowspan]="row.rowSpan">{{row.clientName}} ({{row.clientId}})</td>
            <td>{{row.groupId}}</td>
            <td>{{row.toolCodes}}</td>
        </tr>
    </tbody>
    </table>
    <p *ngIf="usersClientGroupToolsRows.length == 0" class="text-center text-info">No groups assigned!</p>
  </div>
</div>
  
  
  
