export const environment = {
    production: false,
    staging: true,
    qa: false,
    developemnt: false,
    appUrl: 'https://user-mgmt.aonline-staging.aon.com',
    // aonlineUrl: 'https://aonline.eutaweb1.aon.nl',
    // apiUrl: 'http://nclpwnqapp10455:4334/api',
    aonlineUrl: 'https://aonline-qc.aon.com',
    aonlineHomepageUrl: 'https://aonline-qc.aon.com/myportal',
    apiUrl: 'https://user-mgmt.aonline-staging.aon.com/api',
    getAccessTargetUrl: '/GetAccess/SamlPortalTransferAgent?TARGET=',
    staticFilesRoot: 'wwwroot',
    keepAlive: 'https://aonline-qc.aon.com/keep/alive.js'
};
