import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, catchError, finalize, Observable, of } from "rxjs";
import { LoggedInUser } from "src/models/LoggedInUser";
import { AppService } from "../app.service";

export class LogsDataSource implements DataSource<any> {

    private logsSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private maxCountSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public maxCount$ = this.maxCountSubject.asObservable();

    constructor(private appService: AppService) {}

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.logsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.logsSubject.complete();
        this.loadingSubject.complete();
    }

    loadLogs(pageIndex = 0, pageSize = 10, logId = 0, logVal='', sortDirection = 'desc') {

        this.loadingSubject.next(true);
        if(logId==null || logId==undefined || logId.toString()=='')
        {
        logId=0;
        }
        let offset = pageIndex * pageSize;
        this.appService.getLogs(pageSize, offset, logId, logVal, sortDirection).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(result => {
            this.logsSubject.next(result.data);
            console.log(this.logsSubject);
            this.maxCountSubject.next(result.maxCount);
        });
        
    }    
}