<!--loader-->
<div id="loading" *ngIf="isLoadingResults || (dataSource.loading$ | async)">
  <div class="loaderbox">
      <div class="darkbackground-box"></div>      
      <div class="spinnerbox-ic">
          <mat-spinner style="width: 55px;height:55px"></mat-spinner>
      </div>      
      </div>
</div>

<!-- Parallax Section -->
<div class="jumbotron paral paralsec py-5">
  <div class="container card px-4 py-1">
    <div class="row">
      <div class="col-md-4 col-sm-4 col-xs-4 hdbox-fm-grid d-flex align-items-center">
        Logs
      </div>
      <div class="col-md-8 col-sm-8 col-xs-8 sorting-contentbox d-flex align-items-center justify-content-end">
        <form [formGroup]="filterForm" class="mt-3">
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Search by Log Id</mat-label>
            <input matInput formControlName="log" name="log" placeholder="Ex. 190" #input>
          </mat-form-field>
  
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Filter by Log Level</mat-label>
            <select  matNativeControl formControlName="logVal" name="logVal" (change)="logValChange()" #select>
              <option value="Information">Information</option>
              <option value="Error">Error</option>
          </select>
          </mat-form-field>
         
          <button href="#" class="btn btn-sm" (click)="onClearFilters()">Clear filters</button>
        </form>
      </div>
    </div>

    <div class="mat-elevation-z8 data-row-columm mt-2 mb-3">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows matSort matSortActive="id" matSortDirection="desc" matSortDisableClear>
    
        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th class="mat-column-id" mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td class="mat-column-id" mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="value" >
          <th mat-header-cell *matHeaderCellDef> Log Level </th>
          <td mat-cell *matCellDef="let row"> {{row.level}} </td>
        </ng-container>

        <!-- Error Message Column -->
        <ng-container matColumnDef="msg" >
          <th mat-header-cell *matHeaderCellDef> Message </th>
          <td mat-cell *matCellDef="let row"> {{row.message}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="created" >
          <th mat-header-cell *matHeaderCellDef> Created </th>
          <td mat-cell *matCellDef="let row"> {{row.added_Date}} </td>
        </ng-container>

        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button aria-label="expand row" (click)="toggleExpandClicked($event, row)">
              <mat-icon *ngIf="expandedElement !== row">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="expandedElement === row">keyboard_arrow_up</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row" [attr.colspan]="displayWithExpand.length">
            <div class="row-detail-inner-container"
                [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
            <div class="row w-100 m-2">
                <div class="col-md-4">
                  <label *ngIf="row.exception">Exception: &nbsp;</label>{{row.exception}}
                  <label *ngIf="row.stackTrace">Stack Trace: &nbsp;</label>{{row.stackTrace}}
                </div>
            
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayWithExpand"></tr>
        <tr mat-row *matRowDef="let row; columns: displayWithExpand;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === row"
          (click)="expandedElement = expandedElement === row ? null : row"></tr>
        
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    
      <mat-paginator [length]="dataSource.maxCount$ | async" [pageSize]="10" [pageSizeOptions]="[10, 50, 100, 500]" aria-label="Select page of dataSource"></mat-paginator>
    </div>
    
  </div>
</div>
