import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AppService } from '../app.service';
import { AuthGuard } from '../auth/authguard.service';

@Component({
  selector: 'app-user-audit',
  templateUrl: './user-audit.component.html',
  styleUrls: ['./user-audit.component.scss']
})
export class UserAuditComponent {

  loggedInUser : LoggedInUser;
  minLengthTerm = 2;

  id: number | null;
  user: any;
  isLoading: boolean = false;

  umTrail: any;
  oktaTrail: any;

  constructor( private appService: AppService, private authGuard: AuthGuard, private route: ActivatedRoute, private _snackBar: MatSnackBar ) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.id = parseInt(this.route.snapshot.paramMap.get('id')!);
    if (Number.isNaN(this.id)){
      this.showSnackbar('Invalid Id');
      return;
    }
    this.findUserDetails(this.id);
  }

  findUserDetails(id: number){
    this.isLoading = true;
    forkJoin([
      this.appService.getUserById(id),
    ]).subscribe(
      (results) => {
        this.isLoading = false;
        this.user = results[0];
        this.fetchAuditItems();
      },
      (error) => {
        this.isLoading = false;
        this.showSnackbar(error.message);
      }
    );
  }

  fetchAuditItems(){
    this.isLoading = true;

    forkJoin([
      this.appService.getAuditLogs(9999, 0, 'User', this.user?.username),
      this.appService.getOktaDetailsByEmail(this.user?.email)
    ]).subscribe(
      (results) => {
        this.isLoading = false;
        this.umTrail = results[0];
        this.oktaTrail = results[1];
      },
      (error) => {
        this.isLoading = false;
        this.showSnackbar(error.message);
      }
    );
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

}
