<!-- banner start -->
<div class="container-fluid">
    <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 rmv-pd">
    <div class="containerbox-banner"></div>
    
    </div>
    </div>
    </div>
    <!-- banner close-->
    
       <!--loader-->
<div id="loading">
  <div class="loaderbox">
      <div class="darkbackground-box"></div>      
      <div class="spinnerbox-ic">
          <mat-spinner style="width: 55px;height:55px"></mat-spinner>
      </div>     
      </div> 
</div>
    
    <!--form area -->
    <div class="container form-area-box">
    <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 hdbox-fm">
    Assign Tools to Country
    </div>
    <span class="error-msg-txt">{{message}}</span>
    </div>
    
    

    <div class="row frm">
        <div class="col-md-2 col-sm-3 col-xs-3 txt-ad-box">Country:</div>
        <div class="col-md-10 col-sm-10 col-xs-10 input-ad-box">
            <select class="input-ad-box dropdown select-btn" style="width: 350px" id="country" [(ngModel)]="country"
            name="country" (change)="onCountryChange($event)">
            <option selected="true" disabled>Choose Country</option>
            <option *ngFor="let country of countryList" [value]="country.id">
              {{country.code}} ({{country.name}})</option>
        </select>
        </div>
    </div>

        
    <div class="row frm">
        <div class="col-md-2 col-sm-3 col-xs-3 txt-ad-box">Tools access to country :<div class="smt-txt">(Multiple choice field and its values are ACX, GAMS, Shopie, Aon Bond Link )</div></div>
        <div class="col-md-10 col-sm-10 col-xs-10 input-ad-box">
          <mat-form-field appearance="fill" class="dropdown-access">
          <mat-label>Select Tools</mat-label>
          <mat-select [formControl]="apps" [(ngModel)]="selectedTools" multiple panelClass="input-ad-box dropdown" style="width: 350px;height: 50px;">
            <mat-select-trigger>
              {{(selectedTools.length > 0) ? selectedTools[0].title : ''}}
              <span *ngIf="(apps.value?.length || 0) > 1" class="example-additional-selection">
                (+{{(apps.value?.length || 0) - 1}} {{apps.value?.length === 2 ? 'other' : 'others'}})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let app of appList" [value]="app" [disabled]="!app.isActive">{{app.title}}</mat-option>
          </mat-select>
        </mat-form-field>
        </div>
        </div>
    <!-- <div class="row frm">
    <div class="col-md-2 col-sm-3 col-xs-3 txt-ad-box">Status :</div>
    <div class="col-md-10 col-sm-10 col-xs-10 input-ad-box">
        <select class="input-ad-box dropdown"  id="user-dropdown" style="width: 350px;height: 50px;" name="user">
            <option selected="true" disabled>Select Status</option>
            <option >Active</option>
            <option >Inactive</option>
           </select>
    </div>
    </div> -->       
    
    <div class="row frm">
    <div class="col-md-2 col-sm-3 col-xs-3 txt-ad-box"></div>
    <div class="col-md-10 col-sm-10 col-xs-10 input-ad-box">
      <a (click)="onSubmit()" class="svn-btn">Save</a> <a href="#" class="cn-btn">Cancel</a></div>
    </div>
    
    </div>
    <!--form area -->
