<!--loader-->
<div *ngIf="isLoading">
    <div class="loaderbox">
        <div class="darkbackground-box"></div>
        <div class="spinnerbox-ic">
            <mat-spinner style="width: 55px;height:55px"></mat-spinner>
        </div>
    </div>
</div>
<div id="myModal" class="modal" *ngIf="isClaimsToolSelected">

    <!-- Modal content -->
    <div class="modal-content">
      
      <div class="row hdbox-fm my-2">
        <div><span class="close" (click)="closeForm()">&times;</span></div>
        <div class="col-md-12 col-sm-12 col-xs-12 hdbox-fm-grid d-flex align-items-center">
            Claim Managers Contact Form            
        </div>
        
    </div>
      <div>
        <form [formGroup]="claimsContactForm" (ngSubmit)="onClaimContactFormSubmit()" class="my-3">
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Primary SPOC Email :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter Primary SPOC's Email</mat-label>
                        <input matInput formControlName="aspemail" name="aspemail" [(ngModel)]="selectedAspEmail">
                        <div *ngIf="claimsContactForm.controls['aspemail'].touched && claimsContactForm.controls['aspemail'].invalid">
                            <mat-error *ngIf="claimsContactForm.controls['aspemail'].errors?.['required']">This field is required</mat-error>
                            <mat-error *ngIf="claimsContactForm.controls['aspemail'].errors?.['aspemail']">Invalid email address</mat-error>
                        </div>
                    </mat-form-field>
                    <br>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Primary SPOC First Name :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter first name</mat-label>
                        <input matInput formControlName="aspfname" name="aspfname" [(ngModel)]="selectedAspFName" [disabled]="true" >
                        <div *ngIf="claimsContactForm.controls['aspfname'].touched && claimsContactForm.controls['aspfname'].invalid">
                            <mat-error *ngIf="claimsContactForm.controls['aspfname'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                    <br>
                </div>
            </div>
		  
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Primary SPOC Last Name :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field m-0 p-0">
                    <mat-label>Enter last name</mat-label>
                    <input matInput formControlName="asplname" name="asplname" [(ngModel)]="selectedAspLName" [disabled]="true" >
                    <div *ngIf="claimsContactForm.controls['asplname'].touched && claimsContactForm.controls['asplname'].invalid">
                        <mat-error *ngIf="claimsContactForm.controls['asplname'].errors?.['required']">This field is required</mat-error>
                    </div>
                </mat-form-field>
                <br>
            </div>
        </div>
         
          
        <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Secondary SPOC Email :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field m-0 p-0">
                    <mat-label>Enter Secondary SPOC's Email</mat-label>
                    <input matInput formControlName="assemail" name="assemail" [(ngModel)]="selectedAssEmail">
                    <div *ngIf="claimsContactForm.controls['assemail'].touched && claimsContactForm.controls['assemail'].invalid">
                        <mat-error *ngIf="claimsContactForm.controls['assemail'].errors?.['required']">This field is required</mat-error>
                        <mat-error *ngIf="claimsContactForm.controls['assemail'].errors?.['assemail']">Invalid email address</mat-error>
                    </div>
                </mat-form-field>
                <br>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Secondary SPOC First Name :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field m-0 p-0">
                    <mat-label>Enter first name</mat-label>
                    <input matInput formControlName="assfname" name="assfname" [(ngModel)]="selectedAssFName" [disabled]="true" >
                    <div *ngIf="claimsContactForm.controls['assfname'].touched && claimsContactForm.controls['assfname'].invalid">
                        <mat-error *ngIf="claimsContactForm.controls['assfname'].errors?.['required']">This field is required</mat-error>
                    </div>
                </mat-form-field>
                <br>
            </div>
        </div>
      
      <div class="row">
        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Secondary SPOC Last Name :</div>
        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
            <mat-form-field appearance="outline" class="form-field m-0 p-0">
                <mat-label>Enter last name</mat-label>
                <input matInput formControlName="asslname" name="asslname"[(ngModel)]="selectedAssLName" [disabled]="true" >
                <div *ngIf="claimsContactForm.controls['asslname'].touched && claimsContactForm.controls['asslname'].invalid">
                    <mat-error *ngIf="claimsContactForm.controls['asslname'].errors?.['required']">This field is required</mat-error>
                </div>
            </mat-form-field>
            <br>
        </div>
    </div>
     
      
     
    <div class="row">
        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>ODS Client Id :</div>
        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
            <mat-form-field appearance="outline" class="form-field m-0 p-0">
                <mat-label>Enter ODS Client Id: eg 1363340 or 1363340, 1363341, 1363342...</mat-label>
                <input matInput formControlName="odsci" name="odsci">
                <mat-hint >*eg 1363340 or 1363340, 1363341, 1363342...</mat-hint>
                <div *ngIf="claimsContactForm.controls['odsci'].touched && claimsContactForm.controls['odsci'].invalid">
                    <mat-error *ngIf="claimsContactForm.controls['odsci'].errors?.['required']">This field is required</mat-error>
                </div>
            </mat-form-field>
            <br>
        </div>
    </div>
          
    <div class="row mt-3">
        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"></div>
        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
            <button type="submit" class="svn-btn" >Submit</button>
          <button (click)="resetForm()" type="button" class="cn-btn">Cancel</button>
        </div>
    </div>      
          
        </form>
      </div>
      
    </div>
  
  </div>
<div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-3">
        <div class="row hdbox-fm my-2">
            <div class="col-md-12 col-sm-12 col-xs-12 hdbox-fm-grid d-flex align-items-center">
                Create Client
            </div>
        </div>
    
        <form [formGroup]="createForm" (ngSubmit)="onSubmit()" class="my-3">
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Select Country :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field class="example-full-width">
                        <mat-label>Search Country</mat-label>
                        <input matInput placeholder="Search countries" [(ngModel)]="selectedCountry" [matAutocomplete]="auto"
                            formControlName="country" name="country" placeholder="Enter country code">
                        <div *ngIf="createForm.controls['country'].touched && createForm.controls['country'].invalid">
                            <mat-error *ngIf="createForm.controls['country'].errors?.['required']">This field is required</mat-error>
                        </div>
                        <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onCountrySelected()"
                          [displayWith]="displayCountryWith">
                          <mat-option *ngIf="isLoadingCountries" class="is-loading">Loading...</mat-option>
                          <ng-container *ngIf="!isLoadingCountries">
                            <mat-option *ngFor="let country of filteredCountries" [value]="country">
                              <span>{{country.name}} ({{country.code}})</span>
                            </mat-option>
                          </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Client Name :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter client name</mat-label>
                        <input matInput formControlName="name" name="name">
                        <div *ngIf="createForm.controls['name'].touched && createForm.controls['name'].invalid">
                            <mat-error *ngIf="createForm.controls['name'].errors?.['required']">This field is required</mat-error>
                            <mat-error *ngIf="createForm.controls['name'].errors?.['exists']">Client name already exists</mat-error>
                        </div>
                    </mat-form-field>
                    <br>
                    <button type="button" class="btn btn-dark btn-sm mb-3" *ngIf="clientExists && canEditExistingClient" [routerLink]="['/clients', existingClientId, 'edit']">Edit existing client</button>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Type :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Client type</mat-label>
                        <mat-select formControlName="type" name="type">
                            <mat-option *ngFor="let type of optionClientTypes" [value]="type.value">
                                {{type.text}}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="createForm.controls['type'].touched && createForm.controls['type'].invalid">
                            <mat-error *ngIf="createForm.controls['type'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Industry :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Client's industry</mat-label>
                        <mat-select formControlName="industry" name="industry" [(ngModel)]="selectedIndustry">
                            <mat-option *ngFor="let industry of allIndustries" [value]="industry">
                                {{industry.title}}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="createForm.controls['industry'].touched && createForm.controls['industry'].invalid">
                            <mat-error *ngIf="createForm.controls['industry'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-12 col-xs-3 txt-ad-box text-md-end">Provide Access on :<div class="smt-txt">(Multiple choice field and its values are ACX, GAMS, Shopie, Aon Bond Link )</div></div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="dropdown-access">
                        <mat-label>Select Apps</mat-label>
                        <mat-select formControlName="tools" name="tools" [(ngModel)]="selectedTools" multiple panelClass="input-ad-box dropdown">
                            <mat-select-trigger>
                                {{(selectedTools.length > 0) ? selectedTools[0].title : ''}}
                                <span *ngIf="(selectedTools.length || 0) > 1" class="example-additional-selection">
                                    (+{{(selectedTools.length || 0) - 1}} {{selectedTools.length === 2 ? 'other' : 'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let tool of allTools" [value]="tool" [disabled]="tool.disabled">{{tool.title}}</mat-option>
                        </mat-select>
                        <mat-hint *ngIf="noTools">No tools available for selected country</mat-hint>
                        <mat-hint  *ngIf="isCollabToolSelected" class="text-primary">Selecting 'Sharepoint Collaboration' tool will create a new Sharepoint collaboration site for this client.</mat-hint>
                    </mat-form-field>
                    <div *ngIf="noTools || isCollabToolSelected" class="mb-4"></div>
                </div>
            </div>

            <div class="row" *ngIf="isRealEstateToolSelected || isRequestQuoteToolSelected">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>SP Site Url :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter Site Url for Real Estate/Request Quote</mat-label>
                        <input matInput formControlName="spUrl" name="spUrl">
                        <div *ngIf="createForm.controls['spUrl'].touched && createForm.controls['spUrl'].invalid">
                            <mat-error *ngIf="createForm.controls['spUrl'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                </div>
            </div>
    
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Upload Logo :<div class="smt-txt">Max 165x44 px, JPEG/PNG</div></div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <div>
                        <input class="form-control upload-file-btn" type="file" (change)="onFileChange($event)">
                        <mat-error *ngIf="fileErr">{{fileErr}}</mat-error>
                    </div>
                </div>
            </div>
    
            <div class="row mt-3">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"></div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <button type="submit" class="svn-btn" [disabled]="this.createForm.invalid || fileErr">Submit</button>
                    <button (click)="onCancel()" type="button" class="cn-btn">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>