import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { fromEvent, merge, Observable } from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import { SpTaskDataSource } from './sptask-data-source';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { EditAdminComponent } from '../edit-admin/edit-admin.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-reports-sharepoint-tasks',
  templateUrl: './reports-sharepoint-tasks.component.html',
  styleUrls: ['./reports-sharepoint-tasks.component.scss']
})
export class ReportsSharepointTasksComponent implements OnInit{
  
 
  loggedInUser : LoggedInUser;
  minLengthTerm = 1;

  dataSource: SpTaskDataSource;
  displayedColumns: string[] = ['id','adminName', 'email','task', 'status', 'entityId', 'createdAt','updatedAt'];
  _data: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('spTaskInput') spTaskInput: ElementRef;
  @ViewChild('adminInput') adminInput: ElementRef;
  @ViewChild('entityInput') entityInput: ElementRef;
   
  filterForm = new FormGroup({
    sptask: new FormControl(''),
    admin: new FormControl(''),
    entity: new FormControl('')
  });

  isLoadingResults = false;

  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  // For Country selection
  

  constructor(private router: Router, private appService: AppService, private authGuard: AuthGuard, private _snackBar: MatSnackBar, public dialog: MatDialog) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }
  
  ngOnInit(): void {   
    
    this.dataSource = new SpTaskDataSource(this.appService);    
    this.dataSource.loadSpTask(0);
  }

  ngAfterViewInit() {
   
    fromEvent(this.spTaskInput.nativeElement,'keyup')
    .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
            this.paginator.pageIndex = 0;
            this.loadSharePointTasks();
        })
    )
    .subscribe();
    fromEvent(this.adminInput.nativeElement,'keyup')
    .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
            this.paginator.pageIndex = 0;
            this.loadSharePointTasks();
        })
    )
    .subscribe();
    fromEvent(this.entityInput.nativeElement,'keyup')
    .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
            this.paginator.pageIndex = 0;
            this.loadSharePointTasks();
        })
    )
    .subscribe();
    
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        
    merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadSharePointTasks())
        )
        .subscribe();   
    

  }

  loadSharePointTasks(): void {
     this.dataSource.loadSpTask(
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.spTaskInput.nativeElement.value,
      this.adminInput.nativeElement.value,
      this.entityInput.nativeElement.value,      
      this.dateRange.value.start ? this.dateRange.value.start.toISOString() : '',
      this.dateRange.value.end ? this.dateRange.value.end.toISOString() : '',
      this.sort.direction);
      
  } 

  onClearFilters(){
    this.filterForm.reset();
    this.dateRange.reset();
    this.paginator.pageIndex = 0;
    this.loadSharePointTasks();
  }
 
  exportForm() {
    this.isLoadingResults = true; 
    this.appService.getSharepointTaskCSV(
      this.spTaskInput.nativeElement.value,
      this.adminInput.nativeElement.value,
      this.entityInput.nativeElement.value,
      this.dateRange.value.start ? this.dateRange.value.start.toISOString() : '',
      this.dateRange.value.end ? this.dateRange.value.end.toISOString() : '',
      this.sort.direction
    ).subscribe(
      (data: string) => { 
        this.showSnackbar('CSV exported Successfully');
        const a = document.createElement("a");
        a.href = "data:text/csv," + data;
        let filename = "SharePoint Tasks";
        a.setAttribute("download", filename + ".csv");
        document.body.appendChild(a);        a.click();
        document.body.removeChild(a);
        this.isLoadingResults = false;
      },
      (e: { message: string; }) => {
        this.showSnackbar("Error exporting report - " + e.message);
        this.isLoadingResults = false;         
      }
    );         
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }
  onDateSelect(){    
    if(this.dateRange.value.start != null && this.dateRange.value.end != null)
    {
      this.paginator.pageIndex = 0;
      this.loadSharePointTasks();
    }
  }
}

