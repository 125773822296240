import {AfterViewInit, Component, ViewChild,OnInit, inject, ElementRef} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { AppService } from '../app.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {forkJoin, fromEvent, merge, observable, Observable, of as observableOf, of, pipe} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import { UserDataSource } from './user-data-source';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { MatDialog } from '@angular/material/dialog';
import { AuditUserComponent } from '../audit-user/audit-user.component';
import { environment } from 'src/environments/environment';
import { OPTION_USER_TYPES, OPTION_COUNTRIES, OPTION_SALUTATIONS, OPTION_LANGUAGES } from 'src/models/Constants';
import { UserFilterComponent } from '../user-filter/user-filter.component';
import { NgDialogAnimationService } from "../utilities/d.service";
import { SelectionModel } from '@angular/cdk/collections';
import { EmailMessage } from 'src/dto/EmailMessage';
import { FileSelectorComponent } from '../file-selector/file-selector.component';
import { OktaUserProfile } from 'src/models/OktaUserProfile';

@Component({
  selector: 'app-user-report',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class UserReportComponent implements OnInit, AfterViewInit {

  optionUserTypes: any[] = OPTION_USER_TYPES;
  optionCountries: any[] = OPTION_COUNTRIES;
  optionSalutations: any[] = OPTION_SALUTATIONS;
  optionLanguages: any[] = OPTION_LANGUAGES;

  loggedInUser : LoggedInUser;
  minLengthTerm = 2;

  //Route Params
  gotParam: boolean = false;
  readonly routeParams = ['sortBy', 'sort', 'pageIndex', 'pageSize', 'searchText', 'client', 'subgroup', 'type', 'country', 'isActive', 'emailSent'];
  receivedParams : any = {
    sortBy: 'name',
    sort : 'asc',
    pageIndex: 0,
    pageSize: 50,
    searchText: '',
    client: 0,
    subgroup: 0,
    type: '',
    country: '',
    isActive: '',
    emailSent: ''
  }

  dataSource: UserDataSource;
  displayedColumns: string[] = ['select', 'id', 'name', 'email','type', 'country', 'lastLogin', 'isActive', 'emailSent', 'ac-de'];
  displayWithExpand = [...this.displayedColumns, 'expand'];
  message: string | null = null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  filterForm = new FormGroup({
    client: new FormControl(''),
    subgroup: new FormControl(''),
    usertype: new FormControl(''),
    country: new FormControl(''),
    isactive: new FormControl(''),
    emailSent: new FormControl('')
  });
  
  // For Client selection
  filteredClients: any;
  isLoadingClients = false;
  selectedClient: any = "";

  // For Subgroup selection
  clientSubgroups: any = [];
  selectedSubgroup: any = null;

  // For UserType selecion
  selectedUserType: any = "";

  // For Country selecion
  selectedCountry: any = "";

  // For Active/Inactive selecion
  selectedIsActive: boolean | null = null;

  // For Email sent selecion
  selectedEmailSent: boolean | null = null;

  constructor(private router: Router, 
    private route: ActivatedRoute, 
    private location: Location, 
    public dialog: MatDialog, 
    private appService: AppService, 
    private authGuard: AuthGuard, 
    private _snackBar: MatSnackBar,
    public filterDialog: NgDialogAnimationService
    ) {   
      this.loggedInUser = authGuard.loggedInUser;
      this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
        this.loggedInUser = loggedInUserNew;
      });
  }

  ngOnInit(): void {
    this.isLoadingResults = false;
    this.dataSource = new UserDataSource(this.appService);
  }

  body:any;
  expandedElement:any | null;

  ngAfterViewInit() {
    setTimeout(() => {
      this.isLoadingResults = false;
      //Check and refresh page for received params
      this.gotParam = false;
      this.route.queryParams
        .subscribe(params => {
          this.routeParams.forEach(param => {
            if(params[param]){
              this.gotParam = true;
              this.receivedParams[param] = params[param];
            }
          });

          this.sort.sort(({ id: this.receivedParams.sortBy, start: this.receivedParams.sort}) as MatSortable);
          if(this.gotParam){
            this.setPageFiltersFromParams();
          }else{
            this.dataSource.loadUsers(0);
          }

          this.subscribeEvents();
        }
      );
    });
  }

  subscribeEvents(){
    // server-side search
    fromEvent(this.input.nativeElement,'keyup')
    .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
            this.paginator.pageIndex = 0;
            this.loadUsersPage();
        })
    )
    .subscribe();
    
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        
    merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap((e) => {
              return this.loadUsersPage()
            })
        )
        .subscribe();

    //Listen to client selection changes
    this.filterForm.controls['client'].valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(250),
        tap(() => {
          this.filteredClients = [];
          this.isLoadingClients = true;
        }),
        switchMap(value => this.appService.searchClients(10, 0, value as string, '', '', '', '', false, 'name', 'asc', true)
          .pipe(
            finalize(() => {
              this.isLoadingClients = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        if (response['data'] == undefined) {
          this.filteredClients = [];
        } else {
          this.filteredClients = response['data'];
        }
      });
  }

  setPageFiltersFromParams(){
    setTimeout(() => {
      this.sort.active = this.receivedParams['sortBy'];
      this.sort.direction = this.receivedParams['sort'];
      this.paginator.pageIndex = this.receivedParams['pageIndex'];
      this.paginator.pageSize = this.receivedParams['pageSize'];
      this.input.nativeElement.value = this.receivedParams['searchText'];
      if(this.receivedParams['type']){
        this.selectedUserType = this.receivedParams['type'];
      }
      if(this.receivedParams['country']){
        this.selectedCountry = this.receivedParams['country'];
      }
      if(this.receivedParams['isActive'] != null){
        this.selectedIsActive = this.receivedParams['isActive'] == 'true' ? true : 
        this.receivedParams['isActive'] == 'false' ? false : null;
      }
      if(this.receivedParams['emailSent'] != null){
        this.selectedEmailSent = this.receivedParams['emailSent'] == 'true' ? true : 
        this.receivedParams['emailSent'] == 'false' ? false : null;
      }
      if(this.receivedParams['client']){
        this.isLoadingResults = true;
        this.appService.searchClients(10, 0, this.receivedParams['client'], '', '', '', '', false, 'name', 'asc', true)
        .subscribe((response: any) => {
          this.filteredClients = response.data;
          this.selectedClient = this.filteredClients.find((client: { clientId: any; }) => client.clientId === this.receivedParams['client']);
          this.selectedSubgroup = null;
          this.clientSubgroups = [];
          this.appService.getClientGroups(this.selectedClient.id).subscribe((response) => {
            this.isLoadingResults = false;
            this.clientSubgroups = response.filter((tg: { isDefault: boolean; }) => tg.isDefault === false);
            if(this.receivedParams['subgroup']){
              this.selectedSubgroup = this.clientSubgroups.find((subgroup: { groupId: any; }) => subgroup.groupId === this.receivedParams['subgroup']);
            }
            this.loadUsersPage();
          }, 
          (e) => {
            this.clientSubgroups = [];
            this.selectedSubgroup = null;
            this.loadUsersPage();
          });
          
        });
      }else{
        this.loadUsersPage();
      }
    });
    
  }

  loadUsersPage(): void {
    this.dataSource.loadUsers(
    this.paginator.pageIndex,
    this.paginator.pageSize,
    this.input.nativeElement.value,
    this.selectedClient ? this.selectedClient.id : 0,
    this.selectedSubgroup ? this.selectedSubgroup.id : 0,
    this.selectedUserType ? this.selectedUserType : "",
    this.selectedCountry ? this.selectedCountry : "",
    this.selectedIsActive,
    this.selectedEmailSent,
    this.sort.active,
    this.sort.direction);

    this.updateUrlState();
    this.selection.clear();
  }

  updateUrlState(): void {
    let pathParts: [string] = [`/users?pageIndex=${this.paginator.pageIndex}&pageSize=${this.paginator.pageSize}&sortBy=${this.sort.active}&sort=${this.sort.direction}`];
    if(this.input.nativeElement.value){
      pathParts.push(`searchText=${this.input.nativeElement.value}`)
    }
    if(this.selectedClient){
      pathParts.push(`client=${this.selectedClient.clientId}`)
    }
    if(this.selectedSubgroup){
      pathParts.push(`subgroup=${this.selectedSubgroup.groupId}`)
    }
    if(this.selectedUserType){
      pathParts.push(`type=${this.selectedUserType}`)
    }
    if(this.selectedCountry){
      pathParts.push(`country=${this.selectedCountry}`)
    }
    if(this.selectedIsActive != null){
      pathParts.push(`isActive=${this.selectedIsActive}`)
    }
    if(this.selectedEmailSent != null){
      pathParts.push(`emailSent=${this.selectedEmailSent}`)
    }
    this.location.replaceState(pathParts.join('&'));
  }

  openUser(event: any){
    this.router.navigateByUrl(`/users/${event}/edit`);
  }

  sendActivationEmail(row:any){
    this.isLoadingResults = true;
    this.appService.sendActivationEmail(row.email)
      .subscribe((data) => {
        this.isLoadingResults = false;
        row.activationEmailSent = true;
        this.showSnackbar(data.message);
      }, 
      (e) => {
        console.log(e);
        this.isLoadingResults = false;
        this.showSnackbar(e.message);
      });
  }

  resetOktaAuthenticators(row:any){
    this.isLoadingResults = true;
    if(confirm(`This will reset Okta MFA factors for the user ${row.username}. User will be asked to set them again on next sign in. Proceed?`)) {
      this.appService.resetOktaAuthenticators(row.email)
      .subscribe((data) => {
        this.isLoadingResults = false;
        this.showSnackbar(data);
      }, 
      (e) => {
        console.log(e);
        this.isLoadingResults = false;
        this.showSnackbar(e.message);
      });
    }else{
      this.isLoadingResults = false;
    }
  }

  toggleStatus(row:any){
    let requestedIsActive = null;
    if(row.isActive == true){
      requestedIsActive = false;
    }else if(row.isActive == false){
      requestedIsActive = true;
    }

    if(requestedIsActive != null){
      this.isLoadingResults = true;
      this.appService.setIsActive(row.email, requestedIsActive)
      .subscribe((data) => {
        this.isLoadingResults = false;
        this.showSnackbar(`User ${Boolean(JSON.parse(data)) == true ? "Activated" : "Deactivated"}`);
        row.isActive = Boolean(JSON.parse(data));
      }, 
      (e) => {
        console.log(e);
        this.isLoadingResults = false;
        this.showSnackbar(e.message);
      });
    }else{
      this.showSnackbar("Invalid Status Requested!");
    }
  }

  refreshStatus(row:any){
    this.isLoadingResults = true;
    this.appService.getUserOktaStatus(row.email, true)
      .subscribe((data: any) => {
        row.status = data;
        this.isLoadingResults = false;
      }, 
      (e) => {
        console.log(e);
        this.isLoadingResults = false;
        this.showSnackbar(e.message);
      });
  }

  toggleExpandClicked(event: any, row: any){
   
    this.expandedElement = this.expandedElement === row ? null : row
    event.stopPropagation();

    if(row.imageUrl == null && row.imagePath){
      row.imageUrl = `${environment.appUrl}${row.imagePath}`;
    } 

    if(row.initials == null && (row.firstName != null || row.lastName != null)){
      row.fullName = [row.firstName, row.lastName].filter(Boolean).join(" ");
      row.initials = row.fullName?.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
    }

    if(row.addressText == null){
      row.addressText = [row.street, row.city, row.zip].filter(Boolean).join(", ");
    }

    if(row.country){
      row.countryView = `${this.optionCountries.filter((country: { value: any; }) => country.value.localeCompare(row.country, undefined, { sensitivity: 'accent' }) === 0)[0]?.text} (${row.country})`
    }

    if(row.languageCode){
      row.languageView = `${this.optionLanguages.filter((lang: { value: any; }) => lang.value.localeCompare(row.languageCode, undefined, { sensitivity: 'accent' }) === 0)[0]?.text} (${row.languageCode})`
    }

    this.loadClientsAndTools(row);
  }

 
  async loadClientsAndTools(row: any){
    
    
    if(row.userToolsStr != null || row.userClientsStr != null){
      return;
    }
    row.emailSender=false;
    row.isLoadingClientsOrTools = true;
    forkJoin([
      this.appService.getUserClients(row.id)
    ]).subscribe((results) => {
      let userClients = results[0].map((userClient: { name: any, clientId:any, isAuthAvailable:boolean  }) => {
        if(userClient.isAuthAvailable !=true ){
          row.emailSender=true;
        }
        return userClient.name;
      });
      if(userClients.length > 10){
        userClients = userClients.slice(0, 10);
        row.isTruncatedUserClients = true;
      }      
      row.userClientsStr = userClients.join(', ');

      row.isLoadingClientsOrTools = false;
    }); 
  }

  onClientSelected() {
    this.selectedClient = this.selectedClient;
    this.selectedSubgroup = null;
    this.clientSubgroups = [];
    this.loadUsersPage();
    this.appService.getClientGroups(this.selectedClient.id).subscribe((response) => {
      this.clientSubgroups = response.filter((tg: { isDefault: boolean; }) => tg.isDefault === false);
    }, 
    (e) => {
      this.clientSubgroups = [];
      this.selectedSubgroup = null;
    });
  }

  onFilterChanged() {
    this.loadUsersPage();
  }

  displayClientWith(value: any) {
    return value?.name;
  }

  getUserTypeName(typeCode: any){
    var arr = this.optionUserTypes.filter(obj => {
      return obj.value === typeCode;
    });
    if(arr.length > 0){
      return arr[0].text;
    }
    return "";
  }

  onClearFilters(){
    this.filterForm.reset();

    this.selectedUserType = null;
    this.selectedCountry = null;
    this.selectedIsActive = null;

    this.paginator.pageIndex = 0;
    this.loadUsersPage();
  }

  onClearSearch($event: any){
    this.input.nativeElement.value = '';
    this.loadUsersPage();
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }
  
  exportForm() {
    this.isLoadingResults = true;   
    this.appService.getUsersCsv(this.input.nativeElement.value,
      this.selectedClient ? this.selectedClient.id : 0,
      this.selectedSubgroup ? this.selectedSubgroup.id : 0,
      this.selectedUserType ? this.selectedUserType : "",
      this.selectedCountry ? this.selectedCountry : "",
      this.selectedIsActive,
      this.selectedEmailSent,
      this.sort.active,
      this.sort.direction)
      .subscribe(
        (data) => {
          data=encodeURIComponent(data);
          this.message = 'CSV exported Successfully';
          const a = document.createElement("a");
          a.href = "data:text/csv," + data;
          let filename = this.selectedClient ? `Users - ${this.selectedClient.name}` : "Users";
          a.setAttribute("download", filename + ".csv");
          document.body.appendChild(a);        a.click();
          document.body.removeChild(a);
          this.isLoadingResults = false;   
          
        },
        (error) => {
          this.message ='An Error occured while Creating client, please try again after sometime';  
          this.isLoadingResults = false;         
        }
      );         
  }

  openFileSelector(){
    const dialogRef = this.dialog.open(FileSelectorComponent, {
      width: '512px',
      data: {multiple: false, allowedTypes: ['text/csv'], sampleFileUrl: `${environment.apiUrl}/${environment.staticFilesRoot}/CsvSamples/UsersImportSample.csv`}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == null){
        return;
      }
      this.onBulkUploadFileSelected(result[0]);
      
    });
  }

  openAuditDialog(row: any){
    this.isLoadingResults = true;

    forkJoin([
      this.appService.getOktaDetailsByEmail(row.email),
      this.appService.getAuditLogs(5, 0, 'User', row.username, 'CREATE,UPDATE,EMAIL,RESET_AUTH,CLIENT_CONTACT_CREATED,CLIENT_CONTACT_REMOVED')
    ]).subscribe(
      (results) => {
        this.isLoadingResults = false;
        const dialogRef = this.dialog.open(AuditUserComponent, {
          width: '1000px',
          data: {oktaTrail: results[0], umTrail: results[1], user: row}          
        });
    console.log(results)
        dialogRef.afterClosed().subscribe(result => {
          if(result == null){
            return;
          }
        });
      },
      (error) => {
        this.isLoadingResults = false;
        this.showSnackbar(error.message);
      }
    );
  }



  // Commented for future use 
  // openFilterDialog(): void {
  //   const dialogRef = this.filterDialog.open(UserFilterComponent, {
  //     direction: 'rtl',
  //     width: '350px',
  //     animation:{to:"left"},
  //     position: { rowEnd: "0" }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if(result){
  //       console.log('Filter Applied', result);
  //     }else{
  //       console.log('Filter Closed');
  //     }
      
  //   });
  // }


// Bulk Upload and options

async onBulkUploadFileSelected(file: File){
  if(await this.validateBulkUploadFile(file)){
    try {
      let oupArr = await this.getOktaUserProfiles(file);
      this.startBulkUserCreationJob(oupArr);
    } catch (error: any) {
      this.showSnackbar(error);
    }
  }
}

async validateBulkUploadFile(file: File){
  let type: string = file.type!;
  if(type !== 'text/csv'){
    this.showSnackbar('Invalid File Type');
    return false;
  }
  let lines: String[] = (await file.text()).split(/[\r\n]+/);
  if(lines[0] !== 'Email,FirstName,LastName,UserType,ClientId,SendEmail'){
    this.showSnackbar('Invalid Header Row');
    return false;
  }
  if(lines.length < 2){
    this.showSnackbar('No data rows found');
    return false;
  }
  return true;
}

async getOktaUserProfiles(file: File){
  let lines: String[] = (await file.text())
    .split(/[\r\n]+/)
    .filter(line => line.trim() !== '');
  let oupArr : OktaUserProfile[] = [];
  lines.slice(1).forEach(line => {
    let parts = line.split(',');
    if(parts.length < 6){
      throw('Insufficient data detected in row!');
    }
    oupArr.push({
      Email: parts[0],
      FirstName: parts[1],
      LastName:  parts[2],
      UserType: parts[3],
      ClientIds: parts[4],
      SendEmail: parts[5] == 'Yes' ? true : false
    });
  });
  return oupArr;
}

startBulkUserCreationJob(oktaUserProfiles : OktaUserProfile[]){
  let observables : [Observable<any>?] = [];
  let errorsMap : any = {};
  let actionResults: any;

  let reverseUserTypeMap : any = {};
  this.optionUserTypes.forEach(element => {
    reverseUserTypeMap[element.text] = element.id;
  });
  oktaUserProfiles.forEach(oup => {
    const formData = new FormData();
    formData.append('FirstName', oup.FirstName);
    formData.append('LastName', oup.LastName);
    formData.append('Email', oup.Email);
    formData.append('Login', oup.Email);
    formData.append('ClientIds', oup.ClientIds);
    formData.append('RoleId', reverseUserTypeMap[oup.UserType] ?? 'u');
    formData.append('isActive', `true`);

    const error$ = this.appService.addUser(formData, oup.SendEmail).pipe(
      catchError(err => {
        errorsMap[oup.Email] = err.message;
        return of(err.status)
      }),
    );
    observables.push(error$);
  });

  if(observables.length > 0){
    this.isLoadingResults = true;
    forkJoin(observables).subscribe({
      next: (result) => {
        actionResults = result;
      },
      error: (err: any) => { 
        this.isLoadingResults = false;
        console.log('err', err);
      },
      complete: () => { 
        this.isLoadingResults = false;
        let report = this.getBulkUserCreationReport(oktaUserProfiles, actionResults, errorsMap);
        console.log('action results', actionResults);
        console.log('errors', errorsMap);

        let emailMessage = this.makeEmailMessageFromReport(report, 'Below is the result of recent bulk users import - ', 'Bulk User Import Report');
        if(emailMessage != null)
          this.sendEmail(emailMessage);
      }
    });
  }
}

getBulkUserCreationReport(oktaUserProfiles : OktaUserProfile[], result: any, errors: any){
  let header = ['Email', 'Success', 'Reason'];
  let report = [header];

  result.forEach((row: any, index: number) => {
    let email = oktaUserProfiles[index].Email;
    let success = row != null;
    let reason = '';
    if(!success){
      reason = errors[email];
    }
    report.push([email, success ? 'Yes' : 'No', reason]);
  });

  return report;
}

// Bulk Selection and options

  selection = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.getDataLength();
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.getData().forEach(row => this.selection.select(row));
  }

  onSelectionChanged(event: any, row: any){
    this.selection.toggle(row);
  }

  sendBulkEmailsClicked(){
    let observables : [Observable<any>?] = [];
    let errorsMap : any = {};
    let actionResults: any;
    this.selection.selected.forEach(row => {
      const error$ = this.appService.sendActivationEmail(row.email, true).pipe(
        catchError(err => {
          errorsMap[row.email] = err.message;
          return of(err.status)
        }),
      );
      observables.push(error$);
    });

    if(observables.length > 0){
      this.isLoadingResults = true;
      forkJoin(observables).subscribe({
        next: (result) => {
          actionResults = result;
        },
        error: (err: any) => { 
          this.isLoadingResults = false;
          console.log('err', err);
        },
        complete: () => { 
          this.isLoadingResults = false;
          let report = this.getBulkEmailReport(this.selection.selected, actionResults, errorsMap);
          let emailMessage = this.makeEmailMessageFromReport(report, 'Below is the result of recent bulk emails sent - ', 'Bulk Email Status Report');
          if(emailMessage != null)
            this.sendEmail(emailMessage);
        }
      });
    }
  }

  getBulkEmailReport(selected: any, result: any, errors: any){
    let header = ['Email', 'Success', 'Reason'];
    let report = [header];

    result.forEach((row: any, index: number) => {
      let email = selected[index].email;
      let success = row != null;
      let reason = '';
      if(!success){
        reason = errors[email];
      }
      report.push([email, success ? 'Yes' : 'No', reason]);
    });

    return report;
  }

  // Bulk Actions Email

  makeEmailMessageFromReport(report: string[][], message: string, subject: string): EmailMessage | null{
    if(report && report.length > 1){
      let header = report[0];
      if(header && header.length > 0){
        let dataRows = [];
        for(let i= 1; i < report.length; i++){
          let dataRow = `<tr><td>${i}</td><td>${report[i].join('</td><td>')}</td></tr>`;
          dataRows.push(dataRow);
        }
        let table = `<table><tr><th>#</th><th>${header.join('</th><th>')}</th></tr>${dataRows.join('')}</table>`;
        let htmlStr = `<p>Hi ${this.loggedInUser.firstName} ${this.loggedInUser.lastName}</p>
                      <p>${message}</p>
                      <br>
                      ${table}
                      <br>
                      Regards,
                      <br>
                      Aonline Support`;
        
        let msg: EmailMessage = {
          to: [this.loggedInUser.email!],
          cc: null,
          bcc: null,
          subject: subject,
          content: htmlStr,
          isContentHtml: true
        }
        return msg;
      } 
    }
    return null;
  }

  sendEmail(emailMessage: EmailMessage){
    this.isLoadingResults = false;
    this.appService.sendEmail(emailMessage)
      .subscribe((data) => {
        this.showSnackbar("Success! Report shared on your email.");
        this.isLoadingResults = false;
      },(e) => {
        this.isLoadingResults = false;
        this.showSnackbar(e.message);
      });
  }


}


