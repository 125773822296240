import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { filter, distinctUntilChanged, debounceTime, tap, switchMap, finalize } from 'rxjs';
import { OPTION_CLIENT_TYPES } from 'src/models/Constants';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AppService } from '../app.service';
import { AuthGuard } from '../auth/authguard.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrls: ['./create-admin.component.scss']
})
export class CreateAdminComponent implements OnInit {

  loggedInUser : LoggedInUser;
  isLoading: boolean = false;

  formDirective: FormGroupDirective;
  createForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    countries: new FormControl('', [Validators.required]),
    psuperadmin: new FormControl(false, []),
    pspsite: new FormControl(false, []),
  });

  allCountries: any[];
  selectedCountries: any = [];
  // For email
  existingUser: any;

  constructor(private appService: AppService,private http: HttpClient, private authGuard: AuthGuard, private _snackBar: MatSnackBar, private location: Location) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.isLoading = false;
    this.loadCountries();
  }

  ngAfterViewInit() {
    
    this.createForm.controls['email'].valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        tap(() => {
            this.existingUser = null;
            this.createForm.controls['email'].setErrors(this.createForm.controls['email'].errors);
            if(this.createForm.controls['email'].value != ""){
              this.findExistingUser(this.createForm.controls['email'].value);
            }
        })
      )
      .subscribe();
  }

  loadCountries(){
    this.appService.getCountries(this.loggedInUser).subscribe((response: any) => {
      this.allCountries = response;
    });
  }

  findExistingUser(email: string | null){
    if(email != null){
      this.appService.getAdminByEmail(email).subscribe((response) => {    
        console.log('findExistingUser', response);
        this.existingUser = response;
        this.createForm.controls['email'].setErrors({'exists': true});
      },(e) => {
        console.log('findExistingUser', e);
      });
    }
  }

  onSubmit(formData: any, formDirective: FormGroupDirective): void {
    this.formDirective = formDirective;
    if (this.createForm.invalid) {
      return;
    }
    window.scrollTo(0, 0);

    let adminUser = {
      FirstName: this.createForm.controls['firstname'].value!,
      LastName: this.createForm.controls['lastname'].value!,
      Email: this.createForm.controls['email'].value!,
      IsSuperAdmin: this.createForm.controls['psuperadmin'].value ? this.createForm.controls['psuperadmin'].value : false,
      PCreateSpSite: this.createForm.controls['pspsite'].value ? this.createForm.controls['pspsite'].value : false,
      IsActive: true,
      countryIds: this.selectedCountries.map((selectedCountry: { id: any; }) => {
        return selectedCountry.id;
      })
    }
    
    this.createAdmin(adminUser);
  }

  
  createAdmin(adminUser: any) {
    console.log(adminUser);
    this.isLoading = true; 
    this.appService.addAdmin(adminUser)
      .subscribe((data) => {
        console.log(data);
        this.isLoading = false;
        this.resetForm();
        this.showSnackbar("Admin Added Successfully");
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
      }); 
  }

  resetForm(){
    if(this.formDirective !== null){
      this.formDirective.resetForm();
    }
    this.createForm.reset();
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  onCancel() {
    this.location.back();
  }

}
