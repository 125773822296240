<!--loader-->
<div *ngIf="isLoading">
    <div class="loaderbox">
        <div class="darkbackground-box"></div>
        <div class="spinnerbox-ic">
            <mat-spinner style="width: 55px;height:55px"></mat-spinner>
        </div>
    </div>
</div>

<div id="modalEditManagers" class="modal" style="display:none" *ngIf="claimsDiv">

    <!-- Modal content -->
    <div class="modal-content">
      
      <div class="row hdbox-fm my-2">
        <div><span class="close" (click)="closeForm()">&times;</span></div>
        <div class="col-md-8 col-sm-12 col-xs-12 hdbox-fm-grid d-flex align-items-center">
            Claim Managers Contact             
        </div>
        <div class="col-md-4 col-sm-10 col-xs-10 input-ad-box">
        <button type="button" class="btn btn-dark m-2 d-flex align-items-center justify-content-center" (click)="displaySPOCForm()">
            <span class="m-2">Add New SPOC</span>
          </button>
        </div>
    </div>
      <div>
        <form [formGroup]="claimsContactForm" #formDirective="ngForm" style="display:none;" (ngSubmit)="onClaimContactFormSubmit()" class="form-inline" id="claimManagerForm">
            <div class="row">
                <div class="col-md-3 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="example-full-width" [style.width.%]="70">
                        <mat-label>Aon SPOC Email</mat-label>
                        <input matInput formControlName="asemail" name="asemail" >
                        <div *ngIf="claimsContactForm.controls['asemail'].touched && claimsContactForm.controls['asemail'].invalid">
                            <mat-error *ngIf="claimsContactForm.controls['asemail'].errors?.['required']">This field is required</mat-error>
                        </div>                     
                    </mat-form-field>
                    </div>
                    <div class="col-md-3 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="example-full-width" [style.width.%]="70">
                        <mat-label>Aon SPOC First Name</mat-label>
                        <input matInput formControlName="asfname" name="asfname" [(ngModel)]="selectedAsFName">
                        <div *ngIf="claimsContactForm.controls['asfname'].touched && claimsContactForm.controls['asfname'].invalid">
                            <mat-error *ngIf="claimsContactForm.controls['asfname'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                    </div>
                    <div class="col-md-3 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="example-full-width" [style.width.%]="70">
                        <mat-label>Aon SPOC Last Name</mat-label>
                        <input matInput formControlName="aslname" name="aslname" [(ngModel)]="selectedAsLName">
                        <div *ngIf="(claimsContactForm.controls['aslname'].touched && claimsContactForm.controls['aslname'].invalid) ">
                            <mat-error *ngIf="claimsContactForm.controls['aslname'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                </div>
                
                    <div class="col-md-3 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="example-full-width"  >
                        <mat-label>Select Type</mat-label>
                        <mat-select formControlName="astype" name="astype" [(ngModel)]="selectedSpocType" >
                            <mat-option *ngFor="let claimManager of claimManagers" [value]="claimManager" >{{claimManager.type}}</mat-option>
                        </mat-select>
                        <div *ngIf="claimsContactForm.controls['astype'].touched && claimsContactForm.controls['astype'].invalid">
                            <mat-error *ngIf="claimsContactForm.controls['astype'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                    </div>             

                

            </div>
            <div class="col-md-2 col-sm-10 col-xs-10 input-ad-box" style="float:right">
                <button type="submit" class="btn btn-dark align-middle"  [disabled]="this.claimsContactForm.invalid ">Update Aon SPOC</button>
            </div>
            <br>
        </form>
        <div class="row" *ngIf="claimsDiv">
            <div class="col-md-12 col-sm-12 col-xs-12 txt-ad-box"><p class="h5 pb-1">Current Aon SPOC</p></div>
           
            <hr class="mt-2">
        </div>
        <table class="table table-striped mx-2" *ngIf="subgroups.length > 0">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email</th>
                <th scope="col">Contact type</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let claimManager of claimManagers; index as i">
                <th scope="row">{{claimManager.id}}</th>
                <td>{{claimManager.fName}}</td>
                <td>{{claimManager.lName}}</td>
                <td>{{claimManager.email}}</td>
                <td>{{claimManager.type}}</td>
              </tr>
            </tbody>
        </table>
        <!-- <form [formGroup]="claimsContactForm" (ngSubmit)="onClaimContactFormSubmit()" class="my-3">
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Primary SPOC First Name :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter first name</mat-label>
                        <input matInput formControlName="aspfname" name="aspfname" [(ngModel)]="selectedAspfname">
                        <div *ngIf="claimsContactForm.controls['aspfname'].touched && claimsContactForm.controls['aspfname'].invalid">
                            <mat-error *ngIf="claimsContactForm.controls['aspfname'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                    <br>
                </div>
            </div>
		  
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Primary SPOC Last Name :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field m-0 p-0">
                    <mat-label>Enter last name</mat-label>
                    <input matInput formControlName="asplname" name="asplname" [(ngModel)]="selectedAsplname">
                    <div *ngIf="claimsContactForm.controls['asplname'].touched && claimsContactForm.controls['asplname'].invalid">
                        <mat-error *ngIf="claimsContactForm.controls['asplname'].errors?.['required']">This field is required</mat-error>
                    </div>
                </mat-form-field>
                <br>
            </div>
        </div>
         
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Primary SPOC Email :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field m-0 p-0">
                    <mat-label>Enter Primary SPOC's Email</mat-label>
                    <input matInput formControlName="aspemail" name="aspemail" [(ngModel)]="selectedAspemail">
                    <div *ngIf="claimsContactForm.controls['aspemail'].touched && claimsContactForm.controls['aspemail'].invalid">
                        <mat-error *ngIf="claimsContactForm.controls['aspemail'].errors?.['required']">This field is required</mat-error>
                        <mat-error *ngIf="claimsContactForm.controls['aspemail'].errors?.['aspemail']">Invalid email address</mat-error>
                    </div>
                </mat-form-field>
                <br>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Secondary SPOC First Name :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field m-0 p-0">
                    <mat-label>Enter first name</mat-label>
                    <input matInput formControlName="assfname" name="assfname" [(ngModel)]="selectedAssfname">
                    <div *ngIf="claimsContactForm.controls['assfname'].touched && claimsContactForm.controls['assfname'].invalid">
                        <mat-error *ngIf="claimsContactForm.controls['assfname'].errors?.['required']">This field is required</mat-error>
                    </div>
                </mat-form-field>
                <br>
            </div>
        </div>
      
      <div class="row">
        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Secondary SPOC Last Name :</div>
        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
            <mat-form-field appearance="outline" class="form-field m-0 p-0">
                <mat-label>Enter last name</mat-label>
                <input matInput formControlName="asslname" name="asslname" [(ngModel)]="selectedAsslname">
                <div *ngIf="claimsContactForm.controls['asslname'].touched && claimsContactForm.controls['asslname'].invalid">
                    <mat-error *ngIf="claimsContactForm.controls['asslname'].errors?.['required']">This field is required</mat-error>
                </div>
            </mat-form-field>
            <br>
        </div>
    </div>
     
      <div class="row">
        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Secondary SPOC Email :</div>
        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
            <mat-form-field appearance="outline" class="form-field m-0 p-0">
                <mat-label>Enter Secondary SPOC's Email</mat-label>
                <input matInput formControlName="assemail" name="assemail" [(ngModel)]="selectedAssemail">
                <div *ngIf="claimsContactForm.controls['assemail'].touched && claimsContactForm.controls['assemail'].invalid">
                    <mat-error *ngIf="claimsContactForm.controls['assemail'].errors?.['required']">This field is required</mat-error>
                    <mat-error *ngIf="claimsContactForm.controls['assemail'].errors?.['assemail']">Invalid email address</mat-error>
                </div>
            </mat-form-field>
            <br>
        </div>
    </div>
     
    
          
    <div class="row mt-3">
        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"></div>
        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
            <button type="submit" class="svn-btn"  >Submit</button>
          <button (click)="closeForm()" type="button" class="cn-btn">Cancel</button>
        </div>
    </div>      
          
        </form> -->
      </div>
      
    </div>
  
  </div>

  <div id="modalCreateClaim" class="modal"  *ngIf="isClaimsToolSelectedSubgroup">

    <!-- Modal content -->
    <div class="modal-content">
      
      <div class="row hdbox-fm my-2">
        <div><span class="close" (click)="closeCreateClaimForm()">&times;</span></div>
        <div class="col-md-12 col-sm-12 col-xs-12 hdbox-fm-grid d-flex align-items-center">
            Claim Managers Contact Form            
        </div>
        
    </div>
      <div>
        <form [formGroup]="createClaimsForm" (ngSubmit)="onCreateContactFormSubmit()" class="my-3">
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Primary SPOC Email :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter Primary SPOC's Email</mat-label>
                        <input matInput formControlName="aspemail" name="aspemail" [(ngModel)]="selectedAspEmail">
                        <div *ngIf="createClaimsForm.controls['aspemail'].touched && createClaimsForm.controls['aspemail'].invalid">
                            <mat-error *ngIf="createClaimsForm.controls['aspemail'].errors?.['required']">This field is required</mat-error>
                            <mat-error *ngIf="createClaimsForm.controls['aspemail'].errors?.['aspemail']">Invalid email address</mat-error>
                        </div>
                    </mat-form-field>
                    <br>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Primary SPOC First Name :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter first name</mat-label>
                        <input matInput formControlName="aspfname" name="aspfname" [(ngModel)]="selectedAspFName">
                        <div *ngIf="createClaimsForm.controls['aspfname'].touched && createClaimsForm.controls['aspfname'].invalid">
                            <mat-error *ngIf="createClaimsForm.controls['aspfname'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                    <br>
                </div>
            </div>
		  
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Primary SPOC Last Name :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field m-0 p-0">
                    <mat-label>Enter last name</mat-label>
                    <input matInput formControlName="asplname" name="asplname" [(ngModel)]="selectedAspLName">
                    <div *ngIf="createClaimsForm.controls['asplname'].touched && createClaimsForm.controls['asplname'].invalid">
                        <mat-error *ngIf="createClaimsForm.controls['asplname'].errors?.['required']">This field is required</mat-error>
                    </div>
                </mat-form-field>
                <br>
            </div>
        </div>
         
        <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Secondary SPOC Email :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field m-0 p-0">
                    <mat-label>Enter Secondary SPOC's Email</mat-label>
                    <input matInput formControlName="assemail" name="assemail" [(ngModel)]="selectedAssEmail">
                    <div *ngIf="createClaimsForm.controls['assemail'].touched && createClaimsForm.controls['assemail'].invalid">
                        <mat-error *ngIf="createClaimsForm.controls['assemail'].errors?.['required']">This field is required</mat-error>
                        <mat-error *ngIf="createClaimsForm.controls['assemail'].errors?.['assemail']">Invalid email address</mat-error>
                    </div>
                </mat-form-field>
                <br>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Secondary SPOC First Name :</div>
            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                <mat-form-field appearance="outline" class="form-field m-0 p-0">
                    <mat-label>Enter first name</mat-label>
                    <input matInput formControlName="assfname" name="assfname" [(ngModel)]="selectedAssFName">
                    <div *ngIf="createClaimsForm.controls['assfname'].touched && createClaimsForm.controls['assfname'].invalid">
                        <mat-error *ngIf="createClaimsForm.controls['assfname'].errors?.['required']">This field is required</mat-error>
                    </div>
                </mat-form-field>
                <br>
            </div>
        </div>
      
      <div class="row">
        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Aon Claims Secondary SPOC Last Name :</div>
        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
            <mat-form-field appearance="outline" class="form-field m-0 p-0">
                <mat-label>Enter last name</mat-label>
                <input matInput formControlName="asslname" name="asslname" [(ngModel)]="selectedAssLName">
                <div *ngIf="createClaimsForm.controls['asslname'].touched && createClaimsForm.controls['asslname'].invalid">
                    <mat-error *ngIf="createClaimsForm.controls['asslname'].errors?.['required']">This field is required</mat-error>
                </div>
            </mat-form-field>
            <br>
        </div>
    </div>
     
      
     
    <div class="row">
        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>ODS Client Id :</div>
        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
            <mat-form-field appearance="outline" class="form-field m-0 p-0">
                <mat-label>Enter ODS Client Id: eg 1363340 or 1363340, 1363341, 1363342...</mat-label>
                <input matInput formControlName="odsci" name="odsci">
                <mat-hint >*eg 1363340 or 1363340, 1363341, 1363342...</mat-hint>
                <div *ngIf="createClaimsForm.controls['odsci'].touched && createClaimsForm.controls['odsci'].invalid">
                    <mat-error *ngIf="createClaimsForm.controls['odsci'].errors?.['required']">This field is required</mat-error>
                </div>
            </mat-form-field>
            <br>
        </div>
    </div>
          
    <div class="row mt-3">
        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"></div>
        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
            <button type="submit" class="svn-btn" >Submit</button>
          <button type="button" (click)="closeForm()" class="cn-btn">Cancel</button>
        </div>
    </div>      
          
        </form>
      </div>
      
    </div>
  
  </div>

<div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-3">
        <div class="row hdbox-fm my-2">
            <div class="col-md-11 col-sm-12 col-xs-12">
                Edit Client <span *ngIf="client?.clientId"> - {{ client?.name }} ({{ client?.clientId }})</span> 
            </div>
            <div class="col-md-1 col-sm-12 col-xs-12 mt-md-0 mt-sm-3 mt-xs-3">
                <img *ngIf="imagePath" src="{{imagePath}}" class="img-fluid" alt="Responsive image">
            </div>
        </div>
    
        <div class="row my-3">
            <div class="col-md-6 col-sm-12">
    
                <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
    
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Country :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field class="example-full-width" [style.width.%]="90">
                                <mat-label>Selected Country</mat-label>
                                <input matInput formControlName="country" name="country">
                            </mat-form-field>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Client Name :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                <mat-label>Enter client name</mat-label>
                                <input matInput formControlName="name" name="name">
                                <div *ngIf="editForm.controls['name'].touched && editForm.controls['name'].invalid">
                                    <mat-error *ngIf="editForm.controls['name'].errors?.['required']">This field is required</mat-error>
                                    <mat-error *ngIf="editForm.controls['name'].errors?.['exists']">Client name already exists</mat-error>
                                </div>
                            </mat-form-field>
                            <br>
                            <button type="button" class="btn btn-dark btn-sm mb-3" *ngIf="clientExists && canEditExistingClient" [routerLink]="['/clients', existingClientId, 'edit']">Edit existing client</button>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Type :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                <mat-label>Client type</mat-label>
                                <mat-select formControlName="type" name="type">
                                    <mat-option *ngFor="let type of optionClientTypes" [value]="type.value">
                                        {{type.text}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="editForm.controls['type'].touched && editForm.controls['type'].invalid">
                                    <mat-error *ngIf="editForm.controls['type'].errors?.['required']">This field is required</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Industry :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                <mat-label>Client's industry</mat-label>
                                <mat-select formControlName="industry" name="industry" [(ngModel)]="selectedIndustry">
                                    <mat-option *ngFor="let industry of allIndustries" [value]="industry">
                                        {{industry.title}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="editForm.controls['industry'].touched && editForm.controls['industry'].invalid">
                                    <mat-error *ngIf="editForm.controls['industry'].errors?.['required']">This field is required</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-3 col-sm-12 col-xs-3 txt-ad-box text-md-end">Provide Access on :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="dropdown-access" [style.width.%]="90">
                                <mat-label>Select Apps</mat-label>
                                <mat-select formControlName="tools" name="tools" [(ngModel)]="selectedDefaultTools" multiple panelClass="input-ad-box dropdown">
                                    <mat-select-trigger>
                                        {{(selectedDefaultTools.length > 0) ? selectedDefaultTools[0].title : ''}}
                                        <span *ngIf="(selectedDefaultTools.length || 0) > 1" class="example-additional-selection">
                                            (+{{(selectedDefaultTools.length || 0) - 1}} {{selectedDefaultTools.length === 2 ? 'other' : 'others'}})
                                        </span>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let tool of potentialDefaultTools" [value]="tool" [disabled]="tool.disabled">{{tool.title}}</mat-option>
                                </mat-select>
                                <mat-hint *ngIf="noTools">No tools available for selected country</mat-hint>
                                <mat-hint  *ngIf="isCollabToolSelectedDefault && !createSpSite" class="text-primary">Selecting 'Sharepoint Collaboration' tool will create a new Sharepoint collaboration site for this client.</mat-hint>
                                <mat-hint  *ngIf="!isCollabToolSelectedDefault && !collabSubgroupExists && createSpSite" class="text-primary">Removing 'Sharepoint Collaboration' tool will delete the Sharepoint collaboration site for this client.</mat-hint>
                                <mat-hint *ngIf="!noTools && !(isCollabToolSelectedDefault && !createSpSite) && !(!isCollabToolSelectedDefault && !collabSubgroupExists && createSpSite)">(Multiple choice field and its values are ACX, GAMS, Shopie, Aon Bond Link )</mat-hint>
                            </mat-form-field>
                            <div *ngIf="noTools" class="mb-3"></div>
                            <div *ngIf="!noTools" class="mb-5"></div>
                        </div>
                    </div>
                    
                    <div class="row" *ngIf="(isRealEstateToolSelectedDefault || isRequestQuoteToolSelectedDefault || realEstateExists || requestQuoteExists) && !isCollabToolSelectedDefault ">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>SP site Url :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                <mat-label>SP site Url</mat-label>
                                <input matInput formControlName="spUrl" name="spUrl" [(ngModel)]="selectedSpSiteUrl">
                                <div *ngIf="editForm.controls['spUrl'].touched && editForm.controls['spUrl'].invalid">
                                    <mat-error *ngIf="editForm.controls['spUrl'].errors?.['required']">This field is required</mat-error>
                                </div>
                            </mat-form-field>
                         </div>
                    </div>
                    <div class="row" *ngIf="claimsDiv">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>ODS Client Id :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                <mat-label>Enter ODS Client Id: eg 1363340 or 1363340, 1363341, 1363342...</mat-label>
                                <input matInput formControlName="odsci" name="odsci" [(ngModel)]="selectedOdsClientId">
                                <mat-hint >*eg 1363340 or 1363340, 1363341, 1363342...</mat-hint>
                                <div *ngIf="editForm.controls['odsci'].touched && editForm.controls['odsci'].invalid">
                                    <mat-error *ngIf="editForm.controls['odsci'].errors?.['required']">This field is required</mat-error>
                                </div>
                            </mat-form-field>
                            <br>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Upload Logo :<div class="smt-txt">Max 165x44 px, JPEG/PNG</div></div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <div>
                                <input class="form-control upload-file-btn" type="file" (change)="onFileChange($event)" [style.width.%]="90" #inputFile>
                                <mat-error *ngIf="fileErr">{{fileErr}}</mat-error>
                            </div>
                        </div>
                    </div>
    
                    <div class="row mt-3">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"></div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <button type="submit" class="svn-btn" [disabled]="this.editForm.invalid || fileErr">Submit</button>
                            <button (click)="onCancel()" type="button" class="cn-btn">Cancel</button>
                        </div>
                    </div>
    
                </form>
    
            </div>
    
            <div class="col-md-6 col-sm-12">
                
                <div class="row">
    
                    <div class="row" *ngIf="pCreateSpSite && createSpSite && spSiteUrl">
                        <div class="col-md-12 col-sm-12 col-xs-12 txt-ad-box"><p class="h5 pb-1">Sharepoint Site & Actions</p></div>
                        <div class="col-md-12 col-sm-12 col-xs-12 txt-ad-box" *ngIf="spSiteUrl">
                            <button type="button" class="btn btn-success btn-sm m-1" (click)="syncAdminsClicked()">Sync Admins</button>
                            <button type="button" class="btn btn-success btn-sm m-1" (click)="migrateUsersClicked()">Migrate Users</button>
                            <button type="button" class="btn btn-danger btn-sm m-1" (click)="cleanupUsersClicked()">Cleanup Users</button>
                        </div>
                        
                        <div class="col-md-12 col-sm-12 col-xs-12 m-2 mt-3" *ngIf="spSiteUrl">Site URL: <a href="{{spSiteGetAccessLink}}" target="_blank">{{spSiteUrl}}</a></div>
                        <hr class="mt-2">
                    </div>

                    <div class="row" *ngIf="claimsDiv">
                        <div class="col-md-12 col-sm-12 col-xs-12 txt-ad-box"><p class="h5 pb-1">Claims Tool Actions</p></div>
                        <div class="col-md-12 col-sm-12 col-xs-12 txt-ad-box" *ngIf="claimsDiv">
                            <button type="button" class="btn btn-success btn-sm m-1" (click)="editClaimsContact()">Edit Claims Contacts</button>
                        </div>
                        <hr class="mt-2">
                    </div>
    
                    <p class="h5 pb-2">Create New Subgroup</p>
                    <form [formGroup]="createSubgroupForm" #formDirective="ngForm" (ngSubmit)="onSubgroupSubmit(createSubgroupForm, formDirective)" class="form-inline">
                        <div class="row">
                            <div class="col-md-8 col-sm-10 col-xs-10 input-ad-box">
                                <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="100">
                                    <mat-label>Select Tool</mat-label>
                                    <mat-select formControlName="tool" name="tool" [(ngModel)]="selectedSubgroupTool">
                                        <mat-option *ngFor="let tool of potentialSubgroupTools" [value]="tool" [disabled]="tool.disabled">{{tool.title}}</mat-option>
                                    </mat-select>
                                    <mat-hint  *ngIf="isCollabToolSelectedSubgroup && !spSiteUrl" class="text-primary">Selecting 'Sharepoint Collaboration' tool will create a new Sharepoint collaboration site for this client.</mat-hint>
                                    <div *ngIf="createSubgroupForm.controls['tool'].touched && createSubgroupForm.controls['tool'].invalid">
                                        <mat-error *ngIf="createSubgroupForm.controls['tool'].errors?.['required']">This field is required</mat-error>
                                    </div>
                                </mat-form-field>
                            </div>
    
                            <div class="col-md-4 col-sm-10 col-xs-10 input-ad-box">
                                <button type="submit" class="btn btn-dark align-middle" [disabled]="this.createSubgroupForm.invalid">Create Subgroup</button>
                            </div>
    
                        </div>
                        <div *ngIf="isCollabToolSelectedSubgroup && !spSiteUrl" class="mb-5"></div>
                    </form>
    
                    <div class="col-md-12 col-sm-12 col-xs-12 txt-ad-box" *ngIf="subgroups.length > 0"><p class="h5 pb-1">Subgroups</p></div>
                    <table class="table table-striped mx-2" *ngIf="subgroups.length > 0">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Tool</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let subgroup of subgroups; index as i">
                            <th scope="row">{{i+1}}</th>
                            <td>{{subgroup.groupName}}</td>
                            <td>{{subgroup.title}}</td>
                            <td class="align-top text-center">
                              <button *ngIf="subgroup.canDelete" type="button" class="btn-close mt-1" aria-label="Close" (click)="deleteSubgroup(subgroup)"></button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                </div>
        
            </div>
    
        </div>
    </div>
</div>
  
<!-- The Modal -->
<div *ngIf="showModal" id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content">
        <img style="width:15%" src="/assets/images/warning.png">
        <span style="font-weight: 700;">Delete site in SharePoint</span>
      <br>
        <p>This wil destroy all the documents and subsites of this SharePoint site!</p>      
      <p>You are about to delete the site of<span style="font-weight: 700;"*ngIf="client?.clientId"> {{ client?.name }} ({{ client?.clientId }})</span></p>
      
      <p>Please confirm:</p>
      <div>
      <input type="checkbox" id="check1" name="check1" [(ngModel)]="ck1" [checked]="ck1">
      <label for="check1"> If the site contains client or Aon data please confirm you have the Archive Zip file of this place in order to send to the approriate Aon team</label>
      </div>
      <div>
      <input type="checkbox" id="check2" name="check2" [(ngModel)]="ck2" [checked]="ck2">
      <label for="check2"> You are aware that all the data in this Collaboration site will be lost and you are sure you want to do this.</label>
      </div>
      <div _ngcontent-hnj-c176="" class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
        <button *ngIf="ck1 && ck2" type="submit" class="svn-btn" (click)="onSubmitFinal()">Delete</button>
        <button  type="button" (click)="close()" class="cn-btn">Cancel</button>
    </div>
      
    </div>
  
</div>