<!--loader-->
<div *ngIf="isLoading">
    <div class="loaderbox">
        <div class="darkbackground-box"></div>
        <div class="spinnerbox-ic">
            <mat-spinner style="width: 55px;height:55px"></mat-spinner>
        </div>
    </div>
</div>
  
<div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-3">
        <div class="row hdbox-fm">
            <div class="col-md-8 col-sm-8 col-xs-8 hdbox-fm-grid d-flex align-items-center">
                Audit Trail -&nbsp; <span >{{user?.firstName}} {{user?.lastName}}  ({{ user?.username }})</span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-4 sorting-contentbox d-flex align-items-center justify-content-end">
                <button type="button" class="btn btn-dark d-flex align-items-center justify-content-center" [routerLink]="'../edit'">
                <mat-icon>edit</mat-icon>
                <span class="m-2">Edit User</span>
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-8 col-sm-12">
                <p class="h5 pb-3">User Management App</p>
                <p *ngIf="!umTrail || umTrail.length == 0" class="text-center mt-3">No Audit Trail found</p>
                
                <div *ngFor="let umLog of umTrail">
                    <span class="m-0" *ngIf="umLog.action == 'CREATE'">User Created</span>
                    <span class="m-0" *ngIf="umLog.action == 'UPDATE' && !umLog.attribute">User Updated</span>
                    <span class="m-0" *ngIf="umLog.action == 'UPDATE' && umLog.attribute == 'IsActive' && umLog.newValue == 'False'">User Deactivated</span>
                    <span class="m-0" *ngIf="umLog.action == 'UPDATE' && umLog.attribute == 'IsActive' && umLog.newValue == 'True'">User Activated</span>
                    <span class="m-0" *ngIf="umLog.action == 'EMAIL'">Email Sent</span>
                    <span class="m-0" *ngIf="umLog.action == 'RESET_AUTH'">Authenticators Reset</span>
                    <span class="m-0" *ngIf="umLog.action == 'CLIENT_ASSIGNED'">Client Assigned : {{umLog.newValue}}</span>
                    <span class="m-0" *ngIf="umLog.action == 'CLIENT_REMOVED'">Client Removed : {{umLog.newValue}}</span>
                    <span class="m-0" *ngIf="umLog.action == 'GROUPS_MODIFIED'">Sub-Groups Modified</span>
                    <span class="m-0" *ngIf="umLog.action == 'CLIENT_CONTACT_CREATED'">The user has been designated as the point of contact for client :{{umLog.newValue}}</span>
                    <span class="m-0" *ngIf="umLog.action == 'CLIENT_CONTACT_REMOVED'">The user has been removed as the point of contact for client :{{umLog.newValue}}</span>
                  <p>
                        <small> by <em>{{umLog.createdByUser.email}}</em></small>
                        <small>&nbsp;on {{umLog.createdAt+'+0000' | date: 'MMM dd yyyy, hh:mm:ss a' : loggedInUser.timezoneHrsMin}}</small>
                    </p>

                    <p *ngIf="umLog.action == 'GROUPS_MODIFIED'">
                        <small> Client - <em>{{umLog.attribute}}</em></small><br>
                        <small> Sub-Groups before change - <em>{{umLog.oldValue ? umLog.oldValue : "None"}}</em></small><br>
                        <small> Sub-Groups after change - <em>{{umLog.newValue ? umLog.newValue : "None"}}</em></small>
                    </p>
                    <hr>
                </div>
            </div>

            <div class="col-md-4 col-sm-12">
                <div class="row">
                    <p class="h5 pb-3">Aonline Portal</p>

                    <div>
                        <span class="m-0">Last Login</span>
                        <p><small>on {{user?.lastLogin+'+0000' | date: 'MMM dd yyyy, hh:mm:ss a' : loggedInUser.timezoneHrsMin}}</small></p>
                        <hr>
                    </div>
                </div>

                <div class="row">
                    <p class="h5 pb-3">Okta</p>
                    
                    <div *ngIf="oktaTrail">
                        <span class="m-0">Okta Id</span>
                        <p><small>{{oktaTrail.id}}</small></p>
                        <hr>
                    </div>
                    <div *ngIf="oktaTrail">
                        <span class="m-0">Okta Status</span>
                        <p><small>{{oktaTrail.status}}</small></p>
                        <hr>
                    </div>
                    <div *ngIf="oktaTrail">
                        <span class="m-0">Created on</span>
                        <p><small>{{(oktaTrail.created+'+0000') | date: 'MMM dd yyyy, hh:mm:ss a' : loggedInUser.timezoneHrsMin}}</small></p>
                        <hr>
                    </div>
                    <div *ngIf="oktaTrail">
                        <span class="m-0">Status changed on</span>
                        <p><small>{{(oktaTrail.statusChanged+'+0000') | date: 'MMM dd yyyy, hh:mm:ss a' : loggedInUser.timezoneHrsMin}}</small></p>
                        <hr>
                    </div>
                    <div *ngIf="oktaTrail">
                        <span class="m-0">Last updated on</span>
                        <p><small>{{(oktaTrail.lastUpdated+'+0000') | date: 'MMM dd yyyy, hh:mm:ss a' : loggedInUser.timezoneHrsMin}}</small></p>
                        <hr>
                    </div>
                    <div *ngIf="oktaTrail">
                        <span class="m-0">Password changed on</span>
                        <p><small>{{(oktaTrail.passwordChanged+'+0000') | date: 'MMM dd yyyy, hh:mm:ss a' : loggedInUser.timezoneHrsMin}}</small></p>
                        <hr>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-center">
            <small><em>&nbsp;&nbsp;&nbsp;Note: All dates & times are in <strong>{{'GMT'+loggedInUser.timezoneHrsMin}}</strong></em></small>
        </div>
    </div>
</div>
    
    
    
  