
import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';

@Component({
  selector: 'app-audit-user',
  templateUrl: './audit-user.component.html',
  styleUrls: ['./audit-user.component.scss']
})
export class AuditUserComponent {

  loggedInUser : LoggedInUser;

  isLoading = false;
  oktaTrail: any;
  umTrail: any;
  user: any;

  constructor(private authGuard: AuthGuard, private dialogRef: MatDialogRef<AuditUserComponent>, @Inject(MAT_DIALOG_DATA) public data: {oktaTrail: any, umTrail: any, user: any}) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });

    this.oktaTrail = data.oktaTrail;
    this.umTrail = data.umTrail;
    this.user = data.user;
  }

  onClose(){
    this.dialogRef.close(null);
  }
}
