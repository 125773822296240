import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, catchError, finalize, Observable, of } from "rxjs";
import { LoggedInUser } from "src/models/LoggedInUser";
import { AppService } from "../app.service";

export class SpTaskDataSource implements DataSource<any> {

    private adminsSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private maxCountSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public maxCount$ = this.maxCountSubject.asObservable();

    constructor(private appService: AppService) {}

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.adminsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.adminsSubject.complete();
        this.loadingSubject.complete();
    }

    loadSpTask(pageIndex = 0, pageSize = 10, searchTask='',  searchUser='', searchEntityId='', start='', end='',sortDirection = 'desc') {

        this.loadingSubject.next(true);
        let offset = pageIndex * pageSize;
        this.appService.getSharepointTask(pageSize, offset, searchTask, searchUser,searchEntityId, start, end, sortDirection).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(result => {
            this.adminsSubject.next(result.data);
            this.maxCountSubject.next(result.maxCount);
        });
    }    
}