<!-- <div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-3">
       
    </div>
</div> -->

<mat-drawer-container hasBackdrop="true" class="h-100">
    <mat-drawer #drawer mode="push">
        <div class="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
            <a *ngFor="let e of reports" class="{{'nav-link' + (e.id == activeReport.id ? ' active' : '')}}" data-toggle="pill" role="tab" aria-controls="v-pills-home" aria-selected="true" (click)="onclick(e.id); drawer.toggle(); (false)">{{e.name}}</a>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="d-flex align-items-center p-2">
            <button class="btn btn-secondary btn-dark d-flex align-items-center" matTooltip="Toogle" (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button> 
            <span class="mx-3 my-0 h5">{{activeReport.name}} Report</span> 
        </div>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>