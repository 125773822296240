import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { OPTION_USER_TYPES, OPTION_COUNTRIES, OPTION_LANGUAGES, OPTION_SALUTATIONS } from 'src/models/Constants';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { filter, distinctUntilChanged, debounceTime, tap, switchMap, finalize } from 'rxjs';
import { AuthGuard } from '../auth/authguard.service';
import { isDataSource } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {

  optionUserTypes: any[] = OPTION_USER_TYPES;
  optionCountries: any[] = OPTION_COUNTRIES;
  optionSalutations: any[] = OPTION_SALUTATIONS;
  optionLanguages: any[] = OPTION_LANGUAGES;

  loggedInUser : LoggedInUser;
  minLengthTerm = 2;

  id: number | null;
  user: any;
  isLoading: boolean = false;

  editUserForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    usertype: new FormControl('', [Validators.required]),
    language: new FormControl(''),
    address: new FormControl(''),
    title: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    country: new FormControl(''),
    zip: new FormControl(''),
    phone: new FormControl(''),
    salutation: new FormControl('')
  });

  @ViewChild('inputFile') fileInputElement: ElementRef;
  file: File | null = null; // Variable to store file
  fileErr: string = "";

  userClientFormDirective: FormGroupDirective;
  userClientForm = new FormGroup({
    client: new FormControl('', [Validators.required]),
    groups: new FormControl('', [Validators.required]),
    isClientContact: new FormControl(false, [Validators.required]),
  });

  isClientContact : boolean =false;

  // For Add Client selection
  filteredClients: any;
  isLoadingClients = false;
  selectedClient: any = "";

  // For Add Group Selection
  allGroups: any;
  selectedGroups: any = [];

  // For Table
  usersClients: any = [];
  usersClientToGroupsMap: any = {};

  constructor( private appService: AppService, private authGuard: AuthGuard, private http: HttpClient, private location: Location, private route: ActivatedRoute, private _snackBar: MatSnackBar ) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.id = parseInt(this.route.snapshot.paramMap.get('id')!); 
    this.findUser(this.id);
    this.getUserClientGroups(this.id)
  }


  ngAfterViewInit() {
    this.userClientForm.controls['client'].valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(250),
        tap(() => {
          this.filteredClients = [];
          this.isLoadingClients = true;
        }),
        switchMap(value => this.appService.searchClients(10, 0, value as string, '', '', '', '', false)
          .pipe(
            finalize(() => {
              this.isLoadingClients = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        if (response['data'] == undefined) {
          this.filteredClients = [];
        } else {
          this.filteredClients = response['data'];
        }
      });
  }

  onClientSelected() {
    this.selectedClient = this.selectedClient;
    this.appService.getClientGroups(this.selectedClient.id).subscribe((response) => {
      this.allGroups = response;
      this.makeGroupPreselections();
    });
    this.appService.getUserClientContact(this.selectedClient.id, this.user.id).subscribe((response) => {
      if(response !=null && response.isActive == true)
        {
          this.isClientContact =true;
        }
        else{
          this.isClientContact =false;
        }
    });
  }

  displayClientWith(value: any) {
    return value?.name;
  }

  makeGroupPreselections(){
    this.selectedGroups = this.allGroups.filter((group: { id: number, isDefault: boolean; }) => {
      return this.usersClientToGroupsMap[this.selectedClient.clientId] != null && this.usersClientToGroupsMap[this.selectedClient.clientId].map((item: { groupId: any; }) => item.groupId).includes(group.id);
    });
    if(this.selectedGroups.length == 0){
      this.selectedGroups = this.allGroups.filter((group: { isDefault: boolean; }) => group.isDefault);
    }
  }

  async findUser(id: number | null){
    if(id != null){
      this.isLoading = true;
      await this.appService.getUserById(id).subscribe((response) => {    
        this.user = response;
        this.updateAllFields();
        this.isLoading = false;
      },(e) => {
        console.log('finduser', e);
      });
      
    }
  }

  getUserClientGroups(id: number) {
    if(id != null){
      this.appService.getUserClientGroups(id).subscribe((response) => {    
        this.updateTableData(response)
      },(e) => {
        console.log('getUserClientGroups', e);
      });
    }
  }

  updateAllFields(){
    this.editUserForm.controls['email'].setValue(this.user.email);
    this.editUserForm.controls['firstname'].setValue(this.user.firstName);
    this.editUserForm.controls['lastname'].setValue(this.user.lastName);
    this.editUserForm.controls['language'].setValue(this.user.languageCode);
    this.editUserForm.controls['address'].setValue(this.user.street);
    this.editUserForm.controls['title'].setValue(this.user.title);
    this.editUserForm.controls['city'].setValue(this.user.city);
    this.editUserForm.controls['state'].setValue(this.user.state);
    this.editUserForm.controls['country'].setValue(this.user.country);
    this.editUserForm.controls['zip'].setValue(this.user.zip);
    this.editUserForm.controls['usertype'].setValue(this.user.role.id);
    this.editUserForm.controls['phone'].setValue(this.user.phone);
    this.editUserForm.controls['salutation'].setValue(this.user.salutation);

    this.appService.getOktaDetailsByEmail(this.user.email).subscribe(
      (results) => {
       if(results.aonnetId !=null)
        {
          this.editUserForm.controls['email'].disable();
          this.editUserForm.controls['firstname'].disable();
          this.editUserForm.controls['lastname'].disable();
          this.editUserForm.controls['usertype'].disable();
        }
      });
  }

  updateTableData(userClientGroups: any){
    this.usersClients = [];
    this.usersClientToGroupsMap = {};
    // Making unique clients and client to group map to display table
    var clients = userClientGroups.map((item: { cId: any; clientId: any; clientName: any; isClientContactActive: any  }) => {
      if (this.usersClientToGroupsMap[item.clientId] == null){
        this.usersClientToGroupsMap[item.clientId] = [item];
      } else {
        this.usersClientToGroupsMap[item.clientId].push(item);
      }
      return {
        id: item.cId,
        clientId: item.clientId,
        clientName: item.clientName,
        isClientContactActive: item.isClientContactActive,
        canDelete: false
      };
    });

    for(let key in this.usersClientToGroupsMap){
      this.usersClientToGroupsMap[key].sort();
      this.usersClientToGroupsMap[key].sort((a: { clientName: string; },b: { clientName: string; }) => {
        return a.clientName.localeCompare(b.clientName) // Sort Ascending 
      })
    }

    var uniqueClients: any = [];
    clients.filter(function(item: { clientId: any; }){
      var i = uniqueClients.findIndex((x: { clientId: any; }) => (x.clientId == item.clientId));
      if(i <= -1){
        uniqueClients.push(item);
      }
      return null;
    });
    this.usersClients = uniqueClients;
    this.usersClients.sort((a: { clientName: string; },b: { clientName: string; }) => {
      return a.clientName.localeCompare(b.clientName) // Sort Ascending 
    })
    // Check logged in user can delete which user client mappings to display delete button
    var idsArr: any = [];
    this.usersClients.forEach((userClient: { id: any; }) => {
      idsArr.push(userClient.id);
    });
    if(idsArr.length > 0){
      this.appService.getClientsForAdmin(idsArr.join(',')).subscribe((response) => {
        if(response.length > 0){
          var permittedIdsArr: any = [];
          response.forEach((userClient: { id: any; }) => {
            permittedIdsArr.push(userClient.id);
          });
          this.usersClients.forEach((userClient: { id: any; canDelete: boolean}) => {
            if(permittedIdsArr.includes(userClient.id)){
              userClient.canDelete = true;
            }
          });
        }
      }, e => {
        console.log('getClientsForAdmin err', e.error);
      });
    }

  }

  onEditUserSubmit(): void {
    if (this.editUserForm.invalid) {
      return;
    }
    // continue work here
    window.scrollTo(0, 0);
    const formData = new FormData();
    formData.append('Id', this.id?.toString() || '' );
    formData.append('UserName', this.user.username);
    formData.append('FirstName', this.editUserForm.controls['firstname'].value!);
    formData.append('LastName', this.editUserForm.controls['lastname'].value!);
    formData.append('Email', this.editUserForm.controls['email'].value!);
    formData.append('isActive', `true`);

    if(this.editUserForm.controls['title'].value) formData.append('Title', this.editUserForm.controls['title'].value);
    if(this.editUserForm.controls['address'].value) formData.append('Street', this.editUserForm.controls['address'].value);
    if(this.editUserForm.controls['city'].value) formData.append('City', this.editUserForm.controls['city'].value);
    if(this.editUserForm.controls['state'].value) formData.append('State', this.editUserForm.controls['state'].value);
    if(this.editUserForm.controls['country'].value) formData.append('Country', this.editUserForm.controls['country'].value);
    if(this.editUserForm.controls['zip'].value) formData.append('Zip', `${this.editUserForm.controls['zip'].value}`);
    if(this.editUserForm.controls['usertype'].value) formData.append('RoleId', this.editUserForm.controls['usertype'].value);
    if(this.editUserForm.controls['phone'].value) formData.append('Phone', `${this.editUserForm.controls['phone'].value}`);
    if(this.editUserForm.controls['language'].value) formData.append('LanguageCode', this.editUserForm.controls['language'].value);
    if(this.editUserForm.controls['salutation'].value) formData.append('Salutation', this.editUserForm.controls['salutation'].value);

    if(this.file)
      formData.append('Image', this.file, this.file?.name);
    
    this.updateUser(formData);
  }

  updateUser(formData: FormData){
    this.isLoading = true; 
    this.appService.updateOktaUserProfile(formData)
      .subscribe((data) => {
        this.showSnackbar("User Updated Successfully");
        this.isLoading = false;
        this.resetForms();
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
      });
  }

  onClientsSubmit(formData: any, formDirective: FormGroupDirective): void {
    this.userClientFormDirective = formDirective;
    let groupIdsCsv = this.selectedGroups.map((group:any) => group.id).join(",");
    this.isLoading = true;  
    this.appService.updateUserClientAndGroups(this.id!, this.selectedClient.id, groupIdsCsv, this.isClientContact )
      .subscribe((response) => {
        this.updateTableData(response)
        this.showSnackbar("Client Updated Successfully");
        this.isLoading = false;
        this.resetForms();
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
      });
  }

  deleteClientFromUser(cId: number){
    this.isLoading = true;
    this.appService.deleteUserClient(this.id!, cId)
      .subscribe((response) => {
        this.updateTableData(response);
        this.isLoading = false;
        this.resetForms();
        this.showSnackbar("Client removed!");
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
      });
  }

  resetForms(){
    if(this.userClientFormDirective != null){
      this.userClientFormDirective.resetForm();
    }
    this.userClientForm.reset();
    this.userClientForm.markAsUntouched();
    this.filteredClients = [];
    this.selectedClient = "";
    this.allGroups = null;
    this.selectedGroups = [];
    this.file = null;
    this.fileInputElement.nativeElement.value = '';
  }

  onPhotoChange(event: any) {
    this.file = null;
    this.fileErr = "";
    if(event.target.files[0] == null){
      return;
    }
    this.file = event.target.files[0];

    let type: string = this.file?.type!;
    if(type !== 'image/png' && type !== 'image/jpeg'){
      this.fileErr = "Invalid file!"
      return;
    }
    
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target?.result as string;
      image.onload = () => {
        const {
          height,
          width
        } = image;
        if (height > 160 || width > 160) {
          this.fileErr = "Height and width must not exceed 160px.";
          return false;
        }
        return true;
      };
    };
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  onCancel() {
    this.location.back();
  }

}
