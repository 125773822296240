import { Component, enableProdMode } from '@angular/core';
import { AppService } from './app.service';
import { AuthGuard } from './auth/authguard.service';
import { firstValueFrom, interval } from 'rxjs';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'UserManagement';
  public authMessage: String;
  public loggedInUser: LoggedInUser;
  public aonlineHomepageUrl: string;
  public userUpdated: boolean = false;
  public fullName: string;
  public initials: string;

  constructor(private appService: AppService, private authGuard: AuthGuard) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
      this.fullName = [this.loggedInUser?.firstName, this.loggedInUser?.lastName].filter(Boolean).join(" ");
      this.initials = this.getInitials(this.fullName);
    });
    this.authGuard.authMessageUpdated.subscribe(authMessageNew => {
      this.authMessage = authMessageNew;
    });
  }

  ngOnInit() {    
    if(environment.developemnt == false){
      enableProdMode();
    }
    this.aonlineHomepageUrl = environment.aonlineHomepageUrl;
  }

  ngAfterViewInit(){
    if(environment.developemnt == false){
      //in 10 seconds do something
      interval(300000).subscribe(async x => {
        try{
          fetch(`${environment.aonlineUrl}/alive%22`,
          {
            method: 'GET',
            mode: 'no-cors'
          })
          .then(resp => console.log('Keep Alive - ', resp));
        }
        catch (e){
          console.log('Keep Alive Error', e);
        }
      });
    }
  }

  getInitials(name: string) {
    const hasTokens = name.indexOf(' ') !== -1
    return name.substring(0, hasTokens ? 1 : 2) + (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '')
  }

}
