import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AppService } from '../app.service';
import { AuthGuard } from '../auth/authguard.service';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent {

  loggedInUser : LoggedInUser;
  minLengthTerm = 2;

  id: number | null;
  user: any;
  isLoading: boolean = false;

  // For Table
  usersClientGroupToolsRows: any = [];

  constructor( private appService: AppService, private authGuard: AuthGuard, private route: ActivatedRoute, private _snackBar: MatSnackBar ) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.id = parseInt(this.route.snapshot.paramMap.get('id')!);
    if (Number.isNaN(this.id)){
      this.showSnackbar('Invalid Id');
      return;
    }
    this.findUserDetails(this.id);
  }

  findUserDetails(id: number){
    this.isLoading = true;
    forkJoin([
      this.appService.getUserById(id),
      this.appService.getUserGroupsAndTools(id)
    ]).subscribe(
      (results) => {
        this.isLoading = false;
        this.user = results[0];
        this.onGroupsAndToolsResult(results[1]);
      },
      (error) => {
        this.isLoading = false;
        this.showSnackbar(error.message);
      }
    );
  }

  onGroupsAndToolsResult(groupsAndTools: [any]) {
    this.updateTableData(groupsAndTools);
  }

  updateTableData(groupsAndTools: any){
    groupsAndTools.sort((a: { clientName: string; },b: { clientName: string; }) => {
      return a.clientName.localeCompare(b.clientName) // Sort Ascending 
    })

    this.usersClientGroupToolsRows = [];
    let usersClientGroupToolsMap: any = {};
    // Making unique clients and client to group map to display table
    groupsAndTools.forEach((groupToolDataRow: {
      groupId: string;
      groupName: string;
      toolCode: string;
      toolName: string; 
      clientId: string;
      clientName: string;
    }) => {
      let clientId = groupToolDataRow.clientId;
      let clientName = groupToolDataRow.clientName;
      let groupId = groupToolDataRow.groupId;
      let toolCode = groupToolDataRow.toolCode;
      if(usersClientGroupToolsMap[clientId] == null){
        let grpObj: any = {};
        grpObj = {
          'groupId': groupId,
          'clientName': clientName,
          'toolCodes': [groupToolDataRow.toolCode]
        }
        usersClientGroupToolsMap[clientId] = {};
        usersClientGroupToolsMap[clientId][groupId] = grpObj;
      }else{
        let grpObj: any = usersClientGroupToolsMap[clientId][groupId];
        if(grpObj == null){
          grpObj = {
            'groupId': groupId,
            'clientName': clientName,
            'toolCodes': [groupToolDataRow.toolCode]
          };
          usersClientGroupToolsMap[clientId][groupId] = grpObj;
        }else{
          usersClientGroupToolsMap[clientId][groupId]['toolCodes'].push(toolCode);
        }
      }
    });

    let index: number = 0;
    for (const clientId in usersClientGroupToolsMap) {
      index++;
      if (usersClientGroupToolsMap.hasOwnProperty(clientId)) {
        let isFirst = true;
        for (const groupId in usersClientGroupToolsMap[clientId]) {
          if (usersClientGroupToolsMap[clientId].hasOwnProperty(groupId)) {
            let row: {
              index: number | null;
              clientId: string | null;
              clientName: string | null;
              rowSpan: number;
              groupId: string;
              toolCodes: string;
            } = {
              index: isFirst ? index : null,
              clientId: isFirst ? clientId : null,
              clientName: isFirst ? usersClientGroupToolsMap[clientId][groupId]['clientName'] : null,
              rowSpan: isFirst ? Object.keys(usersClientGroupToolsMap[clientId]).length : 1,
              groupId: usersClientGroupToolsMap[clientId][groupId]['groupId'],
              toolCodes: usersClientGroupToolsMap[clientId][groupId]['toolCodes'].sort().join(', ').split('app-').join('')
            };
            this.usersClientGroupToolsRows.push(row);
    
            isFirst = false;
          }
        }
      }      
    }
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

}
