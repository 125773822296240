import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import { AppService } from '../app.service';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
@Component({
  selector: 'app-client-subgroup',
  templateUrl: './client-subgroup.component.html',
  styleUrls: ['./client-subgroup.component.scss']
})
export class ClientSubgroupComponent implements OnInit{

  loggedInUser : LoggedInUser;
  minLengthTerm = 2;
  isLoading: boolean = false;

  subgroup:string='';
  
  clientList:any;
  toolsList:any;
  tool:any;
  adminId:number;
  adminCountries:any;
  clientsByCountries:string='';
  message: string | null = null;
  message1: string | null = null;
  clientName: string | null = null;
  
  // For Client selection
  filteredClients: any;
  isLoadingClients = false;
  selectedClient: any = "";
  selectedTool:any="";
  registerForm = new FormGroup({    
    client: new FormControl('', [Validators.required]),
    tool: new FormControl('', [Validators.required])
  });

  constructor(private appService: AppService,private http: HttpClient,private location: Location, private authGuard: AuthGuard, private _snackBar: MatSnackBar) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

   ngOnInit():void{
    //   this.appService.getClients().subscribe((response) => {
    //   this.clientList = response;
    //   console.log(this.clientList);
    // });
    
    this.hideloader();  
    this.appService.getApps(this.loggedInUser).subscribe((response) => {
      this.toolsList = response;
      console.log(this.toolsList);
    });

    // this.appService.getAdminByEmail(this.loggedInUser?.email!).subscribe((response:any) => {
    //   this.adminCountries = response.adminCountries;
    //   this.adminCountries.forEach((element:any) => {
    //     if(this.clientsByCountries=='')
    //     {
    //       this.clientsByCountries=element.country.countryCode;          
    //     }
    //     else{
    //       this.clientsByCountries=this.clientsByCountries+','+element.country.countryCode;
    //     }       
    //   });
    //   this.appService.getClientsByCountryCodes(this.clientsByCountries).subscribe((response) => {
    //     this.clientList = response;
    //     console.log(this.clientList);
    //   });
    //   console.log(this.clientsByCountries);
    // });
  }

  
  ngAfterViewInit() {
    this.registerForm.controls['client'].valueChanges
      .pipe(
        filter(res => {
          console.log(res)
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(250),
        tap(() => {
          this.filteredClients = [];
          this.isLoadingClients = true;
        }),
        switchMap(value => this.appService.searchClients(10, 0, value as string)
          .pipe(
            finalize(() => {
              this.isLoadingClients = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        if (response['data'] == undefined) {
          this.filteredClients = [];
        } else {
          this.filteredClients = response['data'];
          console.log(this.filteredClients)
        }
      });
    
  }

  displayClientWith(value: any) {
    return value?.name;
  }

  onClientSelected() {
    this.selectedClient = this.selectedClient;
    
  }

  onSubmit()
  {
    this.showloader(); 
    var body;
    var cl:any=this.registerForm.controls['client'].value;
    var tl:any=this.registerForm.controls['tool'].value; 
    body= {
      "GroupId":cl.clientId+"-"+tl.code,
      "GroupName":cl.name+" - "+tl.title,
      "ClientId":cl.clientId,
      "IsDefault":0,
      "ToolId":tl.id
    }
    body.GroupId = body.GroupId.toLowerCase().replace(' ', '-');

    this.createSubgroup(body);
  }

  onChange() {
    
    console.log(this.registerForm.controls['tool'].value!);
}

  createSubgroup(body:any)
  {

    this.appService.addSubgroup(body) .subscribe(
      (data) => {
        //this.message = 'Subgroup created successfully';
        //this.message1='Subgroup Name:-'+ data.name;
        this.showSnackbar("Subgroup created successfully!");
        console.log(data);
        this.hideloader();  
       },
      (e) => {
        //this.message ='An Error occured while Creating Subgroup, please try again after sometime';
        this.showSnackbar(e.message); 
        this.hideloader();         
      }
    ); 
    //this.client="-1";
    this.tool="-1";
  }

  hideloader() {
    (document.getElementById('loading') as HTMLInputElement).style.display = 'none' ;
  }
  showloader() {
    (document.getElementById('loading') as HTMLInputElement).style.display = 'block' ;
  }
  onCancel() {
    this.location.back();
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }
}




