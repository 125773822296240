<div class="my-5 text-center welcome-content">
  <h1 class="display-5 hd-1">Welcome {{name$}}</h1>
  <div class="col-lg-6 mx-auto dt-1-se">
    <p class="lead mb-4">
      Use AonLine User Management tools to add/modify users, clients, and tools. It also provides reports to view and filter AonLine users and Clients in user friendly way.
    </p>
  </div>
</div>

<div class="container form-links">
  <div class="row mt-3 justify-content-md-center">
    <ng-container>
      <div class="col-md-4 col-sm-4 col-xs-4 box-3-link ">
        <a href="/users/{{oupId}}/edit" class="box-link">
          <img
            src="../assets/images/ic-form-img2.png" style="width: 56px !important;"
            alt="Manage Self"
          />
          <div class="form-section-box-link">Manage Self</div>
        </a>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4 box-3-link ">
        <a href="/users{{usersFilter}}" class="box-link">
          <img
            src="../assets/images/ic-form-img.png" style="width: 56px !important;"
            alt="Manage Users"
          />
          <div class="form-section-box-link">Manage Users</div>
        </a>
      </div>
    </ng-container>
  </div>

  <div class="row mt-3 justify-content-md-center">
    <ng-container>
      <div class="col-md-4 col-sm-4 col-xs-4 box-3-link rpt-ic">
        <a href="/clients{{clientsFilter}}" class="box-link">
          <img
            src="../assets/images/client-report.png" style="width: 45px !important;"
            alt="Manage Clients"
          />
          <div class="form-section-box-link">Manage Clients</div>
        </a>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4 box-3-link rpt-ic">
        <a href="/sites" class="box-link">
          <img
            src="../assets/images/client-sites.png" style="width: 45px !important;"
            alt="Sites"
          />
          <div class="form-section-box-link">Manage Client Sites</div>
        </a>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4 box-3-link rpt-ic">
        <a href="/reports" class="box-link">
          <img
            src="../assets/images/Assign-report.png" style="width: 45px !important;"
            alt="Reports"
          />
          <div class="form-section-box-link">Reports</div>
        </a>
      </div>
    </ng-container>
  </div>

  <div class="row mt-3 justify-content-md-center">
    <ng-container *ngIf="isSuperAdmin===true">
      <div class="col-md-4 col-sm-4 col-xs-4 box-3-link ">
        <a href="/admins" class="box-link">
          <img
            src="../assets/images/ic-form-img2.png" style="width: 56px !important;"
            alt="Admin User Management"
          />
          <div class="form-section-box-link">Manage Admins</div>
        </a>
      </div>
      <div  class="col-md-4 col-sm-4 col-xs-4 box-3-link rpt-ic">
        <a href="/tools" class="box-link">
          <img
            src="../assets/images/Assign-report.png" style="width: 42px !important;"
            alt="Assign Tools to Countries"
          />
          <div class="form-section-box-link">Assign Tools to Country</div>
        </a>
      </div>
      <div  class="col-md-4 col-sm-4 col-xs-4 box-3-link rpt-ic">
        <a href="/logs" class="box-link">
          <img
            src="../assets/images/Assign-report.png" style="width: 42px !important;"
            alt="Assign Tools to Countries"
          />
          <div class="form-section-box-link">Logs</div>
        </a>
      </div>
    </ng-container>
  </div>
  
  
</div>
