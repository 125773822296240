import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { OPTION_CLIENT_TYPES } from 'src/models/Constants';
import { Router, ActivatedRoute } from '@angular/router';
import { filter, distinctUntilChanged, debounceTime, tap, switchMap, finalize } from 'rxjs';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss']
})



export class EditClientComponent implements OnInit {
  
  optionClientTypes: any[] = OPTION_CLIENT_TYPES;

  loggedInUser : LoggedInUser;
  minLengthTerm = 2;

  id: number | null;
  client: any;
  isLoading: boolean = false;

  editForm = new FormGroup({
    country: new FormControl({value: "", disabled: true}, [Validators.required, Validators.email]),
    name: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    industry: new FormControl('', [Validators.required]),
    tools: new FormControl(''),    
    odsci: new FormControl(''),
    spUrl: new FormControl(''),
  });

  claimsContactForm = new FormGroup({
    asfname: new FormControl({value: '', disabled: true}, [Validators.required]),
    aslname: new FormControl({value: '', disabled: true}, [Validators.required]),
    asemail: new FormControl('', [Validators.required, Validators.email]),
    astype: new FormControl('', [Validators.required])
  });

  createClaimsForm = new FormGroup({
    aspfname: new FormControl({value: '', disabled: true}, [Validators.required]),
    asplname: new FormControl({value: '', disabled: true}, [Validators.required]),
    aspemail: new FormControl('', [Validators.required, Validators.email]),
    assfname: new FormControl({value: '', disabled: true}, [Validators.required]),
    asslname: new FormControl({value: '', disabled: true}, [Validators.required]),
    assemail: new FormControl('', [Validators.required, Validators.email]),
    odsci: new FormControl('', [Validators.required]),
  });
  createSubgroupFormDirective: FormGroupDirective;
  createSubgroupForm = new FormGroup({
    tool: new FormControl('', [Validators.required])
  });
  
  @ViewChild('inputFile') fileInputElement: ElementRef;
  file: File | null = null; // Variable to store file
  fileErr: string = "";
  imagePath: string;

  // For Country selection
  filteredCountries: any;
  isLoadingCountries = false;
  selectedCountry: any = null;

  // For existing client navigation
  clientExists: boolean = false;
  canEditExistingClient: boolean = false;
  existingClientId: number = 0;

  //For Industries selection
  allIndustries: any = [];
  selectedIndustry: any = null;

  // For Tools Selection
  allTools: any;
  selectedDefaultTools: any = [];
  potentialDefaultTools: any = [];

  // For Clains Manager Selection
  selectedAsfname : string;
  selectedAslname : string;
  selectedAsemail : string;
  selectedSpocType : any;
  selectedOdsClientId: string;
  isClaimsToolSelectedSubgroup = false;
  subgroups: any = [];
  selectedSubgroupTool: any;
  potentialSubgroupTools: any = [];

  noTools = false;
  isCollabToolSelectedDefault = false;
  isCollabToolSelectedSubgroup = false;
  isRealEstateToolSelectedDefault = false;
  isRealEstateToolSelectedSubgroup = false;
  isRequestQuoteToolSelectedDefault = false;
  isRequestQuoteToolSelectedSubgroup = false;

  collabSubgroupExists = false;
  realEstateExists = false;
  requestQuoteExists = false;

  //Sp Site Url
  selectedSpSiteUrl:string;

  //Permissions
  pCreateSpSite: boolean = false;
  createSpSite: boolean = false;
  spSiteGetAccessLink: string | null = null;
  spSiteUrl: string | null = null;
  claimsDiv: boolean  = false;
  claimManagers: any;
  
   //For Claim Contact
   selectedAspFName:string;
   selectedAspLName:string;
   selectedAspEmail:string;
   selectedAssFName:string;
   selectedAssLName:string;
   selectedAssEmail:string;
   primaryPreLoadedValues:boolean;
   secondaryPreLoadedValues:boolean;

   //For Update Claim Contact Form
   updateCCPreLoadedValues:boolean =false;        
   selectedAsFName:string;
   selectedAsLName:string;

  constructor(private appService: AppService, private router: Router, private _snackBar: MatSnackBar, private authGuard: AuthGuard, private location: Location, private route: ActivatedRoute) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.id = parseInt(this.route.snapshot.paramMap.get('id')!); 
    this.findClient();

    this.appService.getIndustries(this.loggedInUser).subscribe((response) => {
      this.allIndustries = response;
      this.updateAllFields();
    });

    this.appService.getAdminById(this.loggedInUser.id!).subscribe((response:any) => {
      this.pCreateSpSite = response.pCreateSpSite;
    });
  }

  ngAfterViewInit() {    
    this.editForm.controls['name'].valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        tap(() => {
            this.resetClientExists(null);
            if(this.editForm.controls['name'].value === this.client.name){
              return;
            }
            this.findExistingClient(this.editForm.controls['name'].value);
        })
      )
      .subscribe();

      this.editForm.controls['tools'].valueChanges
      .subscribe((response: any) => {
          this.isCollabToolSelectedDefault = (response.filter((tool: { toolCode: string; }) => tool.toolCode == "app-collaboration")).length > 0;
          this.isRealEstateToolSelectedDefault = (response.filter((tool: { toolCode: string; }) => tool.toolCode == "app-re-collab")).length > 0;
          this.isRequestQuoteToolSelectedDefault = (response.filter((tool: { toolCode: string; }) => tool.toolCode == "app-re-request-quote")).length > 0;
          if(this.isCollabToolSelectedDefault == true || this.collabSubgroupExists){
            this.allTools.forEach((tool: { disabled: boolean; toolCode: string; }) => {
              if(tool.toolCode == "app-re-collab" || tool.toolCode == "app-re-request-quote"  ){   
                tool.disabled = true;  
                
              }else{
                tool.disabled = false;              
              }
            });
          }
          else if(this.isRealEstateToolSelectedDefault || this.isRequestQuoteToolSelectedDefault || this.realEstateExists || this.requestQuoteExists){
           this.editForm.controls["spUrl"].setValidators(Validators.required);
            this.editForm.controls["spUrl"].updateValueAndValidity();
            this.allTools.forEach((tool: { disabled: boolean; toolCode: string; }) => {
            if(tool.toolCode != "app-collaboration" ){
              tool.disabled = false;
             }else{
              tool.disabled = true;
            }
            });
          }
          else {
            this.editForm.controls["spUrl"].clearValidators();
            this.editForm.controls["spUrl"].updateValueAndValidity();
            this.allTools.forEach((tool: { disabled: boolean; toolCode: string; }) => {
              if(tool.toolCode == "app-collaboration" || tool.toolCode == "app-re-collab" || tool.toolCode == "app-re-request-quote" ){
                tool.disabled = false;
              }
            });
          }
        
      });

      this.createSubgroupForm.controls['tool'].valueChanges
      .subscribe((response: any) => {
        if(response){
          this.isCollabToolSelectedSubgroup = response.toolCode == "app-collaboration";
          this.isRealEstateToolSelectedSubgroup = response.toolCode == "app-re-collab";
          this.isRequestQuoteToolSelectedSubgroup = response.toolCode == "app-re-request-quote";
          this.isClaimsToolSelectedSubgroup = response.toolCode == "app-claims";
        }
      });
      this.claimsContactForm.controls['asemail'].valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() =>  {        
            this.claimsContactForm.controls['asemail'].setErrors(this.claimsContactForm.controls['asemail'].errors);
            if(this.updateCCPreLoadedValues)        
            {
              this.claimsContactForm.controls['asfname'].reset();
              this.claimsContactForm.controls['aslname'].reset();
            }
            if(this.claimsContactForm.controls['aslname'].value != "" && !this.claimsContactForm.controls['asemail'].invalid){
              this.findCCUserDetails(this.claimsContactForm.controls['asemail'].value);
            }
        
       })).subscribe();

      this.createClaimsForm.controls['aspemail'].valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() =>  {        
            this.createClaimsForm.controls['aspemail'].setErrors(this.createClaimsForm.controls['aspemail'].errors);
            if(this.primaryPreLoadedValues)        
            {
              this.createClaimsForm.controls['aspfname'].reset();
              this.createClaimsForm.controls['asplname'].reset();
            }
            if(this.createClaimsForm.controls['aspemail'].value != "" && !this.createClaimsForm.controls['aspemail'].invalid){
              this.findPrimaryUserDetails(this.createClaimsForm.controls['aspemail'].value);
            }
        
       })).subscribe();

       this.createClaimsForm.controls['assemail'].valueChanges
       .pipe(
         debounceTime(300),
         distinctUntilChanged(),
         tap(() =>  {
         this.createClaimsForm.controls['assemail'].setErrors(this.createClaimsForm.controls['assemail'].errors);
            if(this.secondaryPreLoadedValues)
            {
              this.createClaimsForm.controls['assfname'].reset();
              this.createClaimsForm.controls['asslname'].reset();
            }
            if(this.createClaimsForm.controls['assemail'].value != "" && !this.createClaimsForm.controls['assemail'].invalid){
              this.findSecondaryUserDetails(this.createClaimsForm.controls['assemail'].value);
            }
          })).subscribe();
  }

  findCCUserDetails(email:string | null)
  {
    if(email != null){
      this.appService.getOktaDetailsByEmail(email).subscribe(
        (results) => {
         if(results.aonnetId !=null)
          {            
            this.selectedAsFName= results.firstName;
            this.selectedAsLName = results.lastName;
            this.updateCCPreLoadedValues = true;
          }
          
        });
      }
  }
  findPrimaryUserDetails(email:string | null)
  {
    if(email != null){
    this.appService.getOktaDetailsByEmail(email).subscribe(
      (results) => {
       if(results.aonnetId !=null)
        {            
          this.selectedAspFName= results.firstName;
          this.selectedAspLName = results.lastName;
          this.primaryPreLoadedValues = true;
        }
        
      });
    }
  }
  findSecondaryUserDetails(email:string | null)
  {
    if(email != null){
      this.appService.getOktaDetailsByEmail(email).subscribe(
        (results) => {
         if(results.aonnetId !=null)
          {            
            this.selectedAssFName= results.firstName;
            this.selectedAssLName = results.lastName;
            this.secondaryPreLoadedValues = true;
          }
          
        });
    }
  }

  findClient(){
    if(this.id != null){
      this.isLoading = true;
      this.appService.getClientById(this.id).subscribe((response) => {
        this.client = response;
        this.setCountries();
        this.updateAllFields();
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
        console.log('findclient err', e.error);
      });
    }
  }

  setCountries(){
    this.appService.searchCountries(1000, 0, '', 'asc').subscribe((response:any) => {
      this.filteredCountries = response['data'];
      this.updateAllFields();
      this.selectedCountry = this.filteredCountries.filter((country: { id: any; }) => country.id === this.client.countryId)[0];
      this.setTools();
    });
  }

  setTools(){
    this.appService.searchTools(1000, 0, '', this.selectedCountry.id).subscribe((response:any) => {
      if(response['data'].length == 0){
        this.noTools = true;
      }
      this.allTools = response['data'];
      if(!this.pCreateSpSite){
        this.allTools.forEach((tool: { disabled: boolean; toolCode: string; }) => {
          if(tool.toolCode != "app-collaboration"){
            tool.disabled = false;
          }else{
            tool.disabled = true;
          }
        });
      }
      this.updateAllFields();
    });
  }

  updateAllFields(){
    if(this.client && this.allIndustries && this.allIndustries.length > 0 && this.allTools){
      this.editForm.controls['country'].setValue(`${this.selectedCountry.name} (${this.selectedCountry.code})`);
      this.editForm.controls['name'].setValue(this.client.name);
      
      this.editForm.controls['type'].setValue(this.client.type);
      var defaultTools: any = [];
      this.subgroups = [];
      this.collabSubgroupExists = false;
      this.client.tools.forEach((tool: { isDefault: boolean; toolCode: string; canDelete: boolean }) =>{
        tool.canDelete = true;
        if(tool.isDefault === true){
          defaultTools.push(tool);
        }else{
          this.subgroups.push(tool);
          if(tool.toolCode === "app-collaboration"){
            this.collabSubgroupExists = true;
            tool.canDelete = this.pCreateSpSite;
          }
          if(tool.toolCode === "app-re-collab"){
            this.realEstateExists = true;
          }
          if(tool.toolCode == "app-re-request-quote"){
            this.requestQuoteExists = true;
          }
          if(tool.toolCode === "app-claims"){
            this.claimsDiv = true;
          }
        }
      });
      this.editForm.controls['tools'].setValue(defaultTools);
      this.spSiteUrl = this.client.spSiteUrl;
      if(this.spSiteUrl){
        this.editForm.controls['spUrl'].setValue(this.spSiteUrl);
        this.selectedSpSiteUrl = this.spSiteUrl;
        }
      this.spSiteGetAccessLink = `${environment.aonlineUrl}${environment.getAccessTargetUrl}${this.client.spSiteUrl}`;
      this.createSpSite = this.client.createSpSite;
      this.selectedIndustry = this.allIndustries.filter((industry: { code: any; }) => industry.code == this.client.industry.code)[0];
          
      if(this.client.odsClientIdsCsv != null)
        {
          this.editForm.controls['odsci'].setValidators([Validators.required]);
          this.appService.getClaimManagersByClientId(this.client.id!).subscribe((response:any) => {
            this.claimManagers = response;
            console.log(response);
            if(this.claimManagers != null && this.claimManagers[0].aonlineUserId  && this.claimManagers[1].aonlineUserId)
            {
              this.fetchClaimManagers(this.claimManagers[0].aonlineUserId,this.claimManagers[1].aonlineUserId);
            }            
            this.selectedOdsClientId = this.client.odsClientIdsCsv;
          });
        }
    }
      this.isLoading = false;
      
      if(this.allTools){
        this.selectedDefaultTools = this.allTools.filter((tool: { id: any; }) => {
          return (defaultTools.map( (clientTool: { id: any; }) => clientTool.id)).includes(tool.id);
        });
        this.potentialSubgroupTools = this.allTools.filter((tool: { id: any; }) => {
          return !(this.client.tools.map( (clientTool: { id: any; }) => clientTool.id)).includes(tool.id);
        });
        this.potentialDefaultTools = this.allTools.filter((tool: { id: any; }) => {
          return !((this.subgroups.map( (clientTool: { id: any; }) => clientTool.id )).includes(tool.id) );
        });
        //remove Claims Intake tool from Default list
        this.potentialDefaultTools.splice(this.potentialDefaultTools.findIndex(function(tool:any){
          return tool.toolCode === "app-claims";
        }), 1);       
      }

      this.file = null;
      this.fileInputElement.nativeElement.value = '';
      if(this.client.imagePath != null){
        this.imagePath = this.client.imagePath;
      }

      
  }
  fetchClaimManagers(primaryContact:number, secondaryContact:number)
  {
    this.appService.getUserById(primaryContact).subscribe((response:any) => {
      this.claimManagers[0].fName=response.firstName;
      this.claimManagers[0].lName=response.lastName;
      this.claimManagers[0].email=response.email;
    });
    this.appService.getUserById(secondaryContact).subscribe((response:any) => {
      this.claimManagers[1].fName=response.firstName;
      this.claimManagers[1].lName=response.lastName;
      this.claimManagers[1].email=response.email;
    });
    
  }
 
  findExistingClient(name: string | null){
    if(name != null){
      this.appService.checkIfClientExists(name).subscribe((response) => {  
          
        this.resetClientExists(response);
      },(e) => {
        console.log('findExistingClient', e);
      });
    }
  }

  resetClientExists(response: any){
    if(response != null){
      this.clientExists = response.exists;
      this.canEditExistingClient = response.canEdit;
      this.existingClientId = response.id;
      if(this.clientExists == true){
        this.editForm.controls['name'].setErrors({'exists': true});
      }else{
        this.editForm.controls['name'].setErrors(null);
      }
    }else{
      this.clientExists = false;
      this.canEditExistingClient = false;
      this.existingClientId = 0;
      this.editForm.controls['name'].setErrors(null);
    }
    
  }

  showModal:boolean=false;
  ck1:boolean=false;
  ck2:boolean=false;

  close()
  {
    this.showModal=false;
    console.log(this.ck1,this.ck2);
  }

  onSubmit(): void {
    if(!this.isCollabToolSelectedDefault && !this.collabSubgroupExists && this.createSpSite)
    {
      this.showModal=true;
    }
    else{
      this.onSubmitFinal();
    }
  }

  onSubmitFinal()
  {
    if (this.editForm.invalid) {
      return;
    }
    window.scrollTo(0, 0);
    const formData = new FormData();
    formData.append('Id', this.client.id);
    formData.append('CountryId', this.client.countryId);
    formData.append('Name', this.editForm.controls['name'].value!);
    formData.append('SpSiteUrl', this.editForm.controls['spUrl'].value!);
    formData.append('Type', this.editForm.controls['type'].value!);
    formData.append('OdsClientIdsCsv', this.editForm.controls['odsci'].value!);   
    formData.append('IndustryId', this.selectedIndustry.id);
    formData.append('Status', 'true');
    formData.append('AccessOn', this.selectedDefaultTools.map((tool:any) => tool.id).join(","));
    
    if(this.file){
      formData.append('Image', this.file, this.file?.name);
    }
    
    this.updateClient(formData);
    this.showModal=false;
  }

  updateClient(formData: FormData) {
    this.isLoading = true; 
    this.appService.updateClient(formData)
      .subscribe((data) => {
        this.isLoading = false;
        this.showSnackbar("Client updated successfully");
        this.findClient();
      },(e) => {
        console.log(e);
        this.isLoading = false;
        this.showSnackbar(e.message);
      }); 
  }

  resetForms(){
    this.editForm.reset();
    this.selectedDefaultTools = [];
    this.file = null;
    this.noTools = false;
    this.isCollabToolSelectedDefault = false;
    
    if(this.createSubgroupFormDirective !== null){
      this.createSubgroupFormDirective.resetForm();
    }
    this.createSubgroupForm.reset();
    this.selectedSubgroupTool = null;
  }

  resetVars(){
    this.filteredCountries = [];
    this.allTools = [];
    this.selectedDefaultTools = [];
    this.potentialDefaultTools = [];
    this.potentialSubgroupTools = [];
    this.subgroups = [];
  }

  resetPage(){
    this.resetForms();
    this.resetVars();
  }

  onFileChange(event: any) {
    this.file = null;
    this.fileErr = "";
    if(event.target.files[0] == null){
      return;
    }
    this.file = event.target.files[0];
    
    let type: string = this.file?.type!;
    if(type !== 'image/png' && type !== 'image/jpeg'){
      this.fileErr = "Invalid file!"
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target?.result as string;
      image.onload = () => {
        const {
          height,
          width
        } = image;
        if (height > 44) {
          this.fileErr = "Height must not exceed 44px.";
          return false;
        }else if(width > 165){
          this.fileErr = "Width must not exceed 165px.";
          return false;
        }
        return true;
      };
    };
  }

  syncAdminsClicked(){
    this.isLoading = true;
    console.log('client', this.client);
    let sharepointTask = this.getNewSharepointTask(this.client.country.code, 'SYNC_ADMINS');
    this.appService.addSharepointTask(sharepointTask).subscribe((response:any) => {
      this.isLoading = false;
      this.showSnackbar('Admin sync scheduled');
    },
    (e) => {
      this.isLoading = false;
      this.showSnackbar(e.message);
    });
  }

  migrateUsersClicked(){
    this.isLoading = true;
    let sharepointTask = this.getNewSharepointTask(this.client.clientId, 'SPSITE_MIGRATE');
    this.appService.addSharepointTask(sharepointTask).subscribe((response:any) => {
      this.isLoading = false;
      this.showSnackbar('Migration scheduled');
    },
    (e) => {
      this.isLoading = false;
      this.showSnackbar(e.message);
    });
  }

  cleanupUsersClicked(){
    this.isLoading = true;
    let sharepointTask = this.getNewSharepointTask(this.client.clientId, 'SPSITE_CLEANUP');
    this.appService.addSharepointTask(sharepointTask).subscribe((response:any) => {
      this.isLoading = false;
      this.showSnackbar('Cleanup scheduled');
    },
    (e) => {
      this.isLoading = false;
      this.showSnackbar(e.message);
    });
  }

  getNewSharepointTask(entityId: string, taskCode: string){
    return {
      EntityId: entityId,
      TaskCode: taskCode,
      Status: 'NEW',
      CreatedBy: this.loggedInUser.id
    }
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  onCancel() {
    this.location.back();
  }
  
  onSubgroupSubmit(formData: any, formDirective: FormGroupDirective): void {
    if ((this.isClaimsToolSelectedSubgroup) && (this.createClaimsForm.controls['aspfname'].value ==null ||
      this.createClaimsForm.controls['asplname'].value ==null  ||
      this.createClaimsForm.controls['asslname'].value ==null  ||
      this.createClaimsForm.controls['assfname'].value ==null   
      )){

      (<HTMLInputElement>document.getElementById("modalCreateClaim")).style.display = "block"; 
        this.showSnackbar("Please enter a valid Aon SPOC email");
      return;
    }
    if(this.isClaimsToolSelectedSubgroup && this.createClaimsForm.invalid)
      {
        (<HTMLInputElement>document.getElementById("modalCreateClaim")).style.display = "block"; 
        this.showSnackbar("Please Fill the Aon SPOC form");
      return;
      }
    this.createSubgroupFormDirective = formDirective;
    this.isLoading = true;
    let subGroup = {
      "Code":this.client.clientId+"-"+this.selectedSubgroupTool.toolCode,
      "Name":this.client.name+" - "+this.selectedSubgroupTool.title,
      "ClientId":this.client.id,
      "ToolId": this.selectedSubgroupTool.id,
      "OdsClientIdsCsv": this.createClaimsForm.controls['odsci'].value,   
      "ASPFName": this.createClaimsForm.controls['aspfname'].value,
      "ASPLName": this.createClaimsForm.controls['asplname'].value,
      "ASPEmail": this.createClaimsForm.controls['aspemail'].value,
      "ASSFName": this.createClaimsForm.controls['assfname'].value,
      "ASSLName": this.createClaimsForm.controls['asslname'].value,
      "ASSEmail": this.createClaimsForm.controls['assemail'].value        
    }
    this.appService.addSubgroup(subGroup)
      .subscribe((data) => {
        this.isLoading = false;
        this.showSnackbar("Subgroup created successfully");
        this.resetPage();
        this.findClient();
      },(e) => {
        console.log(e);
        this.isLoading = false;
        this.showSnackbar(e.message);
      });
  }

  deleteSubgroup(subgroup: any): void {
    this.isLoading = true;
    this.appService.getSubgroupMemberCount(subgroup.gId)
      .subscribe((data) => {
        if(confirm(`This subgroup has ${data > 0 ? data : 'no'} member${data == 1 ? '' : 's'}. ${subgroup.toolCode == "app-collaboration" ? 'Deletion will also delete the Sharepoint collaboration site for this client.' : ''} Confirm deletion?`)) {
          this.appService.deleteSubgroup(subgroup.gId)
          .subscribe((data) => {
            this.isLoading = false;
            this.showSnackbar("Subgroup deleted successfully");
            if(subgroup.toolCode == "app-claims" )
              {
                this.claimsDiv =false;
              }
            this.resetVars();
            this.findClient();
          },(e) => {
            console.log(e);
            this.isLoading = false;
            this.showSnackbar(e.message);
          });
        }else{
          this.isLoading = false;
        }
      },(e) => {
        console.log(e);
        this.isLoading = false;
        this.showSnackbar(e.message);
      });
  }

  editClaimsContact()
  {
    this.openForm();
  }
  
  onClaimContactFormSubmit()
  {
    if (this.claimsContactForm.controls['asfname'].value == null|| this.claimsContactForm.controls['aslname'].value == null){

        this.showSnackbar("Please enter a valid Aon SPOC email");
      return;
    }
    
    this.isLoading = true;
    const claimManagerFormData = new FormData();
    claimManagerFormData.append('Id', this.selectedSpocType.id); 
    claimManagerFormData.append('ASFName', this.claimsContactForm.controls['asfname'].value!);
    claimManagerFormData.append('ASLName', this.claimsContactForm.controls['aslname'].value!);
    claimManagerFormData.append('ASEmail', this.claimsContactForm.controls['asemail'].value!);
    claimManagerFormData.append('Type', this.selectedSpocType.type);
    claimManagerFormData.append('ClientId', this.client.id);
    claimManagerFormData.append('ClaimSubgroup', this.claimManagers[0].claimSubgroup);
    this.updateClaimManager(claimManagerFormData);      
  }

  async updateClaimManager(ClaimManagerFormData : FormData)
  {
    await this.appService.updateClaimManagers(ClaimManagerFormData)
    .subscribe((data) => {
      this.isLoading = false;
      this.showSnackbar("Contact updates successfully");
      this.resetClaimManagerForm();
      this.updateClaimManagersTable();
      (<HTMLInputElement>document.getElementById("claimManagerForm")).style.display = "none";
    },(e) => {
      console.log(e);
      this.isLoading = false;
      this.showSnackbar(e.message);
    }); 
    
    
  }
  updateClaimManagersTable()
  {
    this.appService.getClaimManagersByClientId(this.client.id!).subscribe((response:any) => {
      this.claimManagers = response;
      console.log(response);
      if(this.claimManagers != null && this.claimManagers[0].aonlineUserId  && this.claimManagers[1].aonlineUserId)
      {
        this.fetchClaimManagers(this.claimManagers[0].aonlineUserId,this.claimManagers[1].aonlineUserId);
      }            
    });
  }
  resetClaimManagerForm()
  {
    this.claimsContactForm.reset();
  }
  openForm() {
    (<HTMLInputElement>document.getElementById("modalEditManagers")).style.display = "block";
  }
  displaySPOCForm() {
    (<HTMLInputElement>document.getElementById("claimManagerForm")).style.display = "block";
  }
  closeForm() {
    (<HTMLInputElement>document.getElementById("modalEditManagers")).style.display = "none";
  }
  closeCreateClaimForm()
  {
    (<HTMLInputElement>document.getElementById("modalCreateClaim")).style.display = "none"; 
  }

  onCreateContactFormSubmit()
  {
    if ((this.isClaimsToolSelectedSubgroup) && (this.createClaimsForm.controls['aspfname'].value ==null ||
      this.createClaimsForm.controls['asplname'].value ==null  ||
      this.createClaimsForm.controls['asslname'].value ==null  ||
      this.createClaimsForm.controls['assfname'].value ==null   
      )){

      (<HTMLInputElement>document.getElementById("modalCreateClaim")).style.display = "block"; 
        this.showSnackbar("Please enter a valid Aon SPOC email");
      return;
    }
    if (this.createClaimsForm.invalid) {
      return;
    }
    
    (<HTMLInputElement>document.getElementById("modalCreateClaim")).style.display = "none";

  }
}
