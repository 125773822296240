<!--loader-->
<div id="loading" *ngIf="isLoadingResults || (dataSource.loading$ | async)">
  <div class="loaderbox">
      <div class="darkbackground-box"></div>      
      <div class="spinnerbox-ic">
          <mat-spinner style="width: 55px;height:55px"></mat-spinner>
      </div>      
      </div>
</div>

<div class="jumbotron paral paralsec py-5">
  <div class="container card px-4 py-1">
    <div class="row my-2">
      <div class="col-md-4 col-sm-4 col-xs-4 hdbox-fm-grid d-flex align-items-center">
        Users List
      </div>
      <div *ngIf="selection.selected.length == 0" class="col-md-8 col-sm-8 col-xs-8 sorting-contentbox d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-dark m-2 d-flex align-items-center justify-content-center" [routerLink]="['/users/new']">
          <mat-icon>add_circle_outline</mat-icon>
          <span class="m-2">Add User</span>
        </button>
        <button type="button" class="btn btn-dark m-2 d-flex align-items-center justify-content-center" (click)="openFileSelector()">
          <mat-icon>upload</mat-icon>
          <span class="m-2">Bulk Upload</span>
        </button>
        <button type="button" class="btn btn-dark m-2 d-flex align-items-center justify-content-center" (click)="exportForm()">
          <mat-icon>download</mat-icon>
          <span class="m-2">Export CSV</span>
        </button>
        <mat-form-field appearance="outline" class="form-field mx-2" subscriptSizing="dynamic">
          <mat-label>Search by Name / Email</mat-label>
          <input matInput name="name" placeholder="Ex. Gaurav" #input>
          <button *ngIf="input.value.length > 0" mat-icon-button aria-label="Clear" matSuffix (click)="onClearSearch($event)">
            <mat-icon>close</mat-icon>
          </button>
          <mat-icon *ngIf="input.value.length == 0" matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    
    <div *ngIf="selection.selected.length == 0" class="mt-2">
      <form [formGroup]="filterForm" >
        <mat-form-field class="example-full-width form-field" appearance='outline'>
          <mat-label>Filter by Client</mat-label>
          <input matInput placeholder="Select Client" [(ngModel)]="selectedClient" [matAutocomplete]="auto"
              formControlName="client" name="client" placeholder="Min 2 characters to load">
          <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onClientSelected()"
            [displayWith]="displayClientWith">
            <mat-option *ngIf="isLoadingClients" class="is-loading">Loading...</mat-option>
            <ng-container *ngIf="!isLoadingClients">
              <mat-option *ngFor="let client of filteredClients" [value]="client">
                <span>{{client.name}} ({{client.clientId}})</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-field m-0 p-0">
          <mat-label>Filter by Subgroup</mat-label>
          <mat-select formControlName="subgroup" name="subgroup" [(ngModel)]="selectedSubgroup" (selectionChange)="onFilterChanged()">
              <mat-option *ngFor="let subgroup of clientSubgroups" [value]="subgroup">
                  {{subgroup.name}}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-field m-0 p-0 mff-filter-small">
          <mat-label>User Type</mat-label>
          <mat-select formControlName="usertype" name="usertype" [(ngModel)]="selectedUserType" (selectionChange)="onFilterChanged()">
              <ng-container *ngFor="let userType of optionUserTypes" >
                  <mat-option *ngIf="userType.status==1" [value]="userType.value">
                      {{userType.text}}
                  </mat-option>
                </ng-container>                        
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-field m-0 p-0 mff-filter-small">
          <mat-label>Country</mat-label>
          <mat-select formControlName="country" name="country" [(ngModel)]="selectedCountry" (selectionChange)="onFilterChanged()">
              <mat-option *ngFor="let country of optionCountries" [value]="country.value">
                  {{country.text}}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-field m-0 p-0 mff-filter-small">
          <mat-label>Active / Inactive</mat-label>
          <mat-select formControlName="isactive" name="isactive" [(ngModel)]="selectedIsActive" (selectionChange)="onFilterChanged()">
              <mat-option [value]="true">Active</mat-option>
              <mat-option [value]="false">Inactive</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-field m-0 p-0 mff-filter-small">
          <mat-label>Email Sent</mat-label>
          <mat-select formControlName="emailSent" name="emailSent" [(ngModel)]="selectedEmailSent" (selectionChange)="onFilterChanged()">
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
        <button *ngIf="true" type="button" class="btn btn-sm btn-close mx-2" aria-label="Close" (click)="onClearFilters()"></button>
      </form>
    
    </div>
    
    <div *ngIf="selection.selected.length > 0" class="my-3">
      <div class="row my-2">
        <div class="col-md-4 col-sm-4 col-xs-4">
          <p class="h5">Bulk Actions</p>
          <span>Selected {{selection.selected.length}} of  {{dataSource.maxCount$ | async}}</span>
        </div>
        <div class="col-md-8 col-sm-8 col-xs-8 sorting-contentbox d-flex align-items-center justify-content-end">
          <button type="button" class="btn btn-dark d-flex align-items-center justify-content-center" (click)="sendBulkEmailsClicked()">
            <mat-icon>send</mat-icon>
            <span class="m-2">Send Activation Emails</span>
          </button>
        </div>
      </div>
    </div>
    
    <div class="mat-elevation-z8 data-row-columm mb-3" >
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows matSort matSortActive="receivedParams.sortBy" matSortDirection="asc" matSortDisableClear>
    
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="onSelectionChanged($event, row)"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        
        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th class="mat-column-id" mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td class="mat-column-id" mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let row"> {{row.firstName}} {{row.lastName}} </td>
        </ng-container>
    
        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let row"> {{row.email}} </td>
        </ng-container>
    
        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
          <td mat-cell *matCellDef="let row"> 
            {{getUserTypeName(row.roleCode) + ' (' + row.roleCode + ')'}} 
              </td>
        </ng-container>
        
        <!-- Country Column -->
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
          <td mat-cell *matCellDef="let row"> 
            {{row.country | uppercase}} 
              </td>
        </ng-container>
    
        <!-- Country Column -->
        <ng-container matColumnDef="lastLogin">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Last Login (GMT'+loggedInUser.timezoneHrsMin+')'}} </th>
          <td mat-cell *matCellDef="let row"> 
            {{(row.lastLogin+'+0000') | date: 'MMM dd yyyy, hh:mm:ss a' : loggedInUser.timezoneHrsMin}} 
              </td>
        </ng-container>
    
        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 
            <mat-icon>person_add_disabled</mat-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.isActive" class="text-success">●</span>
            <span *ngIf="!row.isActive" class="text-danger">●</span>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="emailSent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 
            <mat-icon>email</mat-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="!row.activationEmailSent" class="text-danger">●</span> 
            <span *ngIf="row.activationEmailSent" class="text-success">●</span>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="ac-de">
          <th class="mat-column-actions" mat-header-cell *matHeaderCellDef> Actions </th>
          <td class="mat-column-actions" mat-cell *matCellDef="let row">
            <div class="btn-group btn-group-sm" role="group" aria-label="Actions">
              <button class="btn btn-secondary btn-dark d-flex align-items-center" [id]="row.id" [routerLink]="[row.id]" matTooltip="View"><mat-icon>visibility</mat-icon></button>
              <div class="alert alert-dark m-0 px-0"></div>
              <button class="btn btn-secondary btn-dark d-flex align-items-center" [id]="row.id" (click)="openUser(row.id)" matTooltip="Edit"><mat-icon>edit</mat-icon></button>
              <div class="alert alert-dark m-0 px-0"></div>
              <button class="btn btn-secondary btn-danger d-flex align-items-center" *ngIf="row.isActive == true" (click)="toggleStatus(row)" matTooltip="Deactivate"><mat-icon>person_off</mat-icon></button>
              <button class="btn btn-secondary btn-dark d-flex align-items-center" *ngIf="row.isActive == false" (click)="toggleStatus(row)" matTooltip="Activate"><mat-icon>how_to_reg</mat-icon></button>
            </div>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
          <td mat-cell *matCellDef="let row">
            <button aria-label="expand row" (click)="toggleExpandClicked($event, row)">
              <mat-icon *ngIf="expandedElement !== row">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="expandedElement === row">keyboard_arrow_up</mat-icon>
            </button>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row" [attr.colspan]="displayWithExpand.length">
            <div class="row-detail-inner-container"
                [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
              <div class="d-flex justify-content-center align-items-center mat-spinner-container">
                <img *ngIf="row.imageUrl" src="{{row.imageUrl}}" alt="..." class="rounded-circle row-detail-image m-2" (load)="$any($event.target).classList.add('image-loaded')">
                <mat-spinner *ngIf="row.imageUrl" class="mat-spinner-small spinner-dark"></mat-spinner>
              </div>
              <div *ngIf="!row.imageUrl" class="rounded-circle row-detail-image m-2 d-flex align-items-center justify-content-center">{{row.initials}}</div>
              <div class="row w-100 m-2">
                <div class="col-md-4">
                  <label *ngIf="row.username">Username: &nbsp;</label>{{row.username}}<br *ngIf="row.username">
                  <label *ngIf="row.addressText">Address: &nbsp;</label>{{row.addressText}}<br *ngIf="row.addressText">
                  <label *ngIf="row.country">Country: &nbsp;</label>{{row.countryView}}<br *ngIf="row.country">
                  <label *ngIf="row.phone">Phone: &nbsp;</label>{{row.phone}}<br *ngIf="row.phone">
                  <label *ngIf="row.languageCode">Language: &nbsp;</label>{{row.languageView}}<br *ngIf="row.languageCode">
                  <label *ngIf="row.salutation">Salutation: &nbsp;</label>{{row.salutation}}
                </div>
                <div class="col-md-8">
                  <div class="col-md-12" *ngIf="row.userClientsStr">
                    <label>Clients: &nbsp;</label>{{ row.userClientsStr }}<span *ngIf="row.isTruncatedUserClients"> ... &nbsp;</span><a href="/users/{{row.id}}">&nbsp; View details </a><br>
                  </div>
                  <div class="col-md-12">
                    <mat-spinner *ngIf="row.isLoadingClientsOrTools" style="width: 16px;height:16px;" class="spinner-dark"></mat-spinner>
                  </div>
                  <label *ngIf="row.status && row.emailSender">Okta Status: &nbsp;{{row.status}} 
                    <span *ngIf="row.status == 'PROVISIONED'">(Activation email was </span>
                    <span *ngIf="row.status == 'PROVISIONED' && !row.activationEmailSent">not </span> 
                    <span *ngIf="row.status == 'PROVISIONED'">sent)</span>
                  </label>
                  <br *ngIf="row.status">
                  <div>
                    <button type="button" class="btn btn-dark btn-sm m-2" [id]="row.id" (click)="refreshStatus(row)" *ngIf="row.emailSender">Refresh Okta Status</button>
                    <button type="button" class="btn btn-dark btn-sm m-2" *ngIf="row.isActive && row.status == 'PROVISIONED' && row.emailSender" (click)="sendActivationEmail(row)">Send Activation Email</button>
                    <button type="button" class="btn btn-dark btn-sm m-2" *ngIf="row.isActive && row.status == 'ACTIVE' && row.emailSender " (click)="resetOktaAuthenticators(row)">Reset Authenticators</button>
                    <button type="button" class="btn btn-dark btn-sm m-2"  (click)="openAuditDialog(row)">Audit Trail</button>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayWithExpand"></tr>
        <tr mat-row *matRowDef="let row; columns: displayWithExpand;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === row"
          (click)="expandedElement = expandedElement === row ? null : row"></tr>
        
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filters</td>
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    
      <div [hidden]="selection.selected.length > 0">
        <mat-paginator [length]="dataSource.maxCount$ | async" [pageSize]="10" [pageSizeOptions]="[10, 50, 100, 500]" aria-label="Select page of dataSource"></mat-paginator>
      </div>
    </div>
  </div>
</div>