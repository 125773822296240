<!--loader-->
<div id="loading" *ngIf="isLoadingResults || (dataSource.loading$ | async)">
    <div class="loaderbox">
      <div class="darkbackground-box"></div>      
      <div class="spinnerbox-ic">
          <mat-spinner style="width: 55px;height:55px"></mat-spinner>
      </div>     
    </div> 
  </div>
  
  <!-- Parallax Section -->
  <div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-1">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <div class="d-flex col-md-9 col-sm-12 col-xs-12 pt-4">
          <form [formGroup]="filterForm" >
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Search by name</mat-label>
              <input matInput formControlName="name" name="name" placeholder="Ex. Gaurav" #input>
            </mat-form-field>
            <mat-form-field class="example-full-width form-field" appearance='outline'>
              <mat-label>Filter by Country</mat-label>
              <input matInput placeholder="Select Country" [(ngModel)]="selectedCountry" [matAutocomplete]="auto"
                  formControlName="country" name="country" placeholder="Country code">
              <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onCountrySelected()"
                [displayWith]="displayCountryWith">
                <mat-option *ngIf="isLoadingCountries" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!isLoadingCountries">
                  <mat-option *ngFor="let country of filteredCountries" [value]="country">
                    <span>{{country.code}}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
            <button href="#" class="btn btn-sm" (click)="onClearFilters()">Clear filters</button>
          </form>
        </div>
    
        <div class="col-md-3 col-sm-12 col-xs-12 box-n">
          <div class="d-flex flex-row-reverse bd-highlight" *ngIf="loggedInUser">
            <button type="button" class="btn btn-dark m-2 d-flex align-items-center justify-content-center exprtbtn" (click)="exportForm()">
              <mat-icon>download</mat-icon>
              <span class="m-2">Export CSV</span>
            </button>
          </div>
        </div>
      </div>
    
      <div class="mat-elevation-z8 data-row-columm mb-3" >
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows matSort matSortActive="id" matSortDirection="asc" matSortDisableClear>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let row"> {{row.firstName}} {{row.lastName}} </td>
          </ng-container>
      
          <!-- Email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let row"> {{row.email}} </td>
          </ng-container>
    
          <!-- SuperAdmin Column -->
          <ng-container matColumnDef="super">
            <th mat-header-cell *matHeaderCellDef> SuperAdmin </th>
            <td mat-cell *matCellDef="let row"> {{row.isSuperAdmin ? 'Yes' : 'No'}} </td>
          </ng-container>
    
          <!-- Sharepoint Site Column -->
          <ng-container matColumnDef="spsite">
            <th mat-header-cell *matHeaderCellDef> SP Access </th>
            <td mat-cell *matCellDef="let row"> {{row.pCreateSpSite ? 'Yes' : 'No'}} </td>
          </ng-container>
    
          <!-- IsActive Column -->
          <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef> Enabled </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.isActive" class="text-success">●</span>
              <span *ngIf="!row.isActive" class="text-danger">●</span>
            </td>
          </ng-container>
    
          <!-- Country Column-->
          <ng-container matColumnDef="countries">
            <th mat-header-cell *matHeaderCellDef> Country </th>
            <td mat-cell *matCellDef="let row" id="countryTd">
                {{getCountries(row)}} 
            </td>
          </ng-container> 

          
          
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filters</td>
          </tr>
        </table>
      
        <mat-paginator [length]="dataSource.maxCount$ | async" [pageSize]="10" [pageSizeOptions]="[10, 50, 100, 500]" aria-label="Select page of dataSource"></mat-paginator>
      </div>
    
    
    </div>
  </div>
  