import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lazyusercreation',
  templateUrl: './lazyusercreation.component.html',
  styleUrls: ['./lazyusercreation.component.scss']
})

export class LazyusercreationComponent implements OnInit {

  public opRef: string = "";
  public opSite: string = "";
  public opTarget: string = "";

  successMessage:string | null=null;
  errorMessage:string | null=null;
  isLoading: boolean = false;

  uname: string = '';
  fname: string = '';
  lname: string = '';
  email: string = '';
  client: string = '';
  clientId: string = '';

  constructor(
    private router : Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private authGuard: AuthGuard
  ) {
  }

  ngOnInit() {
    this.opRef = this.route.snapshot.queryParamMap.get('op_ref') ?? "";
    this.opSite = this.route.snapshot.queryParamMap.get('op_site') ?? "";
    this.opTarget = this.route.snapshot.queryParamMap.get('op_target') ?? "";

    this.isLoading = true;
    this.appService.getLazyUserCreationRedirection(this.opRef, this.opSite, this.opTarget).subscribe((response:any) => {
      this.isLoading = false;
      if(response.redirectType == 1){
        // window.open(response.redirectUrl, "_blank");
        window.location.replace(`${environment.aonlineUrl}/GetAccess/SamlPortalTransferAgent?TARGET=${response.redirectUrl}`);
      }else{
        if(response.acxUser == null){
          this.errorMessage = response.message;
        }else{
          //Show filled form
          this.errorMessage = "";
          this.uname = response.acxUser.userId;
          this.fname = response.acxUser.firstName;
          this.lname = response.acxUser.lastName;
          this.email = response.acxUser.emailAddress;
          this.client = response.client.name;
          this.clientId = response.client.clientId;
        }
      }
    },
    (e) => {
      console.log(e);
      this.errorMessage = e.message;
      this.successMessage = null;
      this.isLoading = false;
    });
  }

  submitForm() { 
    this.isLoading = true; 
    window.scrollTo(0, 0);
    
    const formData = new FormData();
    formData.append('FirstName', this.fname);
    formData.append('LastName', this.lname);
    formData.append('Email', this.email);
    formData.append('Login', this.email);
    formData.append('Client', this.client);
    formData.append('ClientIds', this.clientId);
    formData.append('Street', 'Default');
    formData.append('IsActive', `true`);
    formData.append('RoleId', `9`);

    formData.append('CreatedByUserId', `1`);
    console.log(formData)

    this.appService.addLazyUser(formData, this.opRef, this.opSite, this.opTarget)
      .subscribe((data) => {
        console.log(data);
        this.successMessage="User created successfully. Please check your mail to complete your onboarding. You'll be able to access the document after your onboarding is complete.";
        this.errorMessage=null;
        this.isLoading = false; 
      },(e) => {
        console.log(e);
        this.errorMessage="An Error occured while Creating user - " + e.message;
        this.successMessage=null;
        this.isLoading = false;
      });
  }

}