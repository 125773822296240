import {AfterViewInit, Component, ViewChild,OnInit, inject, ElementRef} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {fromEvent, merge, Observable, of as observableOf} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import { LogsDataSource } from './logs-data-source';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { MatDialog } from '@angular/material/dialog';
import { AuditUserComponent } from '../audit-user/audit-user.component';
import { environment } from 'src/environments/environment';
import { OPTION_USER_TYPES, OPTION_COUNTRIES, OPTION_SALUTATIONS, OPTION_LANGUAGES } from 'src/models/Constants';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})

export class LogsComponent implements OnInit, AfterViewInit {

  selected="";
  loggedInUser : LoggedInUser;
  minLengthTerm = 2;

  dataSource: LogsDataSource;
  displayedColumns: string[] = ['id', 'value','msg', 'created'];
  displayWithExpand = [...this.displayedColumns, 'expand'];
  message: string | null = null;
  _data: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;
  @ViewChild('select') select: ElementRef;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  
  filterForm = new FormGroup({
    log: new FormControl(''),
    logVal: new FormControl(''),
  });
  
  
  constructor(private router: Router,public dialog: MatDialog,private appService: AppService, private authGuard: AuthGuard, private _snackBar: MatSnackBar) {   
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.isLoadingResults = false;   
    this.dataSource = new LogsDataSource(this.appService);
    this.dataSource.loadLogs(0);
    console.log(this.dataSource)
  }

  body:any;
  expandedElement:any | null;

  ngAfterViewInit() {
    // server-side search
    fromEvent(this.input.nativeElement,'keyup')
    .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
            this.paginator.pageIndex = 0;
            this.loadLogsPage();
        })
    )
    .subscribe();
    
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        
    merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadLogsPage())
        )
        .subscribe();

    //Listen to logs selection changes
   
  }
 
  loadLogsPage(): void {
    this.dataSource.loadLogs(
    this.paginator.pageIndex,
    this.paginator.pageSize,
    this.input.nativeElement.value,
    this.select.nativeElement.value,
    this.sort.direction);
  }

  logValChange()
  {    
    this.loadLogsPage();
  }
  getValue(value: any){
    var val = JSON.parse(value);
    return val;
  }
  getMsg(value: any){
    var val = JSON.parse(value).Message;
    var msg= val.substring(0,80);

    return msg;
  }
  toggleExpandClicked(event: any, row: any){
   
    this.expandedElement = this.expandedElement === row ? null : row
    event.stopPropagation();

  }

  

  onClearFilters(){
    this.filterForm.reset();
    this.paginator.pageIndex = 0;
    this.loadLogsPage();
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }
  

}


