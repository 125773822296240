<!--loader-->
<div id="loading" *ngIf="isLoadingResults || (dataSource.loading$ | async)">
  <div class="loaderbox">
      <div class="darkbackground-box"></div>      
      <div class="spinnerbox-ic">
          <mat-spinner style="width: 55px;height:55px"></mat-spinner>
      </div>      
      </div>
</div>

<div class="jumbotron paral paralsec py-5">
  <div class="container card px-4 py-1">
    <div class="row my-2">
      <div class="col-md-4 col-sm-4 col-xs-4 hdbox-fm-grid d-flex align-items-center">
        Client Sites List
      </div>
      <div *ngIf="selection.selected.length == 0" class="col-md-8 col-sm-8 col-xs-8 sorting-contentbox d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-dark m-2 d-flex align-items-center justify-content-center" [routerLink]="['/sites/new']">
          <mat-icon>add_circle_outline</mat-icon>
          <span class="m-2">Add a Site</span>
        </button>
        <mat-form-field appearance="outline" class="form-field mx-2" subscriptSizing="dynamic">
          <mat-label>Search by Name / Email</mat-label>
          <input matInput name="name" placeholder="Ex. Gaurav" #input>
          <button *ngIf="input.value.length > 0" mat-icon-button aria-label="Clear" matSuffix (click)="onClearSearch($event)">
            <mat-icon>close</mat-icon>
          </button>
          <mat-icon *ngIf="input.value.length == 0" matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    
    <div *ngIf="selection.selected.length == 0" class="mt-2">
      <form [formGroup]="filterForm" >
        <mat-form-field class="example-full-width form-field" appearance='outline'>
          <mat-label>Filter by Client</mat-label>
          <input matInput placeholder="Select Client" [(ngModel)]="selectedClient" [matAutocomplete]="auto"
              formControlName="client" name="client" placeholder="Min 2 characters to load">
          <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onClientSelected()"
            [displayWith]="displayClientWith">
            <mat-option *ngIf="isLoadingClients" class="is-loading">Loading...</mat-option>
            <ng-container *ngIf="!isLoadingClients">
              <mat-option *ngFor="let client of filteredClients" [value]="client">
                <span>{{client.name}} ({{client.clientId}})</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-field m-0 p-0 mff-filter-small">
          <mat-label>Active / Inactive</mat-label>
          <mat-select formControlName="isactive" name="isactive" [(ngModel)]="selectedIsActive" (selectionChange)="onFilterChanged()">
              <mat-option [value]="true">Active</mat-option>
              <mat-option [value]="false">Inactive</mat-option>
          </mat-select>
        </mat-form-field>
        <button *ngIf="true" type="button" class="btn btn-sm btn-close mx-2" aria-label="Close" (click)="onClearFilters()"></button>
      </form>
    
    </div>
    
    <div class="mat-elevation-z8 data-row-columm mb-3" >
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows matSort matSortActive="receivedParams.sortBy" matSortDirection="asc" matSortDisableClear>
    
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="onSelectionChanged($event, row)"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        
        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th class="mat-column-id" mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td class="mat-column-id" mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>
    
        <!-- Client Column -->
        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
          <td mat-cell *matCellDef="let row"> 
            {{row.clientName + ' (' + row.clientId + ')'}} 
          </td>
        </ng-container>
    
        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 
            <mat-icon>delete</mat-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.isActive" class="text-success">●</span>
            <span *ngIf="!row.isActive" class="text-danger">●</span>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="ac-de">
          <th class="mat-column-actions" mat-header-cell *matHeaderCellDef> Actions </th>
          <td class="mat-column-actions" mat-cell *matCellDef="let row">
            <div class="btn-group btn-group-sm" role="group" aria-label="Actions">
              <button class="btn btn-secondary btn-dark d-flex align-items-center" [id]="row.id" (click)="openSite(row.id)" matTooltip="Edit"><mat-icon>edit</mat-icon></button>
              <div *ngIf="row.isActive == true" class="alert alert-dark m-0 px-0"></div>
              <button *ngIf="row.isActive == true" class="btn btn-secondary btn-danger d-flex align-items-center" (click)="deleteSite(row)" matTooltip="Delete"><mat-icon>delete</mat-icon></button>
            </div>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
          <td mat-cell *matCellDef="let row">
            <button aria-label="expand row" (click)="toggleExpandClicked($event, row)">
              <mat-icon *ngIf="expandedElement !== row">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="expandedElement === row">keyboard_arrow_up</mat-icon>
            </button>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row" [attr.colspan]="displayWithExpand.length">
            <div class="row-detail-inner-container"
                [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
              <div class="row w-100 m-2">
                <div class="col-md-12">
                  <label *ngIf="row.addressText">Address: &nbsp;</label>{{row.addressText}}<br *ngIf="row.addressText">
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayWithExpand"></tr>
        <tr mat-row *matRowDef="let row; columns: displayWithExpand;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === row"
          (click)="expandedElement = expandedElement === row ? null : row"></tr>
        
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filters</td>
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    
      <div [hidden]="selection.selected.length > 0">
        <mat-paginator [length]="dataSource.maxCount$ | async" [pageSize]="10" [pageSizeOptions]="[10, 50, 100, 500]" aria-label="Select page of dataSource"></mat-paginator>
      </div>
    </div>
  </div>
</div>