import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { CreateUserComponent } from './user-create/create-user.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CreateClientComponent } from './create-client/create-client.component';
import { AdminUserComponent } from './admin-user/admin-user.component';
import { UserReportComponent } from './user-list/user-list.component';
import { ClientReportComponent } from './client-report/client-report.component';
import { EditUserComponent } from './user-edit/edit-user.component';
import { HealthcheckComponent } from './healthcheck/healthcheck.component';
import { AssignToolsComponent } from './assign-tools/assign-tools.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ClientSubgroupComponent } from './client-subgroup/client-subgroup.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { LazyusercreationComponent } from './lazyusercreation/lazyusercreation.component';
import { CSVuploadComponent } from './csvupload/csvupload.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { EditAdminComponent } from './edit-admin/edit-admin.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AuditUserComponent } from './audit-user/audit-user.component';
import { CreateAdminComponent } from './create-admin/create-admin.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MyInterceptor } from './auth/my.interceptor';
import { LogsComponent } from './logs/logs.component';
import { LogoutComponent } from './logout/logout.component';
import { ViewUserComponent } from './user-view/view-user.component';
import { UserFilterComponent } from './user-filter/user-filter.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReportsComponent } from './reports/reports.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ReportsSharepointTasksComponent } from './reports-sharepoint-tasks/reports-sharepoint-tasks.component';
import { ReportsCountryAdminsComponent } from './reports-country-admins/reports-country-admins.component';
import { UserAuditComponent } from './user-audit/user-audit.component';
import { FileSelectorComponent } from './file-selector/file-selector.component';
import { ReportsClientUserCountComponent } from './reports-client-user-count/reports-client-user-count.component';

@NgModule({
  declarations: [AppComponent, HomeComponent, CreateUserComponent, CreateClientComponent, AdminUserComponent, UserReportComponent, ClientReportComponent, EditUserComponent,HealthcheckComponent, AssignToolsComponent, ClientSubgroupComponent, LazyusercreationComponent,EditClientComponent, CSVuploadComponent, EditAdminComponent, CreateAdminComponent, AuditUserComponent, LogsComponent, LogoutComponent, ViewUserComponent, UserFilterComponent, ReportsComponent, ReportsSharepointTasksComponent, ReportsCountryAdminsComponent, UserAuditComponent, FileSelectorComponent, ReportsClientUserCountComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatSortModule,
    MatDialogModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyInterceptor,
      multi: true      
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
