<!--loader-->
<div *ngIf="isLoading">
    <div class="loaderbox">
        <div class="darkbackground-box"></div>
        <div class="spinnerbox-ic">
            <mat-spinner style="width: 55px;height:55px"></mat-spinner>
        </div>
    </div>
</div>

<div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-3">
        <div class="row hdbox-fm my-2">
            <div class="col-md-12 col-sm-12 col-xs-12 hdbox-fm-grid d-flex align-items-center">
                Create User
            </div>
        </div>
    
        
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="my-3">
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Email Address :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter user's email address</mat-label>
                        <input matInput formControlName="email" name="email">
                        <div *ngIf="registerForm.controls['email'].touched && registerForm.controls['email'].invalid">
                            <mat-error *ngIf="registerForm.controls['email'].errors?.['required']">This field is required</mat-error>
                            <mat-error *ngIf="registerForm.controls['email'].errors?.['email']">Invalid email address</mat-error>
                            <mat-error *ngIf="registerForm.controls['email'].errors?.['exists']">Email address already exists</mat-error>
                        </div>
                    </mat-form-field>
                    <br>
                    <button type="button" class="btn btn-dark btn-sm mb-3" *ngIf="existingUser != null" [routerLink]="['/users', existingUser.id, 'edit']">Edit existing user</button>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Select Client :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field class="example-full-width">
                        <mat-label>Search Clients</mat-label>
                        <input matInput placeholder="Search Clients" [(ngModel)]="selectedClient" [matAutocomplete]="auto"
                            formControlName="client" name="client" placeholder="Min 2 characters to load">
                        <div *ngIf="registerForm.controls['client'].touched && registerForm.controls['client'].invalid">
                            <mat-error *ngIf="registerForm.controls['client'].errors?.['required']">This field is required</mat-error>
                        </div>
                        <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete" (optionSelected)="onClientSelected()"
                          [displayWith]="displayClientWith">
                          <mat-option *ngIf="isLoadingClients" class="is-loading">Loading...</mat-option>
                          <ng-container *ngIf="!isLoadingClients">
                            <mat-option *ngFor="let client of filteredClients" [value]="client">
                              <span>{{client.name}} ({{client.clientId}})</span>
                            </mat-option>
                          </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div class="row mb-3" >
                        <mat-checkbox  formControlName="isClientContact" name="isClientContact">Is Point of Contact</mat-checkbox>
                    </div>            
                </div>
            </div>
           
            <div class="row">
                <div class="col-md-3 col-sm-12 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Groups to be assigned to user :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="dropdown-access">
                        <mat-label>Select Groups/Subgroups</mat-label>
                        <mat-select formControlName="groups" name="groups" [(ngModel)]="selectedGroups" multiple panelClass="input-ad-box dropdown">
                            <mat-select-trigger>
                                {{(selectedGroups.length > 0) ? selectedGroups[0].name : ''}}
                                <span *ngIf="(selectedGroups.length || 0) > 1" class="example-additional-selection">
                                    (+{{(selectedGroups.length || 0) - 1}} {{selectedGroups.length === 2 ? 'other' : 'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let group of allGroups" [value]="group" [disabled]="group.isDefault">{{group.name}}</mat-option>
                        </mat-select>
                        <div *ngIf="registerForm.controls['groups'].touched && registerForm.controls['groups'].invalid">
                            <mat-error *ngIf="registerForm.controls['groups'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>First Name :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter user's first name</mat-label>
                        <input matInput formControlName="firstname" name="firstname" [(ngModel)]="firstName">
                        <div *ngIf="registerForm.controls['firstname'].touched && registerForm.controls['firstname'].invalid">
                            <mat-error *ngIf="registerForm.controls['firstname'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Last Name :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter user's last name</mat-label>
                        <input matInput formControlName="lastname" name="lastname" [(ngModel)]="lastName">
                        <div *ngIf="registerForm.controls['lastname'].touched && registerForm.controls['lastname'].invalid">
                            <mat-error *ngIf="registerForm.controls['lastname'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>User Type :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Select the type of user</mat-label>
                        <mat-select formControlName="usertype" name="usertype" [(ngModel)]="selectedUserType" >
                            <ng-container *ngFor="let userType of optionUserTypes" >
                                <mat-option [ngStyle]="{ display : userType.status==0 ? 'none' : 'block' }" [value]="userType.id" [disabled]="userType.id==2">
                                    {{userType.text}}
                                </mat-option>
                              </ng-container>                        
                        </mat-select>
                        <div *ngIf="registerForm.controls['usertype'].touched && registerForm.controls['usertype'].invalid">
                            <mat-error *ngIf="registerForm.controls['usertype'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                    <div class="row mb-3" *ngIf="selectedUserType !== 'e' && selectedUserType !== 'ea'  && selectedUserType !== 2 && selectedClientAuth != true">
                        <mat-checkbox  formControlName="sendemail" name="sendemail">Send Activation Email</mat-checkbox>
                    </div>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Title :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter user's Title</mat-label>
                        <input matInput formControlName="title" name="title">
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Language :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Select user's preferred language</mat-label>
                        <mat-select formControlName="language" name="language">
                            <mat-option *ngFor="let language of optionLanguages" [value]="language.value">
                                {{language.text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Salutation :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Select a salutation</mat-label>
                        <mat-select formControlName="salutation" name="salutation">
                            <mat-option value="">None</mat-option>
                            <mat-option *ngFor="let salutation of optionSalutations" [value]="salutation.value">
                                {{salutation.text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Address :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter user's address</mat-label>
                        <input matInput formControlName="address" name="address">
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">City :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter user's city</mat-label>
                        <input matInput formControlName="city" name="city">
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">State :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter user's state</mat-label>
                        <input matInput formControlName="state" name="state">
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Country :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Select user's country</mat-label>
                        <mat-select formControlName="country" name="country">
                            <mat-option *ngFor="let country of optionCountries" [value]="country.value">
                                {{country.text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Zip :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Enter user's zip</mat-label>
                        <input matInput formControlName="zip" name="zip">
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Telephone :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0">
                        <mat-label>Select user's telephone</mat-label>
                        <input matInput formControlName="phone" name="phone">
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Upload Photo :<div class="smt-txt">Max 160x160 px, JPEG/PNG</div></div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <div>
                        <input class="form-control upload-file-btn" type="file" (change)="onFileChange($event)">
                        <mat-error *ngIf="fileErr">{{fileErr}}</mat-error>
                    </div>
                </div>
            </div>
    
            <div class="row mt-3">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"></div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <button type="submit" class="svn-btn" [disabled]="this.registerForm.invalid || fileErr">Submit</button>
                    <button (click)="onCancel()" type="button" class="cn-btn">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>