import {AfterViewInit, Component, ViewChild,OnInit, inject, ElementRef} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { AppService } from '../app.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {forkJoin, fromEvent, merge, observable, Observable, of as observableOf, of, pipe} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import { SiteDataSource } from './site-data-source';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { MatDialog } from '@angular/material/dialog';
import { AuditUserComponent } from '../audit-user/audit-user.component';
import { environment } from 'src/environments/environment';
import { OPTION_USER_TYPES, OPTION_COUNTRIES, OPTION_SALUTATIONS, OPTION_LANGUAGES } from 'src/models/Constants';
import { NgDialogAnimationService } from "../utilities/d.service";
import { SelectionModel } from '@angular/cdk/collections';
import { EmailMessage } from 'src/dto/EmailMessage';
import { FileSelectorComponent } from '../file-selector/file-selector.component';
import { OktaUserProfile } from 'src/models/OktaUserProfile';

@Component({
  selector: 'app-site-report',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class SiteListComponent implements OnInit, AfterViewInit {

  loggedInUser : LoggedInUser;
  minLengthTerm = 2;

  //Route Params
  gotParam: boolean = false;
  readonly routeParams = ['sortBy', 'sort', 'pageIndex', 'pageSize', 'searchText', 'client', 'isActive'];
  receivedParams : any = {
    sortBy: 'name',
    sort : 'asc',
    pageIndex: 0,
    pageSize: 50,
    searchText: '',
    client: 0,
    isActive: ''
  }

  dataSource: SiteDataSource;
  displayedColumns: string[] = ['select', 'id', 'name', 'client', 'isActive', 'ac-de'];
  displayWithExpand = [...this.displayedColumns, 'expand'];
  message: string | null = null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  filterForm = new FormGroup({
    client: new FormControl(''),
    isactive: new FormControl('')
  });
  
  // For Client selection
  filteredClients: any;
  isLoadingClients = false;
  selectedClient: any = "";

  // For Active/Inactive selecion
  selectedIsActive: boolean | null = null;

  constructor(private router: Router, 
    private route: ActivatedRoute, 
    private location: Location, 
    public dialog: MatDialog, 
    private appService: AppService, 
    private authGuard: AuthGuard, 
    private _snackBar: MatSnackBar,
    public filterDialog: NgDialogAnimationService
    ) {   
      this.loggedInUser = authGuard.loggedInUser;
      this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
        this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.isLoadingResults = false;
    this.dataSource = new SiteDataSource(this.appService);
  }

  body:any;
  expandedElement:any | null;

  ngAfterViewInit() {
    setTimeout(() => {
      this.isLoadingResults = false;
      //Check and refresh page for received params
      this.gotParam = false;
      this.route.queryParams
        .subscribe(params => {
          this.routeParams.forEach(param => {
            if(params[param]){
              this.gotParam = true;
              this.receivedParams[param] = params[param];
            }
          });

          this.sort.sort(({ id: this.receivedParams.sortBy, start: this.receivedParams.sort}) as MatSortable);
          if(this.gotParam){
            this.setPageFiltersFromParams();
          }else{
            this.dataSource.loadSites(0);
          }

          this.subscribeEvents();
        }
      );
    });
  }

  subscribeEvents(){
    // server-side search
    fromEvent(this.input.nativeElement,'keyup')
    .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
            this.paginator.pageIndex = 0;
            this.loadSitesPage();
        })
    )
    .subscribe();
    
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        
    merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap((e) => {
              return this.loadSitesPage()
            })
        )
        .subscribe();

    //Listen to client selection changes
    this.filterForm.controls['client'].valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(250),
        tap(() => {
          this.filteredClients = [];
          this.isLoadingClients = true;
        }),
        switchMap(value => this.appService.searchClients(10, 0, value as string, '', '', '', '', false, 'name', 'asc', true)
          .pipe(
            finalize(() => {
              this.isLoadingClients = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        if (response['data'] == undefined) {
          this.filteredClients = [];
        } else {
          this.filteredClients = response['data'];
        }
      });
  }

  setPageFiltersFromParams(){
    setTimeout(() => {
      this.sort.active = this.receivedParams['sortBy'];
      this.sort.direction = this.receivedParams['sort'];
      this.paginator.pageIndex = this.receivedParams['pageIndex'];
      this.paginator.pageSize = this.receivedParams['pageSize'];
      this.input.nativeElement.value = this.receivedParams['searchText'];
      if(this.receivedParams['isActive'] != null){
        this.selectedIsActive = this.receivedParams['isActive'] == 'true' ? true : 
        this.receivedParams['isActive'] == 'false' ? false : null;
      }
      if(this.receivedParams['client']){
        this.isLoadingResults = true;
        this.appService.searchClients(10, 0, this.receivedParams['client'], '', '', '', '', false, 'name', 'asc', true)
        .subscribe((response: any) => {
          this.filteredClients = response.data;
          this.selectedClient = this.filteredClients.find((client: { clientId: any; }) => client.clientId === this.receivedParams['client']);
        });
      }else{
        this.loadSitesPage();
      }
    });
    
  }

  loadSitesPage(): void {
    this.dataSource.loadSites(
    this.paginator.pageIndex,
    this.paginator.pageSize,
    this.input.nativeElement.value,
    this.selectedClient ? this.selectedClient.id : 0,
    this.selectedIsActive,
    this.sort.active,
    this.sort.direction);

    this.updateUrlState();
    this.selection.clear();
  }

  updateUrlState(): void {
    let pathParts: [string] = [`/sites?pageIndex=${this.paginator.pageIndex}&pageSize=${this.paginator.pageSize}&sortBy=${this.sort.active}&sort=${this.sort.direction}`];
    if(this.input.nativeElement.value){
      pathParts.push(`searchText=${this.input.nativeElement.value}`)
    }
    if(this.selectedClient){
      pathParts.push(`client=${this.selectedClient.clientId}`)
    }
    if(this.selectedIsActive != null){
      pathParts.push(`isActive=${this.selectedIsActive}`)
    }
    this.location.replaceState(pathParts.join('&'));
  }

  openSite(event: any){
    this.router.navigateByUrl(`/sites/${event}/edit`);
  }

  deleteSite(row: any){
    this.isLoadingResults = true;
    if(confirm(`Confirm deletion of site - ${row.name} (${row.clientId}) ?`)) {
      this.appService.deleteSite(row.id).subscribe((response) => {
        this.isLoadingResults = false;
        row.isActive = !row.isActive;
        this.showSnackbar(response.message);
      },(e) => {
        this.isLoadingResults = false;
        this.showSnackbar(e.message);
      });
    }else{
      this.isLoadingResults = false;
    }
  }

  sendActivationEmail(row:any){
    this.isLoadingResults = true;
    this.appService.sendActivationEmail(row.email)
      .subscribe((data) => {
        this.isLoadingResults = false;
        row.activationEmailSent = true;
        this.showSnackbar(data.message);
      }, 
      (e) => {
        console.log(e);
        this.isLoadingResults = false;
        this.showSnackbar(e.message);
      });
  }

  resetOktaAuthenticators(row:any){
    this.isLoadingResults = true;
    if(confirm(`This will reset Okta MFA factors for the user ${row.username}. User will be asked to set them again on next sign in. Proceed?`)) {
      this.appService.resetOktaAuthenticators(row.email)
      .subscribe((data) => {
        this.isLoadingResults = false;
        this.showSnackbar(data);
      }, 
      (e) => {
        console.log(e);
        this.isLoadingResults = false;
        this.showSnackbar(e.message);
      });
    }else{
      this.isLoadingResults = false;
    }
  }

  toggleStatus(row:any){
    let requestedIsActive = null;
    if(row.isActive == true){
      requestedIsActive = false;
    }else if(row.isActive == false){
      requestedIsActive = true;
    }

    if(requestedIsActive != null){
      this.isLoadingResults = true;
      this.appService.setIsActive(row.email, requestedIsActive)
      .subscribe((data) => {
        this.isLoadingResults = false;
        this.showSnackbar(`User ${Boolean(JSON.parse(data)) == true ? "Activated" : "Deactivated"}`);
        row.isActive = Boolean(JSON.parse(data));
      }, 
      (e) => {
        console.log(e);
        this.isLoadingResults = false;
        this.showSnackbar(e.message);
      });
    }else{
      this.showSnackbar("Invalid Status Requested!");
    }
  }

  refreshStatus(row:any){
    this.isLoadingResults = true;
    this.appService.getUserOktaStatus(row.email, true)
      .subscribe((data: any) => {
        row.status = data;
        this.isLoadingResults = false;
      }, 
      (e) => {
        console.log(e);
        this.isLoadingResults = false;
        this.showSnackbar(e.message);
      });
  }

  toggleExpandClicked(event: any, row: any){
    this.expandedElement = this.expandedElement === row ? null : row
    event.stopPropagation();

    if(row.addressText == null){
      row.addressText = row.address;
    }
  }

  onClientSelected() {
    this.selectedClient = this.selectedClient;
    this.loadSitesPage();
  }

  onFilterChanged() {
    this.loadSitesPage();
  }

  displayClientWith(value: any) {
    return value?.name;
  }

  onClearFilters(){
    this.filterForm.reset();
    this.selectedIsActive = null;
    this.paginator.pageIndex = 0;
    this.loadSitesPage();
  }

  onClearSearch($event: any){
    this.input.nativeElement.value = '';
    this.loadSitesPage();
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  // Bulk Selection and options

  selection = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.getDataLength();
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.getData().forEach(row => this.selection.select(row));
  }

  onSelectionChanged(event: any, row: any){
    this.selection.toggle(row);
  }

}


