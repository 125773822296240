<!--loader-->
<div *ngIf="isLoading">
    <div class="loaderbox">
        <div class="darkbackground-box"></div>
        <div class="spinnerbox-ic">
            <mat-spinner style="width: 55px;height:55px"></mat-spinner>
        </div>
    </div>
</div>

<div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-3">
        <div class="row hdbox-fm my-2">
            <div class="col-md-11 col-sm-12 col-xs-12">
                Edit Client Site<span *ngIf="site?.client?.clientId"> - {{ site?.name }} ({{ site?.client?.clientId }})</span> 
            </div>
        </div>
    
        <div class="row my-3">
            <div class="col-md-6 col-sm-12">
    
                <form [formGroup]="editForm" (ngSubmit)="onSiteSubmit()">
            
                    <div class="row mt-2">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Client :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0 mb-2" [style.width.%]="90">
                                <mat-label>Client</mat-label>
                                <input matInput formControlName="client" name="client">
                            </mat-form-field>
                            <br>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Site Name :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                <mat-label>Enter Site Name</mat-label>
                                <input matInput formControlName="name" name="name">
                                <div *ngIf="editForm.controls['name'].touched && editForm.controls['name'].invalid">
                                    <mat-error *ngIf="editForm.controls['name'].errors?.['required']">This field is required</mat-error>
                                    <mat-error *ngIf="editForm.controls['name'].errors?.['exists']">Client name already exists</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Site Address :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                <mat-label>Enter Site Address</mat-label>
                                <input matInput formControlName="address" name="address">
                                <div *ngIf="editForm.controls['address'].touched && editForm.controls['address'].invalid">
                                    <mat-error *ngIf="editForm.controls['address'].errors?.['required']">This field is required</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                    
    
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"></div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <button type="submit" class="svn-btn" [disabled]="this.editForm.invalid">Submit</button>
                            <button (click)="onCancel()" type="button" class="cn-btn">Cancel</button>
                        </div>
                    </div>
    
                </form>

                <hr>

                <div class="row mx-4">
    
                    <p class="h5 pb-2">Add Policies</p>

                    <form [formGroup]="addPolicyForm" #formDirective="ngForm" (ngSubmit)="onSitePolicySubmit(addPolicyForm, formDirective)" class="form-inline">
                        <div class="row">
                            <div class="col-md-8 col-sm-10 col-xs-10 input-ad-box">
                                <mat-form-field class="example-full-width" [style.width.%]="100">
                                    <mat-label>Search Client Policies</mat-label>
                                    <input matInput placeholder="Search Client Policies" [(ngModel)]="selectedPolicy" [matAutocomplete]="autoPolicies"
                                        formControlName="policy" name="policy" placeholder="Min {{minLengthTerm}} characters to load">
                                    <div *ngIf="addPolicyForm.controls['policy'].touched && addPolicyForm.controls['policy'].invalid">
                                        <mat-error *ngIf="addPolicyForm.controls['policy'].errors?.['required']">This field is required</mat-error>
                                        <mat-error *ngIf="addPolicyForm.controls['policy'].errors?.['exists']">Policy already exists</mat-error>
                                    </div>
                                    <mat-autocomplete [panelWidth]="400" #autoPolicies="matAutocomplete"
                                      [displayWith]="displayPolicyWith">
                                      <mat-option *ngIf="isLoadingPolicies" class="is-loading">Loading...</mat-option>
                                      <ng-container *ngIf="!isLoadingPolicies">
                                        <mat-option *ngFor="let policy of filteredPolicies" [value]="policy">
                                          <span>{{policy.policyNumber}}</span>
                                        </mat-option>
                                      </ng-container>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                
                            <div class="col-md-4 col-sm-10 col-xs-10 input-ad-box">
                                <button type="submit" class="btn btn-dark align-middle" [disabled]="this.addPolicyForm.invalid">Add Policy</button>
                            </div>
                
                        </div>
                    </form>
                
                    <div class="col-md-12 col-sm-12 col-xs-12 txt-ad-box" *ngIf="allSitePolicies.length > 0"><p class="h5 pb-1">Attached Policies</p></div>
                    <table class="table table-striped mx-2" *ngIf="allSitePolicies.length > 0">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Policy Number</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let policy of allSitePolicies; index as i">
                            <th scope="row">{{i+1}}</th>
                            <td>{{policy.policyNumber}}</td>
                            <td class="align-top text-center">
                              <button type="button" class="btn-close mt-1" aria-label="Close" (click)="deleteSitePolicy(policy)"></button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                </div>
    
            </div>
    
            <div class="col-md-6 col-sm-12">
                
                <div class="row">
    
                    <p class="h5 pb-2">Add Site Managers</p>
                    <form [formGroup]="addSiteManagerForm" #formDirective="ngForm" (ngSubmit)="onSiteManagerSubmit(addSiteManagerForm, formDirective)" class="form-inline">
                        <div class="row">
                            <div class="col-md-8 col-sm-10 col-xs-10 input-ad-box">
                                <mat-form-field class="example-full-width" [style.width.%]="100">
                                    <mat-label>Search Client Users</mat-label>
                                    <input matInput placeholder="Search Client Users" [(ngModel)]="selectedUser" [matAutocomplete]="autoUsers"
                                        formControlName="search" name="search" placeholder="Min 2 characters to load">
                                    <div *ngIf="addSiteManagerForm.controls['search'].touched && addSiteManagerForm.controls['search'].invalid">
                                        <mat-error *ngIf="addSiteManagerForm.controls['search'].errors?.['required']">This field is required</mat-error>
                                    </div>
                                    <mat-autocomplete [panelWidth]="400" #autoUsers="matAutocomplete"
                                      [displayWith]="displaySiteManagerWith">
                                      <mat-option *ngIf="isLoadingUsers" class="is-loading">Loading...</mat-option>
                                      <ng-container *ngIf="!isLoadingUsers">
                                        <mat-option *ngFor="let user of filteredUsers" [value]="user">
                                          <span>{{user.firstName}} {{user.lastName}} ({{user.email}})</span>
                                        </mat-option>
                                      </ng-container>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
    
                            <div class="col-md-4 col-sm-10 col-xs-10 input-ad-box">
                                <button type="submit" class="btn btn-dark align-middle" [disabled]="this.addSiteManagerForm.invalid">Add Site Manager</button>
                            </div>
    
                        </div>
                    </form>
    
                    <div class="col-md-12 col-sm-12 col-xs-12 txt-ad-box" *ngIf="allSiteManagers.length > 0"><p class="h5 pb-1">Site Managers</p></div>
                    <table class="table table-striped mx-2" *ngIf="allSiteManagers.length > 0">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let user of allSiteManagers; index as i">
                            <th scope="row">{{i+1}}</th>
                            <td>{{user.firstName}} {{user.lastName}}</td>
                            <td>{{user.email}}</td>
                            <td class="align-top text-center">
                              <button type="button" class="btn-close mt-1" aria-label="Close" (click)="deleteSiteManager(user)"></button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                </div>
        
            </div>
    
        </div>
    </div>
</div>