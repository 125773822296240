import {Component, EventEmitter, Output} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSidenavModule} from '@angular/material/sidenav';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { of, filter, switchMap, map, merge } from 'rxjs';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent {
  
  @Output()
  itemClick = new EventEmitter();

  public reports = [
    {id: 'sharepoint-tasks', name: "Sharepoint Tasks"},
    {id: 'country-admins', name: "Country Admins"},
    {id: 'client-user-count', name: "Client User"} 
  ]

  public activeReport = this.reports[0];

  constructor(private route: ActivatedRoute, private router: Router) {
    this.router.navigate(["reports", this.activeReport.id]);
  }

  ngOnInit() {
    // check if there's an id in URL
    // this.route.url
    //   .subscribe(params => {
    //     console.log('params', params);
    //   }
    // );
  }

  onclick(reportId: string) {
    this.activeReport = this.reports.filter(report => report.id === reportId)[0];
    if(this.activeReport){
      this.navigateToReport(reportId);
    }
  }

  navigateToReport(reportId: string){
    this.router.navigate(["reports", reportId]);
  }
}
