import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { OPTION_CLIENT_TYPES } from 'src/models/Constants';
import { Router, ActivatedRoute } from '@angular/router';
import { filter, distinctUntilChanged, debounceTime, tap, switchMap, finalize, catchError } from 'rxjs';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-site',
  templateUrl: './site-edit.component.html',
  styleUrls: ['./site-edit.component.scss']
})

export class EditSiteComponent implements OnInit {
  
  optionClientTypes: any[] = OPTION_CLIENT_TYPES;

  loggedInUser : LoggedInUser;
  minLengthTerm = 3;

  id: number | null;
  site: any;
  isLoading: boolean = false;

  editForm = new FormGroup({
    name: new FormControl(''),
    client: new FormControl(''),
    address: new FormControl('', [Validators.required]),
  });

  addSiteManagerFormDirective: FormGroupDirective;
  addSiteManagerForm = new FormGroup({
    search: new FormControl('', [Validators.required])
  });

  addPolicyFormDirective: FormGroupDirective;
  addPolicyForm = new FormGroup({
    policy: new FormControl('', [Validators.required])
  });

  // For Site Managers
  allSiteManagers: any = [];
  filteredUsers: any;
  isLoadingUsers = false;
  selectedUser: any = "";

  // For policies
  allSitePolicies: any = [];
  allOdsPolicies: any | null = null;
  filteredPolicies: any;
  isLoadingPolicies = false;
  selectedPolicy: any = "";

  constructor(private appService: AppService, private router: Router, private _snackBar: MatSnackBar, private authGuard: AuthGuard, private location: Location, private route: ActivatedRoute) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.id = parseInt(this.route.snapshot.paramMap.get('id')!); 
    this.findSite();
    this.findSiteManagers();
    this.findSitePolicies();
  }

  ngAfterViewInit() {
    this.addSiteManagerForm.controls['search'].valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(250),
        tap(() => {
          this.filteredUsers = [];
          this.isLoadingUsers = true;
        }),
        switchMap(value => this.appService.getUsers(10, 0, value as string, this.site.clientId, 0, '', '', true, null, 'name', 'asc')
          .pipe(
            finalize(() => {
              this.isLoadingUsers = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        if (response['data'] == undefined) {
          this.filteredUsers = [];
        } else {
          this.filteredUsers = response['data'];
        }
      });

    this.addPolicyForm.controls['policy'].valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(250),
        tap(() => {
          this.filteredPolicies = [];
          this.isLoadingPolicies = true;
        })
      )
      .subscribe((response: any) => {
        this.isLoadingPolicies = false;
        if (response == undefined) {
          this.filteredPolicies = [];
        } else {
          this.filteredPolicies = this.allOdsPolicies.filter((p: { policyNumber: (string | null)[]; }) => p.policyNumber.includes(response));
          console.log('pol filter', this.filteredPolicies);
        }
      });
  }

  findSite(){
    if(this.id != null){
      this.isLoading = true;
      this.appService.getSiteById(this.id).subscribe((response) => {
        this.site = response;
        this.updateFields();
        this.findSiteManagers();
        if(this.allOdsPolicies == null)
          this.findClientPolicies();
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
        console.log('findsite err', e.error);
      });
    }
  }

  findClientPolicies(){
    if(this.site != null){
      this.isLoadingPolicies = true;
      this.appService.getClientPoliciesFromOds(this.site.client.id).subscribe((response) => {
        var respArr = JSON.parse(response);
        var agreementList = respArr.Data.AgreementList;
        this.allOdsPolicies = [];
        agreementList.forEach((agreement: any) => {
          var id = agreement["AgreementSummary"]["Identifier"]["ID"];
          var polNum = agreement["InsurerPolicyNumber"].trim();
          if(polNum != null || polNum != ''){
            var policy = {
              'id': id,
              'policyNumber': polNum
            };
            this.allOdsPolicies.push(policy);
          }
          
        });
        this.isLoadingPolicies = false;
      },(e) => {
        this.isLoadingPolicies = false;
        this.showSnackbar(e.message);
        console.log('find ods polices err', e.error);
      });
    }
  }

  findSiteManagers(){
    if(this.id != null){
      this.isLoading = true;
      this.appService.getSiteManagers(this.id, 10, 0, '', true, 'name', 'asc').subscribe((response) => {
        this.allSiteManagers = response.data;
        this.isLoading = false;
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
        console.log('find site managers err', e.error);
      });
    }
  }

  findSitePolicies(){
    if(this.id != null){
      this.isLoading = true;
      this.appService.getSitePolicies(this.id, 10, 0, '', true).subscribe((response) => {
        this.allSitePolicies = response.data;
        this.isLoading = false;
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
        console.log('find polices err', e.error);
      });
    }
  }

  updateFields(){
    if(this.site){
      this.editForm.controls['client'].setValue(`${this.site.client.name} (${this.site.client.clientId})`);
      this.editForm.controls['name'].setValue(this.site.name);
      this.editForm.controls['address'].setValue(this.site.address);

      this.editForm.controls['client'].disable();

      this.isLoading = false;
    }
  }

  onSiteSubmit()
  {
    if (this.editForm.invalid) {
      return;
    }
    window.scrollTo(0, 0);
    const formData = new FormData();
    formData.append('Id', this.site.id);
    formData.append('Name', this.editForm.controls['name'].value!);
    formData.append('ClientId', this.site.clientId);
    formData.append('Address', this.editForm.controls['address'].value!);
    formData.append('IsActive', 'true');
    
    this.updateSite(formData);
  }

  updateSite(formData: FormData) {
    this.isLoading = true; 
    this.appService.updateSite(formData)
      .subscribe((data) => {
        this.isLoading = false;
        this.showSnackbar("Site updated successfully");
        this.findSite();
      },(e) => {
        console.log(e);
        this.isLoading = false;
        this.showSnackbar(e.message);
      }); 
  }

  onSiteManagerSubmit(formData: any, formDirective: FormGroupDirective): void {
    this.addSiteManagerFormDirective = formDirective;
    this.isLoading = true; 

    formData = new FormData();
    formData.append('SiteId', this.site.id);
    formData.append('AonlineUserId', this.selectedUser.id);
    formData.append('CreatedByUserId', this.loggedInUser.id);
    formData.append('IsActive', 'true');

    this.appService.addSiteManager(formData)
      .subscribe((response) => {
        // this.updateTableData(response)
        this.showSnackbar("Site Manager added successfully");
        this.isLoading = false;
        this.resetSiteManagerForm();
        this.findSiteManagers();
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
    });
  }

  deleteSiteManager(siteManager: any){
    this.isLoading = true; 
    this.appService.deleteSiteManager(siteManager.siteId, siteManager.aonlineUserId)
      .subscribe((response) => {
        this.showSnackbar("Site Manager deleted successfully");
        this.isLoading = false;
        this.findSiteManagers();
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
    });
  }

  onSitePolicySubmit(formData: any, formDirective: FormGroupDirective): void {
    var newPolicy = this.selectedPolicy.policyNumber;
    var existing = this.allSitePolicies.map((p: { policyNumber: any; }) => p.policyNumber);
    if(existing.includes(newPolicy)){
      this.addPolicyForm.controls['policy'].setErrors({ 'exists': true });
      this.showSnackbar("Policy already exists!");
      return;
    }

    this.addPolicyFormDirective = formDirective;
    this.isLoading = true; 

    formData = new FormData();
    formData.append('SiteId', this.site.id);
    formData.append('PolicyNumber', this.selectedPolicy.policyNumber);
    formData.append('CreatedByUserId', this.loggedInUser.id);
    formData.append('ModifiedByUserId', this.loggedInUser.id);
    formData.append('IsActive', 'true');

    this.appService.addSitePolicy(formData)
      .subscribe((response) => {
        // this.updateTableData(response)
        this.showSnackbar("Site Policy added successfully");
        this.isLoading = false;
        this.resetSitePolicyForm();
        this.findSitePolicies();
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
    });
  }

  resetSiteManagerForm(){
    // this.editForm.reset();
    if(this.addSiteManagerFormDirective != null){
      this.addSiteManagerFormDirective.resetForm();
    }
    this.addSiteManagerForm.reset();
    this.filteredUsers = [];
    this.selectedUser = "";
  }

  deleteSitePolicy(sitePolicy: any){
    this.isLoading = true; 
    this.appService.deleteSitePolicy(sitePolicy.id)
      .subscribe((response) => {
        this.showSnackbar("Site Policy deleted successfully");
        this.isLoading = false;
        this.findSitePolicies();
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
    });
  }

  resetSitePolicyForm(){
    // this.editForm.reset();
    if(this.addPolicyFormDirective != null){
      this.addPolicyFormDirective.resetForm();
    }
    this.addPolicyForm.reset();
  }

  // resetVars(){
  //   this.filteredCountries = [];
  //   this.allTools = [];
  //   this.selectedDefaultTools = [];
  //   this.potentialDefaultTools = [];
  //   this.potentialSubgroupTools = [];
  //   this.subgroups = [];
  // }

  resetPage(){
    // this.resetForms();
    // this.resetVars();
  }

  displaySiteManagerWith(value: any) {
    if(!value) return "";
    return `${value?.firstName} ${value?.lastName} (${value?.email})`;
  }

  displayPolicyWith(value: any) {
    if(!value) return "";
    return `${value?.policyNumber}`;
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  onCancel() {
    this.location.back();
  }
  
}
