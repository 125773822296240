<!--loader-->
<div *ngIf="isLoading">
    <div class="loaderbox">
        <div class="darkbackground-box"></div>
        <div class="spinnerbox-ic">
            <mat-spinner style="width: 55px;height:55px"></mat-spinner>
        </div>
    </div>
</div>

<div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-3">
        <div class="row hdbox-fm my-2">
            <div class="col-md-12 col-sm-12 col-xs-12 hdbox-fm-grid d-flex align-items-center">
                Create Client Site
            </div>
        </div>
    
        
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="my-3">
    
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Select Client :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field class="example-full-width">
                        <mat-label>Search Clients</mat-label>
                        <input matInput placeholder="Search Clients" [(ngModel)]="selectedClient" [matAutocomplete]="auto"
                            formControlName="client" name="client" placeholder="Min 2 characters to load">
                        <div *ngIf="registerForm.controls['client'].touched && registerForm.controls['client'].invalid">
                            <mat-error *ngIf="registerForm.controls['client'].errors?.['required']">This field is required</mat-error>
                        </div>
                        <mat-autocomplete [panelWidth]="400" #auto="matAutocomplete"
                          [displayWith]="displayClientWith">
                          <mat-option *ngIf="isLoadingClients" class="is-loading">Loading...</mat-option>
                          <ng-container *ngIf="!isLoadingClients">
                            <mat-option *ngFor="let client of filteredClients" [value]="client">
                              <span>{{client.name}} ({{client.clientId}})</span>
                            </mat-option>
                          </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Site Name :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                        <mat-label>Enter Site Name</mat-label>
                        <input matInput formControlName="name" name="name">
                        <div *ngIf="registerForm.controls['name'].touched && registerForm.controls['name'].invalid">
                            <mat-error *ngIf="registerForm.controls['name'].errors?.['required']">This field is required</mat-error>
                            <mat-error *ngIf="registerForm.controls['name'].errors?.['exists']">Site already exists for client</mat-error>
                        </div>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Site Address :</div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                        <mat-label>Enter Site Address</mat-label>
                        <input matInput formControlName="address" name="address">
                        <div *ngIf="registerForm.controls['address'].touched && registerForm.controls['address'].invalid">
                            <mat-error *ngIf="registerForm.controls['address'].errors?.['required']">This field is required</mat-error>
                        </div>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row mt-3">
                <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"></div>
                <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                    <button type="submit" class="svn-btn" [disabled]="this.registerForm.invalid">Submit</button>
                    <button (click)="onCancel()" type="button" class="cn-btn">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>