<!--loader-->
<div id="loading" *ngIf="isLoading">
    <div class="loaderbox">
        <div class="darkbackground-box"></div>      
        <div class="spinnerbox-ic">
            <mat-spinner style="width: 55px;height:55px"></mat-spinner>
        </div>     
    </div> 
</div>
      
<mat-dialog-content class="mat-typography">
    <h2 mat-dialog-title id="filter-title">Users Filter</h2>
    <div class="row">

        <!-- Client -->
        <div class="row">

        </div>

        <!-- Subgroup -->
        <div class="row">

        </div>

        <!-- User Type -->
        <div class="row">

        </div>

        <!-- Country -->
        <div class="row">

        </div>

        <!-- Active / Inactive -->
        <div class="row">

        </div>

        <!-- Email Sent -->
        <div class="row">
            
        </div>

    </div>
    
</mat-dialog-content>

<mat-dialog-actions align="start">
    <button class="btn btn-success m-2" (click)="onApply()" type="button">Apply</button>
    <button class="btn btn-danger" (click)="onClear()" type="button">Clear</button>
</mat-dialog-actions>